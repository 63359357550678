import server from '@config'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom'
import { gaEvent, isB2B } from 'utils/utils'
import logo from '../../assets/header.svg'
import Animation from './Animation/Animation'
import SortList from './SortList'
import './SortPage.css'


function SortPage() {

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const [words, setWords] = useState([])
  const [result, setResult] = useState([])
  const [flag, setFlag] = useState(1)
  const { t } = useTranslation()

  useEffect(() => {

    let data = { "email": localStorage.getItem('email') }

    let config1 = {
      method: 'post',
      url: server + '/result/individual_result/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config1)
      .then((res) => {

        let temp = res.data

        if (temp.w_1 === -1) {

          setFlag(1)
          let config = {
            method: 'post',
            url: server + '/result/select/',
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          }

          axios(config)
            .then(res => {
              let temp1 = res.data['result']
              setWords(temp1)
              setResult(temp1)
            })

        } else {
          setFlag(3)
        }
      })
      .catch(err => {
        console.log(err, 'selected words load error')
        toast.error(t("There was an error when we tried to retrieve your selected words :("))
      })
  }, [])

  const onSubmit = () => {
    setFlag(2)
    let result_string = ''
    result.map(word => {
      result_string += word.word_id + ','
    })

    console.log(result_string)

    let data = { "email": localStorage.getItem('email'), "result": result_string }
    let url = "/result/individual_result/";
    if (sessionStorage.getItem('questionnaire')) {
      url += '?questionnaire_type=' + sessionStorage.getItem('questionnaire');
    } else if (sessionStorage.getItem('mission')) {
      url += '?mission=' + sessionStorage.getItem('mission');
    } else if (sessionStorage.getItem('contact')) {
      url += '?contact=' + sessionStorage.getItem('contact');
    } else if (sessionStorage.getItem('company')) {
      url += '?company=' + sessionStorage.getItem('company');
    }
    let config = {
      method: 'put',
      url: server + url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    gaEvent("WORD", "WORD_PAGE", "Sorted words submitted");
    gaEvent("EVALUATION", "EVALUATION_DONE", "Evaluation finished");

    axios(config)
      .then(res => {
        setFlag(3)
      })
      .catch(err => {
        console.log(err)
        toast.error(t("There was an error when we tried to save your sorted words :("))
      })
  }

  if (flag === 1) {
    return (
      <div>
        {words.length > 0 &&
          <div className="sort-page">

            <div className="main">
              <div className='wwt-container'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <img src={logo} alt="work well together" className="logo" />
                  {
                    isB2B() &&
                    <Link className='d-flex align-items-center ml-3' to='/recruitments'>
                      <div className="wwt-btn bg-warning">
                        {t("Pro Access")}
                      </div>
                    </Link>
                  }
                </div>
                <div className="sort-title">
                  {t("Please sort the words selected by order of preference")}
                </div>
                <div className="content">
                  <div className="lines-numbers">
                    {numbers.map((number, index) => {
                      return <div className="number" key={`${index}`}>{number}</div>
                    })}
                  </div>
                  <div className="sort-list">
                    <SortList words={words} setResult={setResult} />
                  </div>
                </div>
              </div>
            </div>
            <div className="rooter">
              <div className="line" />
              <div className='wwt-container align-items-center d-flex h-100 justify-content-end'>
                <button className="next-button-active" onClick={onSubmit}>
                  {t("Validate")}
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    )
  } else if (flag === 2) {
    return (
      <Animation />
    )
  } else {
    gaEvent("EVALUATION", "EVALUATION_WORDS_SORT_DONE", "Evaluation Words Sorting Done");
    return <Redirect to="/results" />
  }
}

export default SortPage