import server from '@config';
import { removeMemberFromMission } from 'api/mission';
import axios from 'axios';
import CompleteProfileSubMenu from 'components/Admin/common/CompleteProfileSubMenu/CompleteProfileSubMenu';
import QuotationTable from 'components/Admin/common/QuotationTable/QuotationTable';
import ConfirmationDialog from 'components/common/Dialogs/ConfirmationDialog';
import EditIcon from 'components/common/Icons/EditIcon';
import MoreDotsIcon from 'components/common/Icons/MoreDotsIcon';
import TableIcon from 'components/common/Icons/TableIcon';
import TrashIcon from 'components/common/Icons/TrashIcon';
import ProgressBar from 'components/common/ProgressBar/ProgressBar';
import Table from 'components/common/Table/Table';
import Loader from 'components/Loader/Loader';
import { getContactType } from 'constants/constants';
import * as htmlToImage from 'html-to-image';
import { MDBIcon, MDBModal, MDBModalBody, MDBTooltip } from 'mdbreact';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { checkQuotationAccess, getProfile, isEvaluationCompleted, stopPropagation, truncate } from 'utils/utils';
import './ValueDetails.css';

function ValueDetails(props) {

    const [mission, setMission] = useState(null);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const [searchInput, setSearchInput] = useState(null);
    const [printMode, setPrintMode] = useState(false);

    const [contacts, setContacts] = useState([]);
    const [matchings, setMatchings] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [extraSupportedFields, setExtraSupportedFields] = useState([]);
    const [matchingByContacts, setMatchingByContacts] = useState([]);

    const [quotationTableData, setQuotationTableData] = useState(null);

    const quotationAccess = checkQuotationAccess();
    const [confirmDialogConfig, setConfirmDialogConfig] = useState(null);

    const resetConfirmDialogConfig = () => {
        setTimeout(() => {
            setConfirmDialogConfig(null);
        }, 500);
    }

    useEffect(() => {
        const profile = getProfile();
        if (profile && profile.companies && profile.companies.length) {
            setCompanyId(profile.companies[0].company.pk);
            if (profile.companies[0].company.extra_supported_fields) {
                setExtraSupportedFields(profile.companies[0].company.extra_supported_fields);
            }
        }
    }, [])

    useEffect(() => {
        if (props.mission) {
            setMission(props.mission);
            const mission = props.mission;
            setModal(true);
            const contacts = [];
            if (mission.mission_manager) {
                contacts.push({ user: { ...mission.mission_manager, type: getContactType(mission.mission_manager.contact_type) } });
            }
            if (mission.mission_benchmarks && mission.mission_benchmarks.length) {
                mission.mission_benchmarks.forEach((benchmark) => {
                    contacts.push({ user: { ...benchmark.contact, type: getContactType(benchmark.benchmark_type) } });
                })
            }
            if (mission.mission_member && mission.mission_member.length) {
                mission.mission_member.forEach((member) => {
                    contacts.push({ user: { ...member, type: getContactType(member.contact_type), member: true } });
                })
            }
            console.log('contacts', contacts);
            setContacts(contacts);
        } else {
            setModal(false);
        }
        setSearchInput('');
    }, [props.mission]);

    useEffect(() => {
        if (contacts.length) {
            getContactsMatchings();
        }
    }, [contacts]);

    useEffect(() => {
        const matchingArray = [];
        contacts.forEach((contact) => {
            let matching = matchings.find((matching) => matching.user_1 === contact.user.user_id);
            if (!matching) {
                matching = matchings.find((matching) => matching.user_2 === contact.user.user_id);
            }
            matchingArray.push({ contact, matching });
        });
        setMatchingByContacts(matchingArray);
    }, [contacts, matchings])

    const getContactsMatchings = () => {
        setLoading(true);
        const data = {
            contacts: contacts.map((contact) => contact.user.user_id).filter((user_id) => user_id),
            company_id: companyId
        }
        let config = {
            method: 'post',
            url: server + '/company/get_contact_avatar_matchings/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        axios(config)
            .then(res => {
                setLoading(false);
                if (res.data && res.data.length) {
                    setMatchings(res.data);
                } else {
                    setMatchings([]);
                }
            })
            .catch((err) => {
                console.warn('error in fetching contacts', err);
                setLoading(false);
            })
    }

    useEffect(() => {
        if (printMode) {
            printTable();
        }
    }, [printMode])

    const toggle = () => {
        if (props && props.toggle) {
            props.toggle();
        }
    }

    const updateQuotationTableData = (data) => {
        setQuotationTableData(data);
    }

    const removeMemberConfirmation = (contact) => {
        setConfirmDialogConfig({
            title: `${t('Confirm deletion?')}`,
            onYesAction: () => removeMember(contact),
        })
    }

    const removeMember = (contact) => {
        setLoading(true);
        removeMemberFromMission(mission.pk, contact.user.id, companyId)
            .then((res) => {
                console.log('remove member res', res);
                toast.success(t('Candidate removed successfully'));
                if (props.reload) {
                    props.reload();
                }
                setLoading(false);
            });
    }


    const printTable = () => {
        const modal = document.getElementById('missionDetails');
        const html = modal.getElementsByClassName('modal-content')[0];
        html.style.padding = '16px 32px 32px 32px';
        htmlToImage.toPng(html, { pixelRatio: 1 })
            .then(function (dataUrl) {
                var a = document.createElement("a"); //Create <a>
                a.href = dataUrl; //Image Base64 Goes here
                const fileName = `${mission?.reference?.substring(0, 15)}-${mission?.mission_name?.substring(0, 15)}`;
                a.download = `${fileName}.png`; //File name Here
                a.click(); //Downloaded file
                a.remove();
                toast.success(t('Mission details downloaded successfully'));
                setPrintMode(false);
                html.style.padding = 'unset';
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                toast.error(t('Failed to download mission details'));
                setPrintMode(false);
                html.style.padding = 'unset';
            });
    }

    const showScore = (data, key) => {
        if (data && data.extra_fields && data.extra_fields[key] >= 0) {
            let value = <div className='circle green'></div>;
            if (data.extra_fields[key] >= 0.20 && data.extra_fields[key] <= 0.40) {
                value = <div className='circle orange'></div>;
            } else if (data.extra_fields[key] > 0.40) {
                value = <div className='circle red'></div>;
            }
            return value;
        } else {
            return null;
        }
    }

    return (
        <MDBModal isOpen={modal} toggle={toggle} size='xl' className='value-details-modal' id='missionDetails'>
            <Loader loading={(loading || props.loading)}>
                {
                    props.mission &&
                    <MDBModalBody>
                        {
                            printMode &&
                            <div className='d-flex justify-content-center align-items-center p-3'>
                                <h4>{t("Value")}</h4>
                            </div>
                        }
                        <div className='modal-header d-flex justify-content-between align-items-center'>
                            <div className='mx-2'>
                                <MDBTooltip domElement tag="span" placement="bottom">
                                    <span>{truncate(props.mission.mission_name, 30)}</span>
                                    <span>{props.mission.mission_name}</span>
                                </MDBTooltip>
                            </div>
                            <div className='mx-2'>{t("Ref.")} : {props.mission.reference}</div>
                            <span></span>

                            <div className='d-flex ml-3 mission-details-action-btns'>
                                {
                                    (!loading && !printMode) &&
                                    <button className='action-btn' onClick={() => setPrintMode(true)}>
                                        <MDBIcon icon="print" className='icon' />
                                    </button>
                                }
                                {
                                    (mission && !printMode) &&
                                    <Link to={`/values/${mission.pk}/update`}>
                                        <button className='action-btn'>
                                            <EditIcon className='icon' />
                                        </button>
                                    </Link>
                                }
                                {
                                    (!printMode) &&
                                    <button className='action-btn' onClick={toggle}>
                                        <MDBIcon icon="times" className='icon' />
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='overflow-auto'>
                            <Table id='myValueMissions' className='missions'
                                columns={[
                                    [
                                        { key: 'contact.user.last_name', title: 'Last name', sortable: true },
                                        { key: 'contact.user.first_name', title: 'First name', sortable: true },
                                        { key: 'contact.user.contact_type', title: 'Role', sortable: true },
                                        { key: 'matching.ircp', title: 'Avatar', tooltip: 'Avatar Compatibility', sortable: true },
                                        ...extraSupportedFields.map((valueField) => {
                                            return { key: `matching.extra_fields.${valueField.field_name_in_api}`, title: valueField?.field_name_for_user, className: 'value', sortable: true }
                                        }),
                                        { title: '', hidden: printMode }
                                    ]
                                ]}
                                data={matchingByContacts}
                                rowComponent={
                                    ({ contact, matching }, index) => (
                                        <tr key={`row-${contact.user.id}-${index}`} id={`${contact.user.id}_${contact.user.contact_referral}`}>
                                            <td>
                                                <MDBTooltip domElement tag="span" placement="top">
                                                    <span>{truncate(contact.user.last_name, 15)}</span>
                                                    <span>{contact.user.last_name}</span>
                                                </MDBTooltip>
                                            </td>
                                            <td>
                                                <MDBTooltip domElement tag="span" placement="top">
                                                    <span>{truncate(contact.user.first_name, 15)}</span>
                                                    <span>{contact.user.first_name}</span>
                                                </MDBTooltip>
                                            </td>
                                            <td>{t(getContactType(contact.user.contact_type))}</td>
                                            {
                                                (matching && Object.keys(matching).length) ?
                                                    <>
                                                        <td>
                                                            <ProgressBar value={matching.ircp ? matching.ircp * 100 : 0} text={matching.ircp ? matching.ircp * 100 : 0} styles={{ pathColor: '#e5e563' }} classes={'font-weight-bold'} />
                                                        </td>
                                                        {
                                                            extraSupportedFields.map((valueField) => {
                                                                return (
                                                                    <td>
                                                                        {showScore(matching, valueField.field_name_in_api)}
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </> :
                                                    <td colSpan={extraSupportedFields.length + 1} className='text-black-50 text-center'>
                                                        {loading ? null : `${contact?.user?.first_name} ${contact?.user?.last_name} ${t("hasn't completed the evaluation yet")}.`}
                                                    </td>
                                            }
                                            {!printMode &&
                                                <td onClick={stopPropagation}>
                                                    <Dropdown
                                                        trigger={['click']}
                                                        overlay={
                                                            <Menu className='p-2' selectable={false}>
                                                                {CompleteProfileSubMenu({ user: contact.user, t })}
                                                                {
                                                                    (quotationAccess && !contact.user.confidential && isEvaluationCompleted(contact.user.result)) ?
                                                                        <MenuItem key='quotationTable'>
                                                                            <MDBTooltip domElement tag="span" placement="top">
                                                                                <button className='action-btn' onClick={() => updateQuotationTableData(contact.user)}>
                                                                                    <TableIcon className='icon' />
                                                                                    <span className='text'>{t("Quotation Table")}</span>
                                                                                </button>
                                                                                <span>{t("Quotation Table")}</span>
                                                                            </MDBTooltip>
                                                                        </MenuItem>
                                                                        :
                                                                        null
                                                                }
                                                                <MenuItem key='remove'>
                                                                    <MDBTooltip domElement tag="span" placement="top">
                                                                        <button className='action-btn' onClick={() => removeMemberConfirmation(contact)}>
                                                                            <TrashIcon className='icon' />
                                                                            <span className='text'>{t("Remove")}</span>
                                                                        </button>
                                                                        <span>{t("Remove")}</span>
                                                                    </MDBTooltip>
                                                                </MenuItem>
                                                            </Menu>
                                                        }
                                                        placement="bottomRight"
                                                    >
                                                        <button className='action-btn' onClick={stopPropagation}>
                                                            <MoreDotsIcon className='icon' />
                                                        </button>
                                                    </Dropdown>
                                                </td>
                                            }
                                        </tr>
                                    )
                                }
                                noDataComponent={loading ? null : <tr><td colSpan='9' className='text-center'>No data available.</td></tr>}
                            />
                        </div>
                        {
                            quotationTableData &&
                            <QuotationTable data={quotationTableData} onToggle={() => setQuotationTableData(null)} />
                        }
                        {
                            confirmDialogConfig ?
                                <ConfirmationDialog isOpen
                                    title={confirmDialogConfig.title}
                                    message={confirmDialogConfig.message}
                                    onYesAction={confirmDialogConfig.onYesAction}
                                    onNoAction={confirmDialogConfig.onNoAction}
                                    onToggle={(state) => !state ? resetConfirmDialogConfig() : null}>
                                </ConfirmationDialog>
                                : null
                        }
                    </MDBModalBody>

                }
            </Loader>
        </MDBModal >
    );
}

export default ValueDetails;