import server from '@config';
import { useAuth } from '@context/auth';
import { getIndividualResult, getProfile } from 'api/api';
import { ProfileContext } from 'context/profile';
import React, { useContext, useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router';
import { gaEvent } from 'utils/utils';
import './LoginModal.css';

function GoogleButton(props) {

  const { setAuthTokens } = useAuth()
  let params = new URLSearchParams(window.location.search)
  let ref = params.get('ref')
  const mission = params.get('mission')
  const contact = params.get('contact')
  const company = params.get('company')

  const profileContext = useContext(ProfileContext);

  useEffect(() => {
    if (profileContext.profile) {
      getIndividualResult();
    }
  }, [profileContext])

  const handleSuccess = (res) => {
    // setAuthTokens(res.tokenObj.access_token)
    props.setModal(true)

    let body = {
      site: "google",
      email: res.profileObj.email,
      first_name: res.profileObj.givenName,
      last_name: res.profileObj.familyName,
      picture: res.profileObj.imageUrl,
      referral: ref,
    }
    if (mission) {
      body.mission = mission;
    }
    if (contact) {
      body.contact = contact;
    }
    if (company) {
      body.company = company;
    }
    if (props.location.pathname.includes('/pro')) {
      body.is_b2b = true;
    } else {
      body.is_b2b = false;
    }

    fetch(server + '/auth/user', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(data => {
        console.log('google login', data)
        if (data) {
          if (data.status === 0) {
            toast.error(data.message);
          } else {
            gaEvent("CONNECTION", "CONNECTION_GOOGLE", "Connected with Google");
            localStorage.setItem('email', data.email)
            localStorage.setItem('referral', data.referral)
            localStorage.setItem('token', data.token)
            setAuthTokens(data.token)
            getProfile().then((profile) => {
              profileContext.setProfile();
              if (profile) {
                profileContext.setProfile();
                getIndividualResult().then((res) => {
                  if (res.data.w_1 !== -1) {
                    props.setPassed(true)
                  }
                  props.setEmail(localStorage.getItem('email'))
                })
              }
            })
          }
        }

      })
      .catch(err => {
        console.log(err)
        toast.error("Login with Google failed")
      })
  }

  const handleFailure = (err) => {
    console.log(err, ' login google error')
    toast.error("Login with Google failed")
  }

  return (
    <GoogleLogin
      clientId="921661925946-2dbkmmm1k70ijatmbqt58i8ke2oo8bvc.apps.googleusercontent.com"
      buttonText=""
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      cookiePolicy={'single_host_origin'}
      className="btn-google"
    >
      <i className="fab fa-google-plus-g" />&nbsp;&nbsp;&nbsp;
        Google
    </GoogleLogin>
  )
}

export default withRouter(GoogleButton);