import React, { useState } from 'react'
// import axios from 'axios'
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBTooltip } from 'mdbreact'
import {
  LinkedinShareButton, FacebookShareButton, WhatsappShareButton,
  FacebookIcon, WhatsappIcon, LinkedinIcon
} from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import illustration from '../../../assets/invite-lady.svg'
import invite_icon from '../../../assets/red-mail.png'
// import server from '@config'
import '../Results.css'
import InviteEmailModal from './InviteEmailModal'
import { getLanguage, gaEvent } from 'utils/utils'

function InviteModal(props) {

  const [modalState, setModalState] = useState(false)
  const [isChecked, setIsChecked] = useState(true)

  var lang = getLanguage();
  const { t } = useTranslation();
  // const [copied, setCopied] = useState('no-copied')
  const shareUrl = window.location.origin + '/?ref=' + localStorage.getItem('referral')
  const size = 50

  // useEffect (() => {
  //   setCopied('no-copied')
  // }, [modalState])

  const toggle = () => {
    setModalState(!modalState)
  }

  const handleCheck = (e) => {
    setIsChecked(e.target.checked)
  }

  return (
    <div>

      <div className="share-results-invite-frame cursor-pointer wwt-btn" onClick={toggle}>
        <img src={invite_icon} alt="invite-icon" className="results-invite-icon" />
        <div className="share-results-invite-content" >{props.title}</div>
      </div>
      <MDBContainer>
        <MDBModal isOpen={modalState} toggle={toggle}>
          <MDBModalHeader toggle={toggle} className="invite-modal-header">
            <div className="invite-modal-title">{props.title}</div>
          </MDBModalHeader>
          <MDBModalBody className="help-modal-body">
            <div className="invite-modal-frame">
              <div className="invite-modal-frame-text">
                {t("Invite your colleagues to take the test to know how you match professionally")}
              </div>
              <img src={illustration} alt="illustration" className="invite-modal-avatar" />
            </div>
            <div className="custom-control custom-checkbox invite-checkbox">
              <input type="checkbox" className="custom-control-input" defaultChecked id="defaultUncheckedDisabled2" onChange={handleCheck} />
              <label className="custom-control-label" htmlFor="defaultUncheckedDisabled2">
                {t("I authorize the persons I invite to share our matching results on social media")}
              </label>
            </div>
            <div className="invite-label">{t("Invite with")}:</div>
            <div className="share-button-group">
              <LinkedinShareButton url={shareUrl} beforeOnClick={() => {
                gaEvent("MATCHING", "MATCHING_REQUEST_LINKEDIN", "Matching Request with LinkedIn");
              }} className="social-share-button">
                <LinkedinIcon size={size} round />
                      Linkedin
                    </LinkedinShareButton>
              <FacebookShareButton url={shareUrl} beforeOnClick={() => {
                gaEvent("MATCHING", "MATCHING_REQUEST_FACEBOOK", "Matching Request with Facebook");
              }} className="social-share-button">
                <FacebookIcon size={size} round />
                      Facebook
                    </FacebookShareButton>
              <WhatsappShareButton url={shareUrl} beforeOnClick={() => {
                gaEvent("MATCHING", "MATCHING_REQUEST_WHATSAPP", "Matching Request with Whatsapp");
              }} className="social-share-button">
                <WhatsappIcon size={size} round />
                      Whatsapp
                    </WhatsappShareButton>
              <InviteEmailModal shareUrl={shareUrl} size={size} />
            </div>
            <div className="clipboard">
              <div className='position-relative m-auto'>
                <MDBTooltip
                  domElement
                  tag="div"
                  placement="top"
                  clickable
                >
                  <div>
                    <CopyToClipboard
                      text={shareUrl}
                      onCopy={() => gaEvent("MATCHING", "MATCHING_REQUEST_COPYLINK", "Matching Request with Copy Link")}
                    >
                      <div className="clipboard-button wwt-btn btn-primary cursor-pointer">
                        <i className="far fa-copy clipboard-icon" />
                        <div>{t("Copy the link")}</div>
                      </div>
                    </CopyToClipboard>
                  </div>
                  <div>{
                    {
                      'en': 'link copied',
                      'fr': 'lien copié',
                      'ru': ''
                    }[lang]
                  }</div>
                </MDBTooltip>
              </div>
              {/* <span className = {copied}><i className="fas fa-check" style={{color:'green'}}></i></span> */}
            </div>
          </MDBModalBody>

        </MDBModal>
      </MDBContainer>
    </div>
  )
}

export default InviteModal;