import server from '@config';
import axios from 'axios';
import ConfirmationDialog from 'components/common/Dialogs/ConfirmationDialog';
import TableIcon from 'components/common/Icons/TableIcon';
import Table from 'components/common/Table/Table';
import TrashIcon from 'components/common/Icons/TrashIcon';
import MoreDotsIcon from 'components/common/Icons/MoreDotsIcon';
import Loader from 'components/Loader/Loader';
import { ContactTypes, getContactType } from 'constants/constants';
import { MDBBtn, MDBContainer, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBTooltip } from 'mdbreact';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { MenuItem } from 'rc-menu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkQuotationAccess, getProfile, isDateSixMonthsAgo, isEvaluationCompleted, truncate, stopPropagation } from 'utils/utils';
import CompleteProfileBtn from '../common/CompleteProfileBtn/CompleteProfileBtn';
import QuotationTable from '../common/QuotationTable/QuotationTable';
import ContactForm from '../ContactForm/ContactForm';
import toast from 'react-hot-toast'
import Header from '../Header/Header';
import './CompanyContacts.css';


function CompanyContacts(props) {

    const { t } = useTranslation();

    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [filters, setFilters] = useState({
        input: '',
        types: ['C', 'E']
    });
    const [questionnaireTypes, setQuestionnaireTypes] = useState([]);
    const [quotationTableData, setQuotationTableData] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [contactToDelete, setContactToDelete] = useState(null);
    const [contactToRefreshProfile, setContactToRefreshProfile] = useState(null);

    const quotationAccess = checkQuotationAccess();

    const contactStatusMap = {
        QUESTIONNAIRE_COMPLETED: 'Questionnaire completed',
        QUESTIONNAIRE_IN_PROGRESS: 'Questionnaire in progress',
        INVITATION_SENT: 'Invitation sent',
        INVITATION_NOT_SENT: 'Not invited yet'
    }

    useEffect(() => {
        const profile = getProfile();
        if (profile && profile.companies && profile.companies.length) {
            setCompanyId(profile.companies[0].company.pk);
        }
    }, [])

    useEffect(() => {
        if (companyId) {
            getCompanyContacts();
            getCompanyQuestionnaireTypes();
        }
    }, [companyId])

    useEffect(() => {
        filterContacts();
    }, [contacts])

    useEffect(() => {
        filterContacts();
    }, [filters])

    const getCompanyContacts = () => {
        setLoading(true);
        let config = {
            method: 'get',
            url: server + '/company/company_contact/?company_id=' + companyId,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('contacts', res.data);
                res.data.forEach((contact) => {
                    contact.first_name = contact.first_name || '';
                    contact.last_name = contact.last_name || '';
                    if (contact.user_id) {
                        if (contact.complete_profile && (contact.complete_profile.en || contact.complete_profile.fr)) {
                            contact.status = contactStatusMap.QUESTIONNAIRE_COMPLETED;
                        } else {
                            contact.status = contactStatusMap.QUESTIONNAIRE_IN_PROGRESS;
                        }
                    } else if (contact.invitation_sent) {
                        contact.status = contactStatusMap.INVITATION_SENT;
                    } else {
                        contact.status = contactStatusMap.INVITATION_NOT_SENT;
                    }
                })
                setContacts(res.data);
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const getCompanyQuestionnaireTypes = () => {
        setLoading(true);
        let config = {
            method: 'get',
            url: server + '/response/questionnaire_type?company_id=' + companyId,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('questionnaire types', res.data);
                res.data = res.data.filter((questionnaireType, index, self) => {
                    return self.findIndex((v) => v.pk === questionnaireType.pk) === index;
                });
                setQuestionnaireTypes(res.data);
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const filterContacts = () => {
        let contactList = contacts.slice();
        contactList = contactList.filter((c) => {
            const input = filters.input ? filters.input.toLowerCase() : '';
            if (input) {
                if (!(c.first_name.toLowerCase().includes(input) || c.last_name.toLowerCase().includes(input) || c.email.toLowerCase().includes(input))) {
                    return false;
                }
            }
            if (!filters.types.includes(c.contact_type)) {
                return false;
            }
            return true;
        });
        console.log('filtered contacts', filteredContacts)
        setFilteredContacts(contactList);
    }

    const onChangeInput = (event) => {
        if (filters) {
            setFilters({ ...filters, input: event.target.value });
        }
    }

    const toggleTypeFilter = (type) => {
        let types = filters.types;
        if (types.includes(type)) {
            types = types.filter((t) => t !== type);
        } else {
            types.push(type);
        }
        setFilters({ ...filters, types });
    }

    const showStatusBtns = (contact) => {
        switch (contact.status) {
            case contactStatusMap.QUESTIONNAIRE_COMPLETED:
                return showQuestionnaireCompletedBtn(contact);

            case contactStatusMap.QUESTIONNAIRE_IN_PROGRESS:
                return showQuestionnaireInProgressBtn(contact);

            case contactStatusMap.INVITATION_SENT:
                return showInvitationSentBtn(contact);

            case contactStatusMap.INVITATION_NOT_SENT:
                return showInvitationNotSentBtn(contact);

            default:
                return null;
        }
    }

    const showQuestionnaireCompletedBtn = (contact) => {
        if (contact.user_id && contact.complete_profile) {
            if (contact.confidential) {
                return (
                    <MDBTooltip domElement tag='div' placement="top">
                        <div className='d-inline-block cursor-not-allowed'>
                            <MDBBtn
                                rounded
                                className="inline-block btn wwt-btn flat text-transform-none"
                                disabled={contact.confidential}
                            >
                                {t("Questionnaire completed")}
                            </MDBBtn>
                        </div>
                        <span>{t("This profile is confidential")}</span>
                    </MDBTooltip>
                )
            } else {
                return (
                    <>
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu className='p-2 pr-4' selectable={false}>
                                    <MenuItem key='pdf_en'>
                                        <a href={contact.complete_profile.en} target='_blank' rel="noopener noreferrer">
                                            <button className='action-btn'>
                                                <MDBIcon icon="download" className='icon' />
                                                <span className='text'>{t("English")}</span>
                                            </button>
                                        </a>
                                    </MenuItem>
                                    <MenuItem key='pdf_fr'>
                                        <a href={contact.complete_profile.fr} target='_blank' rel="noopener noreferrer">
                                            <button className='action-btn'>
                                                <MDBIcon icon="download" className='icon' />
                                                <span className='text'>{t("French")}</span>
                                            </button>
                                        </a>
                                    </MenuItem>
                                </Menu>
                            }
                            animation="slide-up"
                            placement="bottomLeft"
                        >
                            <div>
                                <MDBTooltip placement="top">
                                    <MDBBtn
                                        rounded
                                        className="inline-block btn wwt-btn flat text-transform-none"
                                    >
                                        {t("Questionnaire completed")}
                                    </MDBBtn>
                                    <span>{t("Click to download Complete Profile")}</span>
                                </MDBTooltip>
                            </div>
                        </Dropdown>
                    </>
                )
            }
        }
        return null;
    }

    const showQuestionnaireInProgressBtn = (contact) => {
        if (contact.user_id && contact.complete_profile && !(contact.complete_profile.en || contact.complete_profile.fr)) {
            return (
                <ConfirmationDialog
                    title={t('Send Invitation')}
                    message={`${t('Do you want to send invitation to')} <span class='text-primary'>${contact.first_name} ${contact.last_name}</span>?`}
                    onYesAction={() => sendCompleteQuestionnaireReminder(contact)}>
                    <MDBTooltip placement="top">
                        <MDBBtn
                            id='qInProgressBtn'
                            rounded
                            className="inline-block btn wwt-btn flat text-transform-none"
                        // onClick={() => openSendInvitationModal(contact)}
                        >
                            {t("Questionnaire in progress")}
                        </MDBBtn>
                        <span>{t("Click to send invitation again")}</span>
                    </MDBTooltip>
                </ConfirmationDialog>
            )
        }
        return null;
    }

    const showInvitationSentBtn = (contact) => {
        if (contact.user_id && contact.complete_profile && (contact.complete_profile.en || contact.complete_profile.fr)) {
            return null;
        } else {
            if (contact.invitation_sent) {
                return (
                    <ConfirmationDialog
                        title={t('Send Invitation')}
                        message={`${t('Do you want to send invitation to')} <span class='text-primary'>${contact.first_name} ${contact.last_name}</span>?`}
                        onYesAction={() => sendIntivation(contact)}>
                        <MDBTooltip placement="top">
                            <MDBBtn
                                rounded
                                color='orange'
                                className="inline-block btn wwt-btn flat text-transform-none"
                            // onClick={() => openSendInvitationModal(contact)}
                            >
                                {t("Invitation sent")}
                            </MDBBtn>
                            <span>{t("Click to send invitation again")}</span>
                        </MDBTooltip>
                    </ConfirmationDialog>
                )
            }
        }
        return null;
    }

    const showInvitationNotSentBtn = (contact) => {
        if (contact.user_id && contact.complete_profile && (contact.complete_profile.en || contact.complete_profile.fr)) {
            return null;
        } else {
            if (!contact.invitation_sent) {
                return (
                    <ConfirmationDialog
                        title={t('Send Invitation')}
                        message={`${t('Do you want to send invitation to')} <span class='text-primary'>${contact.first_name} ${contact.last_name}</span>?`}
                        onYesAction={() => sendIntivation(contact)}>
                        <MDBTooltip placement="top">
                            <MDBBtn
                                rounded
                                color='grey'
                                className="inline-block btn wwt-btn flat text-transform-none"
                            >
                                {t("Not invited yet")}
                            </MDBBtn>
                            <span>{t("Click to send invitation")}</span>
                        </MDBTooltip>
                    </ConfirmationDialog>
                )
            }
        }
        return null;
    }

    const sendCompleteQuestionnaireReminder = (contact) => {
        // setLoading(true);
        let config = {
            method: 'post',
            url: server + '/company/complete_questionnaire_reminder/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                contact_referral: contact.contact_referral
            }
        }
        // axios(config)
        //     .then(res => {
        //         setLoading(false);
        //         getCompanyContacts();
        //     })
        //     .catch((err) => {
        //         setLoading(false);
        //     })
    }

    const sendIntivation = (contact) => {
        setLoading(true);
        let config = {
            method: 'post',
            url: server + '/company/invite_contact/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                contact_referral: contact.contact_referral
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                getCompanyContacts();
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const QuotationTableBtn = (user) => {
        return (
            (quotationAccess && !user.confidential && isEvaluationCompleted(user.result)) ?
                <MDBTooltip domElement tag="span" placement="top">
                    <button className='action-btn' onClick={() => updateQuotationTableData(user)}>
                        <TableIcon className='icon' />
                    </button>
                    <span>{t("Quotation Table")}</span>
                </MDBTooltip>
                :
                <MDBTooltip domElement tag="span" placement="top">
                    <button className='action-btn disabled'>
                        <TableIcon className='icon' />
                    </button>
                    <span>{t("Quotation Table")}</span>
                </MDBTooltip>
        )
    }

    const updateQuotationTableData = (data) => {
        setQuotationTableData(data);
    }

    const updateContact = (contact, contact_type) => {
        setLoading(true);
        const data = JSON.parse(JSON.stringify(contact));
        delete data.result;
        delete data.complete_profile;
        data.contact_type = contact_type;
        let config = {
            method: 'put',
            url: server + '/company/company_contact/update/' + data.id + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        }
        axios(config)
            .then(res => {
                toast.success(t('Status modified'));
                setLoading(false);
                getCompanyContacts();
                setSelectedContact(null);
            })
            .catch((err) => {
                setLoading(false);
                setSelectedContact(null);
            })
    }

    const deleteContact = (contact) => {
        setLoading(true);
        let config = {
            method: 'delete',
            url: server + '/company/company_contact/update/' + contact.id + '/',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                toast.success(t('Profile deleted from contact list'));
                setLoading(false);
                getCompanyContacts();
                setContactToDelete(null);
            })
            .catch((err) => {
                setLoading(false);
                setContactToDelete(null);
            })
    }

    const shouldShowRefreshProfileBtn = (contact) => {
        const date = contact?.result?.date;
        if (date && isDateSixMonthsAgo(date)) {
            return true;
        }
        return false;
    }

    const refreshProfile = (contact) => {
        setLoading(true);
        const data = {
            user_email: contact.email,
            company_id: contact.company_id,
        };
        let config = {
            method: 'post',
            url: server + '/auth/re-invite/',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        }
        axios(config)
            .then(res => {
                toast.success(t('invitation sent”'));
                setLoading(false);
                getCompanyContacts();
                setContactToRefreshProfile(null);
            })
            .catch((err) => {
                setLoading(false);
                setContactToRefreshProfile(null);
            })
    }



    return (
        <React.Fragment>
            <div className='admin-container' style={{ background: "#eeeeee", minHeight: '100vh' }}>
                <Header />
                <div className='container py-4 pt-5'>
                    <div className="row">
                        <div className='d-flex justify-content-between align-items-center w-100 mb-5'>
                            <h2 className='missions-header'>{t("Company Contacts List")}</h2>
                            <div>
                                <ContactForm companyId={companyId} allowedContactTypes={[ContactTypes.Candidate.value, ContactTypes.Employee.value]} questionnaireTypes={questionnaireTypes} onSave={(contact) => getCompanyContacts()}>
                                    <MDBBtn className='wwt-btn flat wwt-btn-pink px-5' >
                                        + {t("New")}
                                    </MDBBtn>
                                </ContactForm>
                            </div>
                        </div>
                        <MDBContainer>
                            <div className='wwt-card p-4'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <input className='wwt-input' placeholder={t("First name, last name or email of the contact")} value={filters?.input} onChange={(event) => onChangeInput(event)} />
                                    </div>
                                    <div className='col-6'>
                                        <div className='align-items-center h-100 row ml-3'>
                                            <span>{t("Type of contact")}</span>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id={ContactTypes.Employee.value} defaultChecked={filters.types.includes('E')} onClick={() => toggleTypeFilter('E')} />
                                                <label className="custom-control-label" htmlFor={ContactTypes.Employee.value}>{t(ContactTypes.Employee.name)}</label>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id={ContactTypes.Candidate.value} defaultChecked={filters.types.includes('C')} onClick={() => toggleTypeFilter('C')} />
                                                <label className="custom-control-label" htmlFor={ContactTypes.Candidate.value}>{t(ContactTypes.Candidate.name)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Loader loading={loading}>
                                <div className='mt-4 wwt-card'>
                                    <Table className='company-contacts'
                                        columns={[
                                            [
                                                { key: 'first_name', title: 'Last name and first name', sortable: true },
                                                { key: 'contact_type', title: 'Role', sortable: true },
                                                { key: 'status', title: 'Status', sortable: true },
                                                { title: '' },
                                            ]
                                        ]}
                                        data={filteredContacts}
                                        rowComponent={
                                            (contact, index) => (
                                                <tr key={`row-${contact.id}-${index}`}>
                                                    <td>
                                                        <MDBTooltip
                                                            domElement
                                                            tag="span"
                                                            placement="top"
                                                        >
                                                            <span>{truncate(`${contact.last_name} ${contact.first_name}`, 30)}</span>
                                                            <span>{contact.last_name} {contact.first_name}</span>
                                                        </MDBTooltip>
                                                    </td>
                                                    <td>{t(getContactType(contact.contact_type))}</td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            {showStatusBtns(contact)}
                                                        </div>
                                                    </td>
                                                    <td onClick={stopPropagation}>

                                                        </td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <CompleteProfileBtn user={contact} />
                                                            <div className='ml-n3'>{QuotationTableBtn(contact)}</div>
                                                            <Dropdown
                                                                trigger={['click']}
                                                                overlay={
                                                                    <Menu className='p-2' selectable={false}>
                                                                        {
                                                                            (contact.contact_type === 'C') ?
                                                                                <MenuItem key='changeContactType'>
                                                                                    <button className='action-btn' onClick={() => setSelectedContact(contact)}>
                                                                                        <span className='text'>{t("Add to employee list")}</span>
                                                                                    </button>
                                                                                </MenuItem>
                                                                                : null
                                                                        }
                                                                        {
                                                                            shouldShowRefreshProfileBtn(contact)  && false ?
                                                                                <MenuItem key='refreshProfile'>
                                                                                    <button className='action-btn'
                                                                                        onClick={() => setContactToRefreshProfile(contact)}>
                                                                                        <span className='text'>{t("Invite the employee to retake the questionnaire")}</span>
                                                                                    </button>
                                                                                </MenuItem>
                                                                            : null
                                                                        }
                                                                        <MenuItem key='remove'>
                                                                            <button className='action-btn' onClick={() => setContactToDelete(contact)}>
                                                                                {/* <TrashIcon className='icon' /> */}
                                                                                <span className='text'>{t("Delete")}</span>
                                                                            </button>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                }
                                                                animation="slide-up"
                                                                placement="bottomRight"
                                                                // onOverlayClick={(e) => onMissionActionClick(mission, e.key)}
                                                            >
                                                                <button className='action-btn' onClick={stopPropagation}>
                                                                    <MoreDotsIcon className='icon' />
                                                                </button>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    />
                                    {
                                        (contacts?.length && !filteredContacts?.length) ?
                                            <div className='grey-text p-3'>No contacts available for applied filter.</div>
                                            : null
                                    }
                                </div>
                            </Loader>
                        </MDBContainer>
                    </div>
                </div>
                {
                    quotationTableData &&
                    <QuotationTable data={quotationTableData} onToggle={() => setQuotationTableData(null)} />
                }
                {
                    selectedContact &&
                    <MDBModal isOpen toggle={() => { setSelectedContact(null) }} size='md' className='contact-update-confirmation-dialog'>
                        <MDBModalBody>
                            <div className='dialog-title'>{t("Confirm modification?")}</div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <div className='d-flex justify-content-end'>
                                <button className='btn wwt-btn flat wwt-btn-red btn-no' onClick={() => setSelectedContact(null)}>{t('No')}</button>
                                <button className='btn wwt-btn flat wwt-btn-green btn-yes' onClick={() => updateContact(selectedContact, 'E')}>{t('Yes')}</button>
                            </div>
                        </MDBModalFooter>
                    </MDBModal>
                }
                {
                    contactToDelete &&
                    <MDBModal isOpen toggle={() => { setContactToDelete(null) }} size='md' className='contact-update-confirmation-dialog'>
                        <MDBModalBody>
                            <div className='dialog-title'>{t("Confirm modification?")}</div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <div className='d-flex justify-content-end'>
                                <button className='btn wwt-btn flat wwt-btn-red btn-no' onClick={() => setContactToDelete(null)}>{t('No')}</button>
                                <button className='btn wwt-btn flat wwt-btn-green btn-yes' onClick={() => deleteContact(contactToDelete)}>{t('Yes')}</button>
                            </div>
                        </MDBModalFooter>
                    </MDBModal>
                }
                {
                    contactToRefreshProfile &&
                    <MDBModal isOpen toggle={() => { setContactToRefreshProfile(null) }} size='md' className='contact-update-confirmation-dialog'>
                        <MDBModalBody>
                            {/* <div className='dialog-title'>{t("Confirm modification?")}</div>
                            <div className='dialog-title'>Are you sure?</div> */}
                            <div>{t("Do you want to invite this person to retake the questionnaire? If you proceed, the existing profile of this person will be deleted, and they will receive a link to retake the questionnaire. If you wish to save their current profile, please print it before continuing.")}</div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <div className='d-flex justify-content-end'>
                                <button className='btn wwt-btn flat wwt-btn-red btn-no' onClick={() => setContactToRefreshProfile(null)}>{t('cancel')}</button>
                                <button className='btn wwt-btn flat wwt-btn-green btn-yes' onClick={() => refreshProfile(contactToRefreshProfile)}>{t('proceed')}</button>
                            </div>
                        </MDBModalFooter>
                    </MDBModal>
                }
            </div>
        </React.Fragment>
    );
}

export default CompanyContacts;