import { removeBenchmarkFromMission } from 'api/mission';
import CompleteProfileBtn from 'components/Admin/common/CompleteProfileBtn/CompleteProfileBtn';
import QuotationTable from 'components/Admin/common/QuotationTable/QuotationTable';
import ConfirmationDialog from 'components/common/Dialogs/ConfirmationDialog';
import EditIcon from 'components/common/Icons/EditIcon';
import TableIcon from 'components/common/Icons/TableIcon';
import TrashIcon from 'components/common/Icons/TrashIcon';
import Table from 'components/common/Table/Table';
import Loader from 'components/Loader/Loader';
import { BenchmarkTypes } from 'constants/constants';
import * as htmlToImage from 'html-to-image';
import { MDBIcon, MDBModal, MDBModalBody, MDBTooltip } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { checkQuotationAccess, isEvaluationCompleted, truncate } from 'utils/utils';
import AllMatchings from './AllMatchings/AllMatchings';
import './TeamBuildingDetails.css';

function TeamBuildingDetails(props) {

    const [mission, setMission] = useState(null);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [CPI, setCPI] = useState(0);
    const [showAllMatchings, setShowAllMatchings] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [searchInput, setSearchInput] = useState(null);
    const [printMode, setPrintMode] = useState(false);

    const [quotationTableData, setQuotationTableData] = useState(null);

    const quotationAccess = checkQuotationAccess();
    const [confirmDialogConfig, setConfirmDialogConfig] = useState(null);

    const resetConfirmDialogConfig = () => {
        setTimeout(() => {
            setConfirmDialogConfig(null);
        }, 500);
    }

    const { t } = useTranslation();

    useEffect(() => {
        if (props.mission) {
            setMission(props.mission);
            setModal(true);
            setCPI(getCPI());
            updateGridData(props.mission);
        } else {
            setModal(false);
        }
    }, [props.mission, props.matchings, CPI])

    useEffect(() => {
        if (printMode) {
            printTable();
        }
    }, [printMode])

    const updateGridData = (mission) => {
        const data = [];
        if (mission.mission_manager) {
            data.push({ ...mission.mission_manager, type: 'Manager', impactOnCPI: getImpactOnCPI(mission.mission_manager.user_id) })
        }
        if (mission.mission_benchmarks) {
            mission.mission_benchmarks.forEach((benchmark) => {
                data.push({ ...benchmark.contact, type: BenchmarkTypes[benchmark.benchmark_type], impactOnCPI: getImpactOnCPI(benchmark.contact.user_id), matching: getMatching(mission.mission_manager.user_id, benchmark.contact.user_id) })
            });
        }
        setGridData(data);
    }

    const getMatching = (user_1, user_2) => {
        console.log('get matchings', props.matchings.length);
        const matchings = props.matchings.filter((m) => (m.user_1 === user_1 && m.user_2 === user_2) || (m.user_1 === user_2 && m.user_2 === user_1));
        if (matchings.length) {
            return matchings[0];
        }
        return null;
    }

    const getCPI = () => {
        let rui_smooth = 0;
        let cpi = 0;
        const matchings = props.matchings || [];
        if (matchings && matchings.length) {
            matchings.forEach((matching) => {
                rui_smooth += matching.rui_smooth;
            });
            cpi = rui_smooth * 100 / matchings.length;
        }
        return +cpi.toFixed(2);
    }

    const toggle = () => {
        if (props && props.toggle) {
            props.toggle();
        }
    }

    const getImpactOnCPI = (userId) => {
        let impactOnCpi = 0;
        const matchings = props.matchings;
        if (userId && CPI) {
            let cpi_without_user = 0;
            let cpi_with_user = 0;
            let count_without_user = 0;
            let count_with_user = 0;
            if (matchings && matchings.length) {
                matchings.forEach((matching) => {
                    if (matching.rui_smooth !== 0) {
                        count_with_user++;
                        cpi_with_user += matching.rui_smooth;
                        if (matching.user_1 !== userId && matching.user_2 !== userId) {
                            cpi_without_user += matching.rui_smooth;
                            count_without_user++;
                        }
                    }
                });
                cpi_without_user = cpi_without_user * 100 / count_without_user;
                cpi_with_user = cpi_with_user * 100 / count_with_user;
                impactOnCpi = (cpi_with_user - cpi_without_user) * 100 / cpi_without_user;
            }
        }
        return +impactOnCpi.toFixed(2);
    }

    const QuotationTableBtn = (user) => {
        return (
            (quotationAccess && !user.confidential && isEvaluationCompleted(user.result)) ?
                <MDBTooltip domElement tag="span" placement="top">
                    <button className='action-btn' onClick={() => updateQuotationTableData(user)}>
                        <TableIcon className='icon' />
                    </button>
                    <span>{t("Quotation Table")}</span>
                </MDBTooltip>
                :
                <MDBTooltip domElement tag="span" placement="top">
                    <button className='action-btn disabled'>
                        <TableIcon className='icon' />
                    </button>
                    <span>{t("Quotation Table")}</span>
                </MDBTooltip>
        )
    }

    const updateQuotationTableData = (data) => {
        setQuotationTableData(data);
    }

    const filterData = (data) => {
        const input = searchInput ? searchInput.toLowerCase() : '';
        if (input) {
            const matchString = [data.last_name, data.first_name].filter((d) => d).join('').toLowerCase();
            if (matchString.includes(input)) {
                return true;
            }
            return false;
        }
        return true;
    }

    const printTable = () => {
        const modal = document.getElementById('missionDetails');
        const html = modal.getElementsByClassName('modal-content')[0];
        html.style.padding = '16px 32px 32px 32px';
        htmlToImage.toPng(html, { pixelRatio: 1 })
            .then(function (dataUrl) {
                var a = document.createElement("a"); //Create <a>
                a.href = dataUrl; //Image Base64 Goes here
                const fileName = `${mission?.reference?.substring(0, 15)}`;
                a.download = `${fileName}.png`; //File name Here
                a.click(); //Downloaded file
                a.remove();
                toast.success(t('Mission details downloaded successfully'));
                setPrintMode(false);
                html.style.padding = 'unset';
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                toast.error(t('Failed to download mission details'));
                setPrintMode(false);
                html.style.padding = 'unset';
            });
    }

    const removeBenchmarkConfirmation = (contact) => {
        setConfirmDialogConfig({
            title: `${t('Confirm deletion?')}`,
            onYesAction: () => removeBenchmark(contact),
        })
    }

    const removeBenchmark = (contact) => {
        setLoading(true);
        removeBenchmarkFromMission(mission.pk, contact.id)
            .then((res) => {
                toast.success(t('Team member removed successfully'));
                if (props.reload) {
                    props.reload();
                }
                setLoading(false);
                toggle();
            });
    }

    return (
        <MDBModal isOpen={modal} toggle={toggle} size='xl' className='mobility-details-modal' id='missionDetails'>
            <Loader loading={loading}>
                {
                    props.mission &&
                    <MDBModalBody>
                        {
                            printMode &&
                            <div className='d-flex justify-content-center align-items-center p-3'>
                                <h4>{t("Team Building")}</h4>
                            </div>
                        }
                        <div className='modal-header d-flex justify-content-between align-items-center'>
                            <div className='mx-2'>{t("Team of")} {props.mission.mission_manager.first_name} {props.mission.mission_manager.last_name}</div>
                            <div className='mx-2'>{t("CPI of the team")} : {CPI}%</div>
                            <div className='d-flex ml-3 mission-details-action-btns'>
                                {
                                    (!printMode) &&
                                    <button className='all-matching-btn' onClick={() => setShowAllMatchings(true)}>
                                        {/* <MDBIcon icon="people-arrows" className='icon' /> */}
                                        <span className='text'>{t("Access All Matchings")}</span>
                                    </button>
                                }
                                {
                                    (!loading && !printMode) &&
                                    <button className='action-btn' onClick={() => setPrintMode(true)}>
                                        <MDBIcon icon="print" className='icon' />
                                    </button>
                                }
                                {
                                    (mission && !printMode) &&
                                    <Link to={`/team-building/${mission.pk}/update`}>
                                        <button className='action-btn'>
                                            <EditIcon className='icon' />
                                        </button>
                                    </Link>
                                }
                                {
                                    (!printMode) &&
                                    <button className='action-btn' onClick={toggle}>
                                        <MDBIcon icon="times" className='icon' />
                                    </button>
                                }
                            </div>
                        </div>
                        {
                            !printMode &&
                            <div className='d-flex align-items-center p-2'>
                                <input className='search-input' placeholder={`${t('Search')}...`} onChange={(e) => setSearchInput(e.target.value)} />
                            </div>
                        }
                        <div className={`${!printMode ? 'overflow-auto' : ''}`}>
                            <Table className='missions'
                                columns={[
                                    [
                                        { key: 'last_name', title: 'Last Name', sortable: true },
                                        { key: 'first_name', title: 'First Name', sortable: true },
                                        { key: 'type', title: 'Role', sortable: true },
                                        { key: 'impactOnCPI', title: 'Impact on CPI', sortable: true },
                                        { key: 'result.r_percent', title: 'Action', sortable: true },
                                        { key: 'result.i_percent', title: 'Vision', sortable: true },
                                        { key: 'result.s_percent', title: 'Intention', sortable: true },
                                        // { title: 'Matching Score with Manager' },
                                        { title: '', hidden: printMode },
                                    ]
                                ]}
                                data={gridData.filter((data) => filterData(data))}
                                rowComponent={
                                    (contact, index) => (
                                        <tr key={index}>
                                            <td>
                                                <MDBTooltip domElement tag="span" placement="top">
                                                    <span>{truncate(contact.last_name, 15)}</span>
                                                    <span>{contact.last_name}</span>
                                                </MDBTooltip>
                                            </td>
                                            <td>
                                                <MDBTooltip domElement tag="span" placement="top">
                                                    <span>{truncate(contact.first_name, 15)}</span>
                                                    <span>{contact.first_name}</span>
                                                </MDBTooltip>
                                            </td>
                                            <td>{contact.type}</td>
                                            <td>{contact.impactOnCPI}%</td>
                                            {
                                                (isEvaluationCompleted(contact.result)) ?
                                                    <>
                                                        <td>{(contact.result.r_percent >= 0) ? (contact.result.r_percent * 100).toFixed(0) + '%' : null}</td>
                                                        <td>{(contact.result.i_percent >= 0) ? (contact.result.i_percent * 100).toFixed(0) + '%' : null}</td>
                                                        <td>{(contact.result.s_percent >= 0) ? (contact.result.s_percent * 100).toFixed(0) + '%' : null}</td>
                                                    </>
                                                    : <td colSpan={3} className='text-black-50 text-center'>
                                                        {`${contact?.first_name} ${contact?.last_name} ${t("hasn't completed the evaluation yet")}.`}
                                                    </td>
                                            }
                                            {/* <td>
                                                {
                                                    contact.matching &&
                                                    <ProgressBar value={contact.matching.rui_smooth * 100} text={contact.matching.rui_smooth * 100} />
                                                }
                                            </td> */}
                                            {!printMode &&
                                                <td>
                                                    <div className='d-flex'>
                                                        <CompleteProfileBtn user={contact} />
                                                        {QuotationTableBtn(contact)}
                                                        {
                                                            contact?.id !== mission?.mission_manager?.id &&
                                                            <MDBTooltip domElement tag="span" placement="top">
                                                                <button className='action-btn' onClick={() => removeBenchmarkConfirmation(contact)}>
                                                                    <TrashIcon className='icon' />
                                                                </button>
                                                                <span>{t("Remove Team Member")}</span>
                                                            </MDBTooltip>
                                                        }
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    )
                                }
                                noDataComponent={searchInput ? (<tr><td colSpan='10' className='text-center'>No data available for applied filter.</td></tr>) : null}
                            />
                        </div>
                        {
                            showAllMatchings &&
                            <AllMatchings mission={props.mission} matchings={props.matchings} toggle={() => setShowAllMatchings(false)} />
                        }
                        {
                            quotationTableData &&
                            <QuotationTable data={quotationTableData} onToggle={() => setQuotationTableData(null)} />
                        }
                        {
                            confirmDialogConfig ?
                                <ConfirmationDialog isOpen
                                    title={confirmDialogConfig.title}
                                    message={confirmDialogConfig.message}
                                    onYesAction={confirmDialogConfig.onYesAction}
                                    onNoAction={confirmDialogConfig.onNoAction}
                                    onToggle={(state) => !state ? resetConfirmDialogConfig() : null}>
                                </ConfirmationDialog>
                                : null
                        }
                    </MDBModalBody>

                }
            </Loader>
        </MDBModal>

    );
}

export default TeamBuildingDetails;