import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBRow } from 'mdbreact';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { EmailIcon, EmailShareButton } from 'react-share';
import server from '../../../config';
import axios from 'axios';
import { gaEvent } from 'utils/utils'
import toast from 'react-hot-toast';

function InviteEmailModal(props) {
    const [modalState, setModalState] = useState(false)
    const [nameState, setNameState] = useState();
    const [emailState, setEmailState] = useState();

    const { t } = useTranslation();

    const shareUrl = props.shareUrl;

    const toggle = () => {
        setModalState(!modalState)
    }

    const onNameChange = (e) => {
        setNameState(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmailState(e.target.value);
    }

    const onSubmit = () => {
        gaEvent("MATCHING", "MATCHING_REQUEST_EMAIL", "Matching Request with Email");

        console.log('send email clicked');
        let data = {
            recepter: emailState,
            name: nameState,
            referral_link: shareUrl
        }
        let config = {
            method: "post",
            url: server + '/send/invite',
            data: data
        }
        console.log(config)
        axios(config)
            .then(res => {
                console.log(res.data['message'])
                toast.success(t('Email sent successfully'));
                setModalState(false);
            })
            .catch(err => {
                console.log(err)
            })

    }

    return (
        <React.Fragment>
            <EmailShareButton url={props.shareUrl} className="social-share-button" onClick={() => toggle()}>
                <EmailIcon size={props.size} style={{ fill: '#FF8891' }} round />
                Mail
            </EmailShareButton>
            <MDBModal isOpen={modalState} toggle={toggle}>
                <MDBModalHeader toggle={toggle} className="invite-modal-header">
                    <div className="invite-modal-title">{t("Invite someone")}</div>
                </MDBModalHeader>
                <MDBModalBody className="help-modal-body">
                    <MDBRow>
                        <MDBCol>
                            <form>
                                <div className="grey-text">
                                    <MDBInput label={t("Name of the recipient")} icon="user" group type="text" validate error="wrong"
                                        success="right" onChange={onNameChange} />
                                    <MDBInput label={t("Email address")} icon="envelope" group type="email" validate error="wrong"
                                        success="right" onChange={onEmailChange} />
                                </div>
                                {/* <span className={span}>Invalid email address</span> */}
                                <div className="text-center">
                                    <MDBBtn color="primary" className="wwt-btn" onClick={onSubmit} disabled={!(nameState && emailState)}>
                                        {t("Send invite")}
                                        <MDBIcon far icon="paper-plane" className="ml-1" />
                                    </MDBBtn>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </React.Fragment>
    )
}

export default InviteEmailModal;