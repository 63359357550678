import React from 'react'
import { useTranslation } from 'react-i18next';
import './Slide.css'
import slide2Img from '../../../assets/illustration.svg';
import slide1Img from '../../../assets/Femme cercle rose.svg';
import slide3Img from '../../../assets/Homme cercle vert.svg';
import LoginModal from '../../Auth/LoginModal';
import { getLanguage, isUserLoggedIn } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import profile from '../../../assets/profile.svg';
import Slider from "react-slick";

const Slide = (props) => {

  const { t } = useTranslation();
  var lang = getLanguage();

  var settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="slide-frame container">
      {/* {lang !== 'fr' ? */}
        <>
          <div className="slide">
            <Slider {...settings}>
              <div key="0" className="explain-slide">
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6'>
                    <div className='title'>
                      <div>{t("Discover your relational interactions at work")}</div>
                    </div>
                    <div className='slide-description'>{t("By understanding each other better, we can save time and enhance our teamwork. ")}</div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <img src={slide2Img} className=" d-block d-md-none slide-img-sm" alt='lady svg' />
                    <img src={slide2Img} className="lady-picture d-none d-md-block" alt='lady svg' />
                  </div>
                </div>
              </div>
              <div key="1" className="explain-slide">
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6'>
                    <div className='title'>
                      <div>{t("We reveal the unique emotional signature")}</div>
                      <div>{t("of each individual or team through science")}</div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <img src={slide1Img} className=" d-block d-md-none slide-img-sm" alt='lady svg' />
                    <img src={slide1Img} className="lady-picture d-none d-md-block" alt='lady svg' />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className='call-to-action'>
            {t("Are you interested in the Work Well Together experience? Contact us to receive a link to complete our multiple-choice questionnaire (18 questions) and discover a brief summary of your relational profile.")}
          </div>
        </>
        {/* :
        <div className='pt-4 pt-md-0'>
          <div className="video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/4B2HSlH2jRs?rel=0"
              title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
          </div>
        </div>
      } */}
      {/* {isUserLoggedIn()} */}
      <div className='intro-btns row'>
        {isUserLoggedIn() ?
          <Link to='/results'>
            <div className='wwt-btn wwt-btn-pink font-montserrat mb-4 mb-md-0 mr-0 mr-md-4'>
              <img src={profile} alt="profile icon" className="profile-icon" />
              {t("My account")}
            </div>
          </Link> :
          <LoginModal
            setEmail={props.setEmail}
            setPassed={props.setPassed}
            pageNum={1}
            openDefault
          />
        }
        {isUserLoggedIn() ?
          null :
          <div className='d-block d-md-none'>
            {/* <CompanyProfileModal /> */}
            <Link to='/pro'>
              <div className="wwt-btn bg-warning font-montserrat mb-4 mb-md-0 mr-0 mr-md-4">
                {t("Pro Access")}
              </div>
            </Link>
          </div>
        }
        <a href="#second-page">
          <div className="wwt-btn wwt-btn-outline font-montserrat">
            {t("How it works")}
          </div>
        </a>
      </div>
    </div>
  )
};

export default Slide