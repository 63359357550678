import server from "@config";
import axios from "axios";
import MultiLang from "components/MultiLang/MultiLang";
import React, { useEffect, useState } from 'react';
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import logo from '../../assets/header.svg';
import QuestionBlock from './QuestionBlock/QuestionBlock';
import SelectWordBlock from "./SelectWordBlock/SelectWordBlock";
import SortWordBlock from "./SortWordBlock/SortWordBlock";
import './BarometerQuestionnaire.css';
import BarometerFirstPage from "./BarometerFirstPage/BarometerFirstPage";
import BarometerLastPage from "./BarometerLastPage/BarometerLastPage";

function BarometerQuestionnaire() {

    const views = {
        FIRST_PAGE: 'FIRST_PAGE',
        DEMOGRAPHIC: 'DEMOGRAPHIC',
        QUESTIONNAIRE: 'QUESTIONNAIRE',
        SELECT_WORDS: 'SELECT_WORDS',
        SORT_WORDS: 'SORT_WORDS',
        LAST_PAGE: 'LAST_PAGE'
    }

    const { t } = useTranslation();

    const [mission, setMission] = useState(null);
    const [demographicQuestions, setDemographicQuestions] = useState([]);
    const [selectedDemographicQuestion, setSelectedDemoGraphicQuestion] = useState(null);
    const [selectedDemographicQuestionNumber, setSelectedDemographicQuestionNumber] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(0);
    const [view, setView] = useState(null);
    const [words, setWords] = useState([]);
    const [selectedWords, setSelectedWords] = useState([]);
    const [company, setCompany] = useState(null);
    const [localData, setLocalData] = useState({});

    useEffect(() => {
        const mission = new URLSearchParams(window.location.search).get('mission');
        const data = localStorage.getItem(`barometer-${mission}`) ? JSON.parse(localStorage.getItem(`barometer-${mission}`)) : null;
        if (data) {
            try {
                setLocalData(data);
                if (data.demographicQuestionnaire && data.demographicQuestionnaire.length) {
                    if (data.selectedDemographicQuestions && data.selectedDemographicQuestions.length) {
                        if (data.demographicQuestionnaire.length <= data.selectedDemographicQuestions.length) {
                            setView(views.QUESTIONNAIRE);
                        } else {
                            setView(views.DEMOGRAPHIC);
                            setSelectedDemoGraphicQuestion(data.demographicQuestionnaire[data.selectedDemographicQuestions.length]);
                            setSelectedDemographicQuestionNumber(data.selectedDemographicQuestions.length + 1);
                        }
                    }
                }
                if (data.questionnaire && data.questionnaire.length) {
                    if (data.selectedQuestions && data.selectedQuestions.length) {
                        if (data.questionnaire.length <= data.selectedQuestions.length) {
                            setView(views.SELECT_WORDS);
                        } else {
                            setSelectedQuestion(data.questionnaire[data.selectedQuestions.length]);
                            setSelectedQuestionNumber(data.selectedQuestions.length + 1);
                        }
                    }
                }
                if (data.words && data.selectedWords) {
                    setSelectedWords(data.selectedWords);
                    setView(views.SORT_WORDS);
                }
            } catch (e) {

            }
        }
        setMission(mission);
    }, [])

    useEffect(() => {
        if (mission) {
            checkMissionActive().then((data) => {
                if (!data) {
                    localStorage.removeItem(`barometer-${mission}`);
                    window.location.replace('/');
                }
            });
            getCompanyDetails();
            if (!view) {
                setView(views.FIRST_PAGE);
            }
            if (localData.demographicQuestionnaire) {
                setDemographicQuestions(localData.demographicQuestionnaire);
            } else {
                getDemographicQuestionnaire();
            }
            if (localData.questionnaire) {
                setQuestions(localData.questionnaire);
            } else {
                getQuestionnaire();
            }
            if (localData.words) {
                setWords(localData.words);
            } else {
                getWords();
            }
        }
    }, [mission])

    useEffect(() => {
        if (questions.length && !localData.questionnaire) {
            setLocalData({ ...localData, questionnaire: questions });
        }
        if (questions.length && !selectedQuestion) {
            setSelectedQuestion(questions[0]);
            setSelectedQuestionNumber(1);
        }
    }, [questions])

    useEffect(() => {
        if (demographicQuestions.length && !localData.demographicQuestionnaire) {
            setLocalData({ ...localData, demographicQuestionnaire: demographicQuestions });
        }
        if (demographicQuestions.length && !selectedDemographicQuestion) {
            setSelectedDemoGraphicQuestion(demographicQuestions[0]);
            setSelectedDemographicQuestionNumber(1);
        }
    }, [demographicQuestions])

    useEffect(() => {
        if (view && view !== views.LAST_PAGE) {
            setLocalData({ ...localData, view: view });
        }
    }, [view])

    useEffect(() => {
        if (mission) {
            localStorage.setItem(`barometer-${mission}`, JSON.stringify(localData));
        }
    }, [localData])

    const checkMissionActive = async () => {
        let url = "/company/is_mission_active/?mission_referral=" + mission;
        const isMissionActive = await axios
            .get(server + url, {
                header: {
                    "Content-type": "application/json",
                },
            })
            .then((res) => {
                return res.data.is_active;
            })
            .catch((err) => {
                console.log(err);
            });
        console.log('is', isMissionActive)
        return isMissionActive;
    }

    const getCompanyDetails = () => {
        let url = "/company/get_company_from_mission/?mission_referral=" + mission;
        axios
            .get(server + url, {
                header: {
                    "Content-type": "application/json",
                },
            })
            .then((res) => {
                if (res && res.data) {
                    setCompany(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getDemographicQuestionnaire = () => {
        let url = "/response/demographic_question/?mission=" + mission;
        axios
            .get(server + url, {
                header: {
                    "Content-type": "application/json",
                },
            })
            .then((res) => {
                if (res && res.data) {
                    res.data.forEach((question) => {
                        if (question.options) {
                            question.options.forEach((option) => {
                                option.answer_id = option.option_id;
                            })
                        }
                    });
                    setDemographicQuestions([...res.data]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getQuestionnaire = () => {
        let url = "/response/question/?mission=" + mission;
        axios
            .get(server + url, {
                header: {
                    "Content-type": "application/json",
                },
            })
            .then((res) => {
                getOptions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getOptions = (questions) => {
        let url = "/response/answer/?mission=" + mission;
        axios
            .get(server + url, {
                header: {
                    "Content-type": "application/json",
                },
            })
            .then((res) => {
                combineQuestionsAndOptions(questions, res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const combineQuestionsAndOptions = (questions, options) => {
        questions.forEach((question) => {
            question.options = options.filter((op) => op.answer_id.startsWith(`${question.question_id}a`))
        });
        setQuestions(questions);
    }

    const getWords = () => {
        let url = "/words/";
        axios
            .get(server + url, {
                header: {
                    "Content-type": "application/json",
                },
            })
            .then((res) => {
                if (res && res.data) {
                    setWords(res.data);
                    setLocalData({ ...localData, words: res.data });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const onNextDemographicQuestion = (demographicQuestion) => {
        window.scrollTo(0, 0);
        let selectedDemographicQuestions = [];
        if (localData && localData?.selectedDemographicQuestions) {
            selectedDemographicQuestions = [...localData.selectedDemographicQuestions];
        }
        selectedDemographicQuestions.push(demographicQuestion);
        setLocalData({ ...localData, selectedDemographicQuestions: selectedDemographicQuestions });
        const index = demographicQuestions.findIndex((q) => q.question_id === demographicQuestion.question_id);
        if (index >= 0 && index < (demographicQuestions.length - 1)) {
            setSelectedDemoGraphicQuestion(demographicQuestions[index + 1]);
            setSelectedDemographicQuestionNumber(selectedDemographicQuestionNumber + 1);
        } else {
            setView(views.QUESTIONNAIRE);
        }
    }

    const onNext = (question) => {
        window.scrollTo(0, 0);
        let selectedQuestions = [];
        if (localData && localData?.selectedQuestions) {
            selectedQuestions = [...localData.selectedQuestions];
        }
        selectedQuestions.push(question);
        setLocalData({ ...localData, selectedQuestions: selectedQuestions });
        const index = questions.findIndex((q) => q.question_id === question.question_id);
        if (index >= 0 && index < (questions.length - 1)) {
            setSelectedQuestion(questions[index + 1]);
            setSelectedQuestionNumber(selectedQuestionNumber + 1);
        } else {
            setView(views.SELECT_WORDS);
        }
    }

    const onSelectWords = (words) => {
        setSelectedWords(words);
        setLocalData({ ...localData, selectedWords: words });
        setView(views.SORT_WORDS);
    }

    const onSortWords = (sortedWords) => {
        console.log('local data', localData);
        console.log('sorted words', sortedWords);
        const body = {};

        // set demographic answers
        body.demographic_answers = {};
        if (localData.selectedDemographicQuestions) {
            localData.selectedDemographicQuestions.forEach((q) => {
                body.demographic_answers[q.pk] = q.answers ? +q.answers.map((a) => q.options.find((op) => op.option_id === a).pk).join(',') : '';
            })
        }
        if (localData.selectedQuestions) {
            let question_answered = '';
            let first_answers = '';
            let second_answers = '';
            localData.selectedQuestions.forEach((q) => {
                question_answered += `${q.question_id.replace('q', '')},`;
                if (q.answers) {
                    q.answers.forEach((a, index) => {
                        if (!index) {
                            first_answers += a.replace(`${q.question_id}a`, '') + ',';
                        } else if (index === 1) {
                            second_answers += a.replace(`${q.question_id}a`, '') + ',';
                        }
                    })
                }
            })
            body.question_answered = question_answered;
            body.first_answers = first_answers;
            body.second_answers = second_answers;
        }
        if (localData.selectedWords) {
            body.selected_word_result = localData.selectedWords.map((word) => word.word_id).join(',');
        }
        if (sortedWords) {
            body.sorted_word_result = sortedWords.map((word) => word.word_id).join(',');
        }
        console.log('body', body);
        saveIndividualResult(body);
    }

    const saveIndividualResult = (data) => {
        let config = {
            method: 'post',
            url: server + '/result/barometer_response/?mission=' + mission,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((res) => {
                toast.success(t('Your response is saved successfully'));
                setView(views.LAST_PAGE);
                localStorage.removeItem(`barometer-${mission}`);
            })
    }

    const currentView = () => {
        switch (view) {
            case views.DEMOGRAPHIC:
                return (
                    <>
                        {
                            selectedDemographicQuestion ?
                                <QuestionBlock
                                    isDemographicQuestion={true}
                                    question={selectedDemographicQuestion}
                                    answerCount={1}
                                    questionNo={selectedDemographicQuestionNumber}
                                    onNext={(question) => onNextDemographicQuestion(question)}
                                    progress={(100 * (selectedDemographicQuestionNumber - 1)) / demographicQuestions?.length} />
                                : null
                        }
                    </>
                )

            case views.QUESTIONNAIRE:
                return (
                    <>
                        {
                            selectedQuestion ?
                                <QuestionBlock
                                    question={selectedQuestion}
                                    answerCount={2}
                                    questionNo={selectedQuestionNumber}
                                    onNext={(question) => onNext(question)}
                                    progress={(100 * (selectedQuestionNumber - 1)) / questions?.length} />
                                : null
                        }
                    </>
                )

            case views.SELECT_WORDS:
                return <SelectWordBlock words={words} onNext={(selectedWords) => onSelectWords(selectedWords)} />

            case views.SORT_WORDS:
                return <SortWordBlock words={selectedWords} onNext={(sortedWords) => onSortWords(sortedWords)} />

            default:
                break;
        }
    }

    const onStartQuestionnaire = () => {
        setView(views.DEMOGRAPHIC);
    }

    if (view === views.FIRST_PAGE) {
        return (
            <>
                <BarometerFirstPage company={company} startQuestionnaire={() => onStartQuestionnaire()} />
            </>
        )
    }

    if (view === views.LAST_PAGE) {
        return (
            <>
                <BarometerLastPage company={company} />
            </>
        )
    }

    return (
        <div>
            <div className='container d-flex justify-content-between pt-4'>
                <div class='align-items-center d-flex flex-1 justify-content-between'>
                    <img src={logo} alt="work well together" className="company-logo" />
                    {company?.company_logo && <img src={company?.company_logo} alt="work well together" className="company-logo ml-4" />}
                </div>
                <MultiLang />
            </div>
            <div>
                {currentView()}
            </div>
        </div>
    )
}

export default BarometerQuestionnaire;