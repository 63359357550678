import React from 'react'
import { useTranslation } from 'react-i18next';
import '../Home/HomePage.css'
import logo from '../../assets/header.svg'
import profile from '../../assets/profile.svg'
import MultiLang from '../MultiLang/MultiLang';
import { isUserLoggedIn } from '../../utils/utils';
import { Link } from 'react-router-dom';

function Header() {

    const { t } = useTranslation();

    return (
        <div className="header">
            <div className='container header-block'>
                <img src={logo} alt="work well together" className="logo" />
                <div className='d-flex'>
                    {isUserLoggedIn() ?
                        <Link className='d-none d-sm-block' to='/results'>
                            <div className="wwt-btn wwt-btn-pink myprofile font-montserrat">
                                <img src={profile} alt="profile icon" className="profile-icon" />
                                {t("My account")}
                            </div>
                        </Link>
                        :
                        <div className='d-none d-md-block'>
                            {/* <CompanyProfileModal /> */}
                            <Link className='d-none d-sm-block' to='/pro'>
                                <div className="wwt-btn bg-warning myprofile font-montserrat">
                                    {t("Pro Access")}
                                </div>
                            </Link>
                        </div>
                    }

                    <MultiLang />
                </div>
            </div>
        </div>
    )
}

export default Header