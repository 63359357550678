import React, { useEffect, useRef, useState } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import 'react-flags-select/css/react-flags-select.css'
import './MultiLang.css'
import { useTranslation } from 'react-i18next';
import { getLanguage } from 'utils/utils'

const MultiLang = () => {

  const { i18n } = useTranslation();

  const ref = useRef();

  useEffect(() => {

    // update selected country manually when a language is changed
    // externally without using this selection
    const selectedCountry = getSelectedCountry();
    if (ref.current && ref.current.state && ref.current.state) {
      ref.current.state.selected = selectedCountry;
    }
  }, [i18n.language])

  const selLang = country => {
    var lang;
    switch (country) {
      case 'US':
        lang = 'en';
        break;
      case 'FR':
        lang = 'fr';
        break;
      default:
        lang = 'en';
    }
    i18n.changeLanguage(lang);
    document.documentElement.lang = i18n.language;
  }

  const getSelectedCountry = () => {
    var lang = getLanguage()
    //console.log('i18n country language is ' + lang);
    switch (lang) {
      case 'en':
        return 'US';
      case 'fr':
        return 'FR';
      default:
        return 'US';
    }
  }

  return (
    <ReactFlagsSelect
      ref={ref}
      countries={["US", "FR"]}
      customLabels={{ "US": "EN", "FR": "FR" }}
      defaultCountry={getSelectedCountry()}
      showSelectedLabel={true}
      selectedSize={20}
      style={{
        outline: 'none !important', width: '100px',
        display: 'flex'
      }}
      className="menu-flags"
      onSelect={selLang}
    />
  );
}

export default MultiLang
