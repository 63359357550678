import Table from 'components/common/Table/Table';
import Loader from 'components/Loader/Loader';
import { MDBIcon, MDBModal, MDBModalBody, MDBTooltip } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProp, isEvaluationCompleted, truncate } from 'utils/utils';
import ProgressBar from '../../../../common/ProgressBar/ProgressBar';

function MobilityCandidateModel({ loading, matchingMap, contacts, selectedCandidate, setSelectedCandidate }) {
    const [dataForGrid, setDataForGrid] = useState([]);
    const [searchInput, setSearchInput] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        updateDataForGrid();
    }, [contacts, matchingMap])

    useEffect(() => {
        console.log('data for grid', dataForGrid)
    }, [dataForGrid])

    const updateDataForGrid = () => {
        const data = contacts.filter((c) => !c.user.member);
        if (matchingMap) {
            data.forEach((contact) => {
                const user_1 = selectedCandidate.user.user_id;
                const user_2 = contact.user.user_id;
                const matching = matchingMap[`${user_1}_${user_2}`] || matchingMap[`${user_2}_${user_1}`];
                contact.user.matching = matching || {};
            });
        }
        setDataForGrid(data);
    }

    const getMatchingProgressBar = (matching, key, styles, classes) => {
        if (matching) {
            if (matching[key] >= 0) {
                return <ProgressBar value={matching[key] * 100} text={matching[key] * 100} styles={styles} classes={classes} />
            }
        }
        return null;
    }

    const showMessage = (contact1, contact2) => {
        let message = '';
        const c1EvaluationCompleted = isEvaluationCompleted(getProp(contact1, ['user', 'result']));
        const c2EvaluationCompleted = isEvaluationCompleted(getProp(contact2, ['user', 'result']));
        if (!(c1EvaluationCompleted || c2EvaluationCompleted)) {
            message = `${contact1?.user?.first_name} ${contact1?.user?.last_name} ${t('and')}  ${contact2?.user?.first_name} ${contact2?.user?.last_name} ${t("haven't completed the evaluation yet")}.`;
        } else if (!c1EvaluationCompleted) {
            message = `${contact1?.user?.first_name} ${contact1?.user?.last_name} ${t("hasn't completed the evaluation yet")}.`;
        } else if (!c2EvaluationCompleted) {
            message = `${contact2?.user?.first_name} ${contact2?.user?.last_name} ${t("hasn't completed the evaluation yet")}.`;
        }
        return message;
    }

    const filterData = (data) => {
        const input = searchInput ? searchInput.toLowerCase() : '';
        if (input) {
            const matchString = [data.user.last_name, data.user.first_name].filter((d) => d).join('').toLowerCase();
            if (matchString.includes(input)) {
                return true;
            }
            return false;
        }
        return true;
    }


    return (
        <MDBModal isOpen={!!selectedCandidate} toggle={() => { setSelectedCandidate(null); }} size='xl' className='recruitment-details-modal'>
            <Loader loading={loading}>
                <MDBModalBody>
                    {
                        selectedCandidate &&
                        <div className='selected-contact-block'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='mr-2'>
                                    <MDBTooltip domElement tag="span" placement="top">
                                        <span>{truncate(selectedCandidate.user.first_name + ' ' + selectedCandidate.user.last_name, 30)}</span>
                                        <span>{selectedCandidate.user.first_name} {selectedCandidate.user.last_name}</span>
                                    </MDBTooltip>
                                </span>
                                <div className='d-flex align-items-center'>
                                    <input className='search-input' placeholder={`${t('Search')}...`} onChange={(e) => setSearchInput(e.target.value)} />
                                    <button className='action-btn' onClick={() => { setSelectedCandidate(null); }}>
                                        <MDBIcon icon="times" className='icon' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='overflow-auto'>
                        <Table className='missions'
                            columns={[
                                [
                                    { key: 'user.last_name', title: 'Last Name', sortable: true },
                                    { key: 'user.first_name', title: 'First Name', sortable: true },
                                    { key: 'user.type', title: 'Role', sortable: true },
                                    { key: 'user.matching.rui_smooth', title: 'RI', tooltip: 'Relational Index', sortable: true },
                                    { key: 'user.matching.immediate_harmony_smooth', title: 'FE', tooltip: 'Feeling', sortable: true },
                                    { key: 'user.matching.deep_compatibility_smooth', title: 'DC', tooltip: 'Deep Compatibility', sortable: true },
                                    { key: 'user.matching.complementarity_smooth', title: 'CP', tooltip: 'Complementarity', sortable: true },
                                    { key: 'user.matching.similarity_smooth', title: 'CO', tooltip: 'Connivance', sortable: true },
                                    { key: 'user.matching.understanding_smooth', title: 'UN', tooltip: 'Understanding', sortable: true }
                                ]
                            ]}
                            data={dataForGrid.filter((data) => filterData(data))}
                            rowComponent={
                                (data, index) => (
                                    <tr key={data.user.id}>
                                        <td>
                                            <MDBTooltip domElement tag="span" placement="top">
                                                <span>{truncate(data.user.last_name, 15)}</span>
                                                <span>{data.user.last_name}</span>
                                            </MDBTooltip>
                                        </td>
                                        <td>
                                            <MDBTooltip domElement tag="span" placement="top">
                                                <span>{truncate(data.user.first_name, 15)}</span>
                                                <span>{data.user.first_name}</span>
                                            </MDBTooltip>
                                        </td>
                                        <td>{t(data.user.type)}</td>
                                        {
                                            (isEvaluationCompleted(getProp(selectedCandidate, ['user', 'result'])) && isEvaluationCompleted(getProp(data, ['user', 'result']))) ?
                                                <>
                                                    <td>{getMatchingProgressBar(data.user.matching, 'rui_smooth', { pathColor: '#00CFD7' }, 'font-weight-bold')}</td>
                                                    <td>{getMatchingProgressBar(data.user.matching, 'immediate_harmony_smooth')}</td>
                                                    <td>{getMatchingProgressBar(data.user.matching, 'deep_compatibility_smooth')}</td>
                                                    <td>{getMatchingProgressBar(data.user.matching, 'complementarity_smooth')}</td>
                                                    <td>{getMatchingProgressBar(data.user.matching, 'similarity_smooth')}</td>
                                                    <td>{getMatchingProgressBar(data.user.matching, 'understanding_smooth')}</td>
                                                </>
                                                : <td colSpan={6} className='text-black-50 text-center'>
                                                    {showMessage(selectedCandidate, data)}
                                                </td>
                                        }
                                    </tr>
                                )
                            }
                            noDataComponent={searchInput ? (<tr><td colSpan='10' className='text-center'>No data available for applied filter.</td></tr>) : null}
                        />
                    </div>
                </MDBModalBody>
            </Loader>
        </MDBModal>

    );
}

export default MobilityCandidateModel;