import React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import UserAvatar from 'react-user-avatar'
// import { Link } from 'react-router-dom'

import './ContactPage.css'

function ContactPerson(props) {
    return (
        <div className="contact-person" >
            {/* <img src={props.img} alt="contact-person-img" className="contact-person-img" /> */}
            <UserAvatar size="40" className='contact-person-img' name={`${props.name}`} src={props.img} />
            <div className="contact-person-context">
                <div className="contact-person-name">{props.name}</div>
                <div className="contact-person-state">{props.state}</div>
                {/*<div className="matching-button-group">
                    <Link to="/matching"><div className="matching-button">Match</div></Link>
                    <div className="matching-refuse">Refuse</div>
                </div>*/}
            </div>
            {props.strength ?
                <div className='d-flex flex-column justify-content-center align-items-end'>
                    <div style={{ width: '50px' }}>
                        <CircularProgressbar
                            value={Math.ceil(props.strength.value * 100)}
                            text={Math.ceil(props.strength.value * 100) + '%'}
                            strokeWidth={8}
                            styles={buildStyles({
                                textColor: '#D755A2',
                                textSize: '30px',
                                textFamily: 'Montserrat',
                                pathColor: '#D755A2'
                            })}
                        />
                    </div>
                    {/* <div className='font-weight-normal mt-2 text-black-50'>{props.strength.strength}</div> */}
                </div>
                :
                <div className='d-flex flex-column justify-content-center align-items-end'>
                    <div style={{ width: '50px' }}>
                        <CircularProgressbar
                            value={Math.ceil(0 * 100)}
                            text={'---'}
                            strokeWidth={8}
                            styles={buildStyles({
                                textColor: '#D755A2',
                                textSize: '30px',
                                textFamily: 'Montserrat',
                                pathColor: '#D755A2'
                            })}
                        />
                    </div>
                </div>}

        </div>
    )
}

export default ContactPerson