import server from '@config'
import axios from 'axios'
import { useAuth } from 'context/auth'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import UserAvatar from 'react-user-avatar'
import { isB2B, logOut } from 'utils/utils'
import MultiLang from '../../MultiLang/MultiLang'

function Header() {

  const [profile, setProfile] = useState()
  const { t, i18n } = useTranslation();
  const { setAuthTokens } = useAuth()

  useEffect(() => {
    let data = JSON.stringify({ "email": localStorage.getItem("email") })

    let config = {
      method: 'post',
      url: server + '/result/profile/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(res => {
        setProfile(res.data['result'])
        localStorage.setItem('profile', JSON.stringify(res.data['result']));
      })
      .catch(err => {
        console.log(err, 'get profile picture error')
        toast.error('Failed to retrieve the user profile.')
      })
  }, [])

  const logOutUser = () => {
    logOut();
    setAuthTokens(null);
  }

  if (profile) {
    return (
      <div className="results-header">
        <div className='wwt-container d-flex'>
          <div className='d-flex justify-content-between w-100'>
            <div className='d-flex align-items-center'>
              <UserAvatar size="80" className='upload-icon mr-4' name={`${profile.first_name} ${profile.last_name}`} src={profile.picture_url} />
              <div className='results-header-title'>
                {profile.first_name} {profile.last_name}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <Link className='d-none d-sm-flex align-items-center ' to='/' onClick={() => logOutUser()}>
                <div className="wwt-btn wwt-btn-pink">
                  <span className="fa fa-sign-out-alt" />
                  &nbsp;{t("Logout")}
                </div>
              </Link>
              {
                isB2B() &&
                <Link className='d-none d-sm-flex align-items-center ml-3' to='/recruitments'>
                  <div className="wwt-btn bg-warning">
                    {t("Pro Access")}
                  </div>
                </Link>
              }
              <div style={{
                width: '100px',
                display: 'flex'
              }} className='multi-lang-menu'>
                <MultiLang />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (<div>Loading...</div>)
  }
}
export default Header