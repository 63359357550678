import React from 'react'
import { useTranslation } from 'react-i18next'
import Accordion from './Accordion/Accordion'

import '../Results.css'

function SecondPage(props) {

  const { t } = useTranslation();

  return (
    <div className="results-second">
      <div style={{display: 'flex', textAlign: 'left'}}>
        <div className="results-second-bar" />
        <div className="results-second-title">{t("An extract of your relational profile")}</div>
      </div>
      <div className="results-accordion-frame">
        <Accordion
          title="Expression of your well-being"
          content={props.render_data[0]}
        />
        <Accordion
          title="Your relational communication"
          content={props.render_data[1]}
        />
        <Accordion
          title="Your approach to situations"
          content={props.render_data[2]}
        />
        <Accordion
          title="Your vision of the world"
          content={props.render_data[3]}
        />
      </div>
    </div>
  )
}

export default SecondPage