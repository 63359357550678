import server from '@config';
import { duplicateMission } from 'api/mission';
import axios from 'axios';
import ConfirmationDialog from 'components/common/Dialogs/ConfirmationDialog';
import CopyIcon from 'components/common/Icons/CopyIcon';
import MoreDotsIcon from 'components/common/Icons/MoreDotsIcon';
import TrashIcon from 'components/common/Icons/TrashIcon';
import Loader from 'components/Loader/Loader';
import { MDBBtn, MDBContainer, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTable, MDBTableBody, MDBTableHead, MDBTabPane, MDBTooltip } from 'mdbreact';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { MenuItem } from 'rc-menu';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { stopPropagation, truncate } from 'utils/utils';
import ProgressBar from '../../common/ProgressBar/ProgressBar';
import Header from '../Header/Header';
import './TeamBuilding.css';
import TeamBuildingDetails from './TeamBuildingDetails/TeamBuildingDetails';

function TeamBuildingRow({ mission, onMissionClick, onMissionActionClick }) {

    const [loading, setLoading] = useState(false);
    const [matchings, setMatchings] = useState([]);
    const [CPI, setCPI] = useState(0);
    const [impactOnCPI, setImpactOnCPI] = useState(0);
    const [lowestMatchingScore, setLowestMatchingScore] = useState(0);

    const { t } = useTranslation();

    const getTeamMatchings = (mission) => {
        setLoading(true);
        console.log('get team matching')
        const contacts = [];
        if (mission.mission_manager) {
            contacts.push({ company_id: mission.mission_manager.company_id, email: mission.mission_manager.email });
        }
        if (mission.mission_benchmarks && mission.mission_benchmarks.length) {
            mission.mission_benchmarks.forEach((benchmark) => {
                contacts.push({ company_id: benchmark.contact.company_id, email: benchmark.contact.email });
            })
        }
        let config = {
            method: 'post',
            url: server + '/result/team_matches/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { contacts }
        }
        axios(config)
            .then(res => {
                console.log('missions', res.data);
                setMatchings(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setMatchings([]);
                setLoading(false);
            })
    }

    useEffect(() => {
        getTeamMatchings(mission);
    }, [])

    useEffect(() => {
        setCPI(getCPI());
        setLowestMatchingScore(getLowestMatchingScore());
    }, [matchings])

    useEffect(() => {
        setImpactOnCPI(getImpactOnCPI());
    }, [CPI]);

    const getImpactOnCPI = () => {
        const userId = mission.mission_manager.user_id;
        let impactOnCpi = 0;
        if (userId && CPI) {
            let cpi_without_user = 0;
            let cpi_with_user = 0;
            let count_without_user = 0;
            let count_with_user = 0;
            if (matchings && matchings.length) {
                matchings.forEach((matching) => {
                    if (matching.rui_smooth !== 0) {
                        count_with_user++;
                        cpi_with_user += matching.rui_smooth;
                        if (matching.user_1 !== userId && matching.user_2 !== userId) {
                            cpi_without_user += matching.rui_smooth;
                            count_without_user++;
                        }
                    }
                });
                cpi_without_user = cpi_without_user * 100 / count_without_user;
                cpi_with_user = cpi_with_user * 100 / count_with_user;
                impactOnCpi = (cpi_with_user - cpi_without_user) * 100 / cpi_without_user;
            }
        }
        return +impactOnCpi.toFixed(0);
    }

    const getCPI = () => {
        let rui_smooth = 0;
        let cpi = 0;
        if (matchings && matchings.length) {
            matchings.forEach((matching) => {
                rui_smooth += matching.rui_smooth;
            });
            cpi = rui_smooth * 100 / matchings.length;
        }
        return +cpi.toFixed(2);
    }

    const getLowestMatchingScore = () => {
        const value = matchings.length ? matchings.reduce((prev, curr) => prev.rui_smooth < curr.rui_smooth ? prev : curr) : {};
        return (value && value.rui_smooth) ? value.rui_smooth * 100 : 0;
    }

    const missionClick = () => {
        if (onMissionClick && !loading) {
            onMissionClick(matchings);
        }
    }

    return (
        <tr key={mission.pk} onClick={missionClick} className={`${loading ? ' row-disabled ' : ''}`}>
            {/* <td>
                <div className="custom-control custom-checkbox custom-control-inline" onClick={stopPropagation}>
                    <input type="checkbox" className="custom-control-input" id="defaultInline2" />
                    <label className="custom-control-label" for="defaultInline2"></label>
                </div>
            </td> */}
            <td>
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <span>{truncate(mission.mission_name, 30)}</span>
                    <span>{mission.mission_name}</span>
                </MDBTooltip>
            </td>
            <td>{mission.reference}</td>
            <td >
                <MDBTooltip domElement tag="span" placement="top">
                    <span>{truncate(mission.mission_manager.last_name, 15)}</span>
                    <span>{mission.mission_manager.last_name}</span>
                </MDBTooltip>
            </td>
            <td >
                <MDBTooltip domElement tag="span" placement="top">
                    <span>{truncate(mission.mission_manager.first_name, 15)}</span>
                    <span>{mission.mission_manager.first_name}</span>
                </MDBTooltip>
            </td>
            {
                (mission?.mission_benchmarks?.length === 1) ?
                    <td>-</td> :
                    <td><ProgressBar value={CPI} text={CPI} /></td>
            }
            <td>{mission?.mission_benchmarks?.length + 1}</td>
            {
                (mission?.mission_benchmarks?.length === 1) ?
                    <td>-</td> :
                    <td><ProgressBar value={lowestMatchingScore} text={lowestMatchingScore} /></td>
            }
            <td onClick={stopPropagation}>

                <Dropdown
                    key={`dp-${mission.pk}`}
                    trigger={['click']}
                    overlay={
                        <Menu className='p-2' selectable={false}>
                            <MenuItem key='duplicate'>
                                <button className='action-btn'>
                                    <CopyIcon className='icon' />
                                    <span className='text'>{t("Duplicate")}</span>
                                </button>
                            </MenuItem>
                            <MenuItem key='remove'>
                                <button className='action-btn'>
                                    <TrashIcon className='icon' />
                                    <span className='text'>{t("Delete")}</span>
                                </button>
                            </MenuItem>
                            <MenuItem key='copyLink'>
                                <CopyToClipboard text={`${window.location.origin}/?mission=${mission.mission_referral}`}>
                                    <button className='action-btn'>
                                        <CopyIcon className='icon' />
                                        <span className='text'>{t("Copy the invitation link")}</span>
                                    </button>
                                </CopyToClipboard>
                            </MenuItem>
                        </Menu>
                    }
                    animation="slide-up"
                    placement="bottomRight"
                    onOverlayClick={(e) => {
                        console.log('e', e)
                        onMissionActionClick(mission, e.key)
                    }}
                >
                    <button className='action-btn' onClick={stopPropagation}>
                        <MoreDotsIcon className='icon' />
                    </button>
                </Dropdown>
            </td>
        </tr>
    )
}

function TeamBuilding(props) {

    const { t } = useTranslation();

    const [activeItem, setActiveItem] = useState("1");
    const [missions, setMissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMission, setSelectedMission] = useState(null);
    const [confirmDialogConfig, setConfirmDialogConfig] = useState(null);

    const resetConfirmDialogConfig = () => {
        setTimeout(() => {
            setConfirmDialogConfig(null);
        }, 500);
    }

    const toggle = tab => e => {
        if (activeItem !== tab) {
            setActiveItem(tab);
        }
    };

    useEffect(() => {
        switch (activeItem) {
            case "1":
                getMyMissions();
                break;

            default:
                break;
        }
    }, [activeItem])

    const getMyMissions = () => {
        setLoading(true);
        let config = {
            method: 'get',
            url: server + '/company/mission/?mission_type=T',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('missions', res.data);
                setMissions(res.data);
            })
            .catch((err) => {
                setLoading(false);
                setMissions([]);
            })
    }

    const onMissionClick = (mission, matchings) => {
        const obj = {
            mission, matchings
        }
        setSelectedMission(obj);
    }

    const onMissionActionClick = (mission, type) => {
        switch (type) {
            case 'duplicate':
                setConfirmDialogConfig({
                    title: 'Duplicate Mission',
                    message: `${t('Do you want to duplicate mission')} <span class='text-primary'>${mission.mission_name}</span>?`,
                    onYesAction: () => onDuplicateMissionClick(mission),
                })
                break;

            case 'archive':
                archiveMission(mission);
                break;

            case 'remove':
                setConfirmDialogConfig({
                    title: t('Delete mission'),
                    message: `${t('Do you want to delete mission')} <span class='text-primary'>${mission.mission_name}</span>?`,
                    onYesAction: () => removeMission(mission),
                })
                break;

            case 'copyLink':
                toast.success(t("Link copied"));
                break;

            default:
                break;
        }
    }

    const onDuplicateMissionClick = (mission) => {
        setLoading(true);
        duplicateMission(mission).then(() => {
            setLoading(false);
            toast.success(t('Mission duplicated successfully'));
            getMyMissions();
        }).catch((error) => {
            setLoading(false);
            toast.error('Failed to duplicate mission.');
        })
    }

    const archiveMission = (mission) => {
        let config = {
            method: 'put',
            url: server + '/company/mission_update/?pk=' + mission.pk,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                toast.success('Mission archived successfully.');
                getMyMissions();
            })
            .catch((err) => {
                setLoading(false);
                console.log('missions', err);
                toast.success('Failed to archive mission.');
            })
    }

    const removeMission = (mission) => {
        setLoading(true);
        let config = {
            method: 'delete',
            url: server + '/company/mission_update/?pk=' + mission.pk,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                toast.success(t('Mission successfully removed'));
                getMyMissions();
            })
            .catch((err) => {
                setLoading(false);
                console.log('missions', err);
                toast.success('Failed to remove mission.');
            })
    }

    return (
        <React.Fragment>
            <div className='admin-container' style={{ background: "#eeeeee", minHeight: '100vh' }}>
                <Header />
                <div className='container py-4 pt-5'>
                    <div className="row">
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <h2 className='missions-header'>{t("Team Building")}</h2>
                            <Link to={'/team-building/add'}>
                                <MDBBtn className='wwt-btn flat wwt-btn-pink px-5' >
                                    + {t("New")}
                                </MDBBtn>
                            </Link>
                        </div>
                        <MDBContainer>
                            <Loader loading={loading}>
                                <MDBNav className="nav-tabs mt-5">
                                    <MDBNavItem>
                                        <MDBNavLink link to="#" active={activeItem === "1"} onClick={toggle("1")} role="tab" >
                                            {t("My Team Building Missions")}
                                        </MDBNavLink>
                                    </MDBNavItem>
                                    {/* <MDBNavItem>
                                        <MDBNavLink link to="#" active={activeItem === "2"} onClick={toggle("2")} role="tab" >
                                            My Colleagues' Missions
                                </MDBNavLink>
                                    </MDBNavItem> */}
                                </MDBNav>
                                <MDBTabContent activeItem={activeItem} className='bg-white'>
                                    <MDBTabPane tabId="1" role="tabpanel" className='overflow-auto'>
                                        <MDBTable striped className='missions team-building-missions row-clickable'>
                                            <MDBTableHead>
                                                <tr>
                                                    {/* <th rowSpan={2}></th> */}
                                                    <th rowSpan={2}><div className='d-flex align-items-center'><span>{t("Missions")}</span></div></th>
                                                    <th rowSpan={2}><div className='d-flex align-items-center'><span>{t("Ref.")}</span></div></th>
                                                    <th colSpan={2}><div className='d-flex align-items-center'><span>{t("Manager")}</span></div></th>
                                                    <th rowSpan={2} className='cpi'><div className='d-flex align-items-center'><span>{t("Collective Performance Index (CPI)")}</span></div></th>
                                                    <th rowSpan={2}><div className='d-flex align-items-center'><span>{t("Team headcount")}</span></div></th>
                                                    <th rowSpan={2}><div className='d-flex align-items-center'><span>{t("Lowest matching score")}</span></div></th>
                                                    <th rowSpan={2}><div className='d-flex align-items-center'></div></th>
                                                </tr>
                                                <tr>
                                                    <th className='font-weight-normal'><div className='d-flex align-items-center'><span>{t("Last Name")}</span></div></th>
                                                    <th className='font-weight-normal'><div className='d-flex align-items-center'><span>{t("First Name")}</span></div></th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {
                                                    missions.map((mission) => {
                                                        return (
                                                            <TeamBuildingRow key={mission.pk} mission={mission} onMissionClick={(matchings) => onMissionClick(mission, matchings)} onMissionActionClick={(mission, type) => onMissionActionClick(mission, type)} />
                                                        )
                                                    })
                                                }
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBTabPane>

                                </MDBTabContent>
                            </Loader>
                            {
                                selectedMission &&
                                <TeamBuildingDetails mission={selectedMission.mission} matchings={selectedMission.matchings} toggle={() => setSelectedMission(null)} reload={() => getMyMissions()} />
                            }
                        </MDBContainer>
                    </div>
                </div>
                {
                    confirmDialogConfig ?
                        <ConfirmationDialog isOpen
                            title={confirmDialogConfig.title}
                            message={confirmDialogConfig.message}
                            onYesAction={confirmDialogConfig.onYesAction}
                            onNoAction={confirmDialogConfig.onNoAction}
                            onToggle={(state) => !state ? resetConfirmDialogConfig() : null}>
                        </ConfirmationDialog>
                        : null
                }
            </div>
        </React.Fragment>
    );
}

export default TeamBuilding;