import server from '@config';
import axios from 'axios';
import ContactForm from 'components/Admin/ContactForm/ContactForm';
import Header from 'components/Admin/Header/Header';
import Loader from 'components/Loader/Loader';
import { ContactTypes } from 'constants/constants';
import { Formik } from 'formik';
import usePrevious from 'hooks/UsePrevious';
import { MDBBtn, MDBContainer, MDBIcon, MDBInput, MDBMedia, MDBTable, MDBTableBody, MDBTableHead, MDBTooltip } from 'mdbreact';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getLanguage, getProfile } from 'utils/utils';
import * as Yup from 'yup';
import './MobilityForm.css';

function MobilityForm(props) {
    const { t } = useTranslation();
    const MissionType = 'M';
    const [loading, setLoading] = useState(false);
    const [invitationLink, setInvitationLink] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [questionnaireTypes, setQuestionnaireTypes] = useState([]);
    const previousContacts = usePrevious(contacts);
    const [companyId, setCompanyId] = useState(null);

    var lang = getLanguage();

    const missionFormRef = new useRef(null);
    const benchmarkTypeMap = new Map();

    const initialValues = {
        reference: "",
        mission_name: "",
        mission_description: "",
        mission_delegates: [],
        mission_manager: null,
        mission_viewers: [],
        mission_member: [],
        mission_benchmarks: [],
        mission_end_date: "",
        default_questionnaire_type: null
    };

    const validationSchema = Yup.object().shape({
        reference: Yup.string().required("Required").max(20, 'Maximum 20 characters are allowed.'),
        mission_name: Yup.string().required("Required").max(80, 'Maximum 80 characters are allowed.'),
        mission_description: Yup.string().required("Required").max(1000, 'Maximum 1000 characters are allowed.'),
        mission_end_date: Yup.date().required("Required").min(new Date(), "Please select future date"),
        default_questionnaire_type: Yup.string().required("Required").nullable(),
        mission_manager: Yup.object().required("Required").nullable(),
    });

    useEffect(() => {
        const profile = getProfile();
        if (profile && profile.companies && profile.companies.length) {
            setCompanyId(profile.companies[0].company.pk);
        }
    }, [])

    useEffect(() => {
        if (companyId) {
            if (props.match.params.missionId) {
                getMissionById();
            } else {
                getCompanyContacts();
                getCompanyQuestionnaireTypes();
            }
        }
    }, [companyId])

    useEffect(() => {
        if (previousContacts && previousContacts.length !== contacts.length) {
            updateContacts();
        }
    }, [contacts])

    const getMissionById = () => {
        let config = {
            method: 'get',
            url: server + '/company/mission_update/?pk=' + props.match.params.missionId,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('mission by id', res);
                if (res.data) {
                    const data = res.data;
                    getCompanyContacts();
                    getCompanyQuestionnaireTypes();
                    if (missionFormRef && missionFormRef.current) {
                        const ref = missionFormRef.current;
                        data.mission_end_date = moment(data.mission_end_date).format('YYYY-MM-DD');
                        data.mission_benchmarks = data.mission_benchmarks.map((b) => ({ benchmark: b.contact, type: b.benchmark_type }));
                        console.log('data', data)
                        if (data.mission_referral) {
                            setInvitationLink(`${window.location.origin}/?mission=${data.mission_referral}`);
                        }
                        ref.setValues(data);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const getCompanyContacts = () => {
        setLoading(true);
        let config = {
            method: 'get',
            url: server + '/company/company_contact/?company_id=' + companyId,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('contacts', res.data);
                res.data.forEach((contact) => {
                    contact.first_name = contact.first_name || '';
                    contact.last_name = contact.last_name || '';
                })
                setContacts(res.data);
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const getCompanyQuestionnaireTypes = () => {
        setLoading(true);
        let config = {
            method: 'get',
            url: server + '/response/questionnaire_type?company_id=' + companyId,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('questionnaire types', res.data);
                res.data = res.data.filter((questionnaireType, index, self) => {
                    return self.findIndex((v) => v.pk === questionnaireType.pk) === index;
                });
                setQuestionnaireTypes(res.data);
                if (missionFormRef && missionFormRef.current) {
                    const ref = missionFormRef.current;
                    const values = ref.values;
                    if (res.data && res.data.length && values && values.default_questionnaire_type) {
                        res.data.forEach((qt) => {
                            if (qt.pk === values.default_questionnaire_type) {
                                values.default_questionnaire_type = qt;
                            }
                        })
                        ref.setValues(values);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const updateContacts = () => {
        const ref = missionFormRef.current;
        const values = { ...ref.values };
        const tempContacts = [...contacts];
        tempContacts.forEach((contact) => {
            contact.selected = null;
        })
        const manager = values.mission_manager || {};
        const members = values.mission_member || [];
        const viewers = values.mission_viewers || [];
        const delegates = values.mission_delegates || [];
        const benchmarks = values.mission_benchmarks || [];
        tempContacts.forEach((contact) => {
            if (values.mission_manager && values.mission_manager.id) {
                if (contact.id === manager.id) {
                    contact.selected = 'mission_manager';
                }
            }
            members.forEach((member) => {
                if (contact.id === member.id) {
                    contact.selected = 'mission_member';
                }
            });
            viewers.forEach((viewer) => {
                if (contact.id === viewer.id) {
                    contact.selected = 'mission_viewers';
                }
            });
            delegates.forEach((delegate) => {
                if (contact.id === delegate.id) {
                    contact.selected = 'mission_delegates';
                }
            });
            benchmarks.forEach((benchmark, index) => {
                if (benchmark.benchmark && contact.id === benchmark.benchmark.id) {
                    contact.selected = 'mission_benchmarks_' + index;
                }
            });
        });
        console.log('updateContacts', tempContacts.map((c) => ({ id: c.id, first_name: c.first_name, last_name: c.last_name, selected: c.selected })));
        console.log('updateContacts form', ref.values);
        setContacts(tempContacts);
    }

    const onSaveContact = (contact, type, ...args) => {
        console.log('contact saved', contact, missionFormRef);
        if (contact) {
            getCompanyContacts();
        }
        if (missionFormRef && missionFormRef.current) {
            const ref = missionFormRef.current;
            const values = ref.values;
            switch (type) {
                case 'mission_manager':
                    ref.setFieldValue('mission_manager', contact);
                    break;

                case 'mission_member':
                    const members = values.mission_member || [];
                    ref.setFieldValue('mission_member', [...members, contact]);
                    break;

                case 'mission_viewers':
                    const viewers = values.mission_viewers || [];
                    ref.setFieldValue('mission_viewers', [...viewers, contact]);
                    break;

                case 'mission_delegates':
                    const delegates = values.mission_delegates || [];
                    ref.setFieldValue('mission_delegates', [...delegates, contact]);
                    break;

                case 'mission_benchmarks':
                    const benchmarks = values.mission_benchmarks || [];
                    if (args && args.length) {
                        const benchmark = benchmarks[args[0]];
                        benchmark.benchmark = contact;
                        ref.setFieldValue('mission_benchmarks', [...benchmarks]);
                    }
                    break;

                default:
                    break;
            }
            setTimeout(updateContacts, 100);
        }

    }

    const addContactBtn = (type, label, allowedContactTypes, ...args) => {
        let defaultQuestionnaireType;
        if (missionFormRef && missionFormRef.current) {
            const ref = missionFormRef.current;
            const values = ref.values;
            if (values.default_questionnaire_type) {
                defaultQuestionnaireType = values.default_questionnaire_type;
            }
        }
        return (
            <ContactForm companyId={companyId} allowedContactTypes={allowedContactTypes} defaultQuestionnaireType={defaultQuestionnaireType} questionnaireTypes={questionnaireTypes} onSave={(contact) => onSaveContact(contact, type, ...args)}>
                <MDBTooltip domElement tag="span" placement="top">
                    <i className="fa fa-user-plus cursor-pointer text-primary" />
                    <span>{label}</span>
                </MDBTooltip>
            </ContactForm>
        )
    }

    const onFormSubmit = (mission) => {
        if (mission.mission_owner) {
            mission.mission_owner = mission.mission_owner.id;
        }
        const data = {
            ...mission,
            default_questionnaire_type: mission.default_questionnaire_type ? mission.default_questionnaire_type.pk : null,
            mission_manager: mission.mission_manager.id,
            mission_member: mission.mission_member.map((m) => m.id),
            mission_viewers: mission.mission_viewers.map((m) => m.id),
            mission_delegates: mission.mission_delegates.map((m) => m.id),
            mission_benchmarks: mission.mission_benchmarks.filter((value) => value.benchmark && value.benchmark.id).map((value) => { return { id: value.benchmark.id, benchmark_type: value.type } }),
            mission_end_date: new Date(mission.mission_end_date).toISOString(),
            type: MissionType, company: companyId
        };
        if (props.match.params.missionId) {
            updateMission(data);
        } else {
            saveMission(data);
        }
    }


    const saveMission = (data) => {
        setLoading(true);
        let config = {
            method: 'post',
            url: server + '/company/mission/',
            data: data
        }
        console.log('mission config', config);
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('missions', res.data);
                toast.success(t('Mission successfully added'));
                window.location.href = '/mobilities';
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    const updateMission = (data) => {
        setLoading(true);
        let config = {
            method: 'put',
            url: server + '/company/mission_update/?pk=' + props.match.params.missionId,
            data: data
        }
        console.log('mission config', config);
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('missions', res.data);
                toast.success(t('Mission successfully updated'));
                window.location.href = '/mobilities';
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    return (
        <React.Fragment>
            <div className='admin-container' style={{ background: "#eeeeee", minHeight: '100vh' }}>
                <Header />
                <div className='container py-4 pt-5'>
                    <div className="row">
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className='d-flex align-items-center'>
                                <div className='mb-3'>
                                    <MDBTooltip domElement tag="span" placement="bottom">
                                        <Link to='/mobilities' className='text-black-50'>
                                            <MDBIcon icon="arrow-left" size='2x' className='cursor-pointer' style={{ color: '#5F5F5F' }} />
                                        </Link>
                                        <span>{t("Cancel and go back")}</span>
                                    </MDBTooltip>
                                </div>
                                <h2 className='missions-header'>{t("Mobility Form")}</h2>
                            </div>
                        </div>
                        <MDBContainer>
                            <Loader loading={loading}>
                                <Formik
                                    innerRef={missionFormRef}
                                    key='mobilityForm'
                                    initialValues={initialValues}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            setSubmitting(false);
                                            onFormSubmit(values);
                                        }, 500);
                                    }}
                                    validationSchema={validationSchema}
                                >
                                    {(props) => {
                                        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched } = props;
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <div className='row bg-white p-4 mt-4'>
                                                    <div className='col-4'>
                                                        <MDBInput label={t("Mission Name")} group name="mission_name"
                                                            validate error="wrong" success="right" value={values.mission_name}
                                                            onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.mission_name && touched.mission_name && (
                                                            <div className="input-feedback">{t(errors.mission_name)}</div>
                                                        )}
                                                    </div>
                                                    <div className='col-4'>
                                                        <MDBInput label={t("Mission Reference")} group name="reference"
                                                            validate error="wrong" success="right" value={values.reference}
                                                            onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.reference && touched.reference && (
                                                            <div className="input-feedback">{t(errors.reference)}</div>
                                                        )}
                                                    </div>
                                                    <div className='col-4'>
                                                        <MDBInput label={t("End Date")} group name="mission_end_date" type="date"
                                                            validate error="wrong" success="right" value={values.mission_end_date}
                                                            onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.mission_end_date && touched.mission_end_date && (
                                                            <div className="input-feedback">{t(errors.mission_end_date)}</div>
                                                        )}
                                                    </div>
                                                    <div className='col-12'>
                                                        <MDBInput label={t("Description")} group name="mission_description" validate
                                                            error="wrong" success="right" value={values.mission_description}
                                                            onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.mission_description && touched.mission_description && (
                                                            <div className="input-feedback">{t(errors.mission_description)}</div>
                                                        )}
                                                    </div>
                                                    {
                                                        invitationLink &&
                                                        <div className='col-12 my-3'>
                                                            <div className='text-left'>
                                                                <div className='font-weight-bold mb-2'>{t("Invitation Link")}</div>
                                                                <div className='d-flex'>
                                                                    <span>{invitationLink}</span>
                                                                    <div className='ml-3'>
                                                                        <MDBTooltip
                                                                            domElement tag="div" placement="top" clickable >
                                                                            <div>
                                                                                <CopyToClipboard text={invitationLink}>
                                                                                    <i className="far fa-copy clipboard-icon cursor-pointer" />
                                                                                </CopyToClipboard>
                                                                            </div>
                                                                            <div>{
                                                                                {
                                                                                    'en': 'link copied',
                                                                                    'fr': 'lien copié',
                                                                                    'ru': ''
                                                                                }[lang]
                                                                            }</div>
                                                                        </MDBTooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='col-6 my-3'>
                                                        <div className='text-left'>
                                                            <div className='font-weight-bold mb-2'>{t("Questionnaire")}</div>
                                                            <div className='d-flex'>
                                                                <Typeahead
                                                                    className='w-100'
                                                                    id="default_questionnaire_type"
                                                                    multiple={false}
                                                                    labelKey={(option) => `${option.questionnaire_type}`}
                                                                    renderMenuItemChildren={(q) => (
                                                                        <MDBMedia>
                                                                            <MDBMedia body>
                                                                                {q.questionnaire_type} {(q.default_companies && q.default_companies.includes(companyId)) ? '(Company Default)' : null}
                                                                            </MDBMedia>
                                                                        </MDBMedia>
                                                                    )}
                                                                    options={questionnaireTypes}
                                                                    placeholder={t("Select a Questionnaire")}
                                                                    onChange={(selected) => {
                                                                        const value = (selected.length > 0) ? selected[0] : null;
                                                                        setFieldValue('default_questionnaire_type', value);
                                                                    }}
                                                                    selected={values.default_questionnaire_type ? [values.default_questionnaire_type] : []}
                                                                    onBlur={(e) => setFieldTouched('default_questionnaire_type', true)}
                                                                />
                                                            </div>
                                                            {errors.default_questionnaire_type && touched.default_questionnaire_type && (
                                                                <div className="typeahead-feedback">{t(errors.default_questionnaire_type)}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='col-6 my-3'>
                                                        <div className='text-left'>
                                                            <div className='font-weight-bold mb-2'>{t("Manager")}</div>
                                                            <div className='d-flex'>
                                                                <Typeahead
                                                                    id="mission_manager"
                                                                    className='w-100'
                                                                    multiple={false}
                                                                    labelKey={(option) => `${option.first_name} ${option.last_name}`}
                                                                    // onChange={setSingleSelections}
                                                                    renderMenuItemChildren={(contact) => (
                                                                        <MDBMedia>
                                                                            <MDBMedia body>
                                                                                {contact.first_name} {contact.last_name} <br />
                                                                                <span className='text-black-50'>{contact.email}</span>
                                                                            </MDBMedia>
                                                                        </MDBMedia>
                                                                    )}
                                                                    options={contacts.filter((contact) => (!contact.selected || contact.selected === 'mission_manager') && [ContactTypes.Employee.value].includes(contact.contact_type))}
                                                                    placeholder={t("Select a Manager")}
                                                                    onChange={(selected) => {
                                                                        const value = (selected.length > 0) ? selected[0] : null;
                                                                        setFieldValue('mission_manager', value);
                                                                        console.log('select manager', missionFormRef)
                                                                        setTimeout(updateContacts, 100);
                                                                    }}
                                                                    selected={values.mission_manager ? [values.mission_manager] : []}
                                                                    onBlur={(e) => setFieldTouched('mission_manager', true)}
                                                                />
                                                                <div className='contact-form-btn'>
                                                                    {addContactBtn('mission_manager', t('Add new manager'), [ContactTypes.Employee.value])}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {errors.mission_manager && touched.mission_manager && (
                                                            <div className="typeahead-feedback">{t(errors.mission_manager)}</div>
                                                        )}
                                                    </div>
                                                    <div className='col-12 my-3'>
                                                        <div className='text-left'>
                                                            <div className='font-weight-bold mb-2'>{t("Internal Candidates")}</div>
                                                            <div className='d-flex'>
                                                                <Typeahead
                                                                    id="mission_member"
                                                                    className='w-100'
                                                                    multiple={true}
                                                                    labelKey={(option) => `${option.first_name} ${option.last_name}`}
                                                                    // onChange={setSingleSelections}
                                                                    renderMenuItemChildren={(contact) => (
                                                                        <MDBMedia>
                                                                            <MDBMedia body>
                                                                                {contact.first_name} {contact.last_name} <br />
                                                                                <span className='text-black-50'>{contact.email}</span>
                                                                            </MDBMedia>
                                                                        </MDBMedia>
                                                                    )}
                                                                    options={contacts.filter((contact) => (!contact.selected) && [ContactTypes.Employee.value].includes(contact.contact_type))}
                                                                    placeholder={t("Select internal candidates")}
                                                                    onChange={(selected) => {
                                                                        const value = (selected.length > 0) ? selected : [];
                                                                        setFieldValue('mission_member', value);
                                                                        setTimeout(updateContacts, 100);
                                                                    }}
                                                                    selected={(values.mission_member instanceof Array) ? values.mission_member : []}
                                                                />
                                                                <div className='contact-form-btn'>
                                                                    {addContactBtn('mission_member', t('Add internal candidates'), [ContactTypes.Employee.value])}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {false &&
                                                        <div className='col-12 my-3'>
                                                            <div className='text-left'>
                                                                <div className='font-weight-bold mb-2'>Mission Viewers</div>
                                                                <div className='d-flex'>
                                                                    <Typeahead
                                                                        id="mission_viewers"
                                                                        className='w-100'
                                                                        multiple={true}
                                                                        labelKey={(option) => `${option.first_name} ${option.last_name}`}
                                                                        // onChange={setSingleSelections}
                                                                        renderMenuItemChildren={(contact) => (
                                                                            <MDBMedia>
                                                                                <MDBMedia body>
                                                                                    {contact.first_name} {contact.last_name} <br />
                                                                                    <span className='text-black-50'>{contact.email}</span>
                                                                                </MDBMedia>
                                                                            </MDBMedia>
                                                                        )}
                                                                        options={contacts.filter((contact) => (!contact.selected || contact.selected === 'mission_viewers'))}
                                                                        placeholder="Select Viewers"
                                                                        onChange={(selected) => {
                                                                            const value = (selected.length > 0) ? selected : [];
                                                                            setFieldValue('mission_viewers', value);
                                                                            setTimeout(updateContacts, 100);
                                                                        }}
                                                                        selected={(values.mission_viewers instanceof Array) ? values.mission_viewers : []}
                                                                    />
                                                                    <div className='contact-form-btn'>
                                                                        {addContactBtn('mission_viewers', 'Add new mission viewer')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {false &&
                                                        <div className='col-12 my-3'>
                                                            <div className='text-left'>
                                                                <div className='font-weight-bold mb-2'>Mission Delegates</div>
                                                                <Typeahead
                                                                    id="mission_delegates"
                                                                    multiple={true}
                                                                    labelKey={(option) => `${option.first_name} ${option.last_name}`}
                                                                    // onChange={setSingleSelections}
                                                                    renderMenuItemChildren={(contact) => (
                                                                        <MDBMedia>
                                                                            <MDBMedia body>
                                                                                {contact.first_name} {contact.last_name} <br />
                                                                                <span className='text-black-50'>{contact.email}</span>
                                                                            </MDBMedia>
                                                                        </MDBMedia>
                                                                    )}
                                                                    options={contacts.filter((contact) => contact.user_id && (!contact.selected || contact.selected === 'mission_delegates'))}
                                                                    placeholder="Select Delegates"
                                                                    onChange={(selected) => {
                                                                        const value = (selected.length > 0) ? selected : [];
                                                                        setFieldValue('mission_delegates', value);
                                                                        setTimeout(updateContacts, 100);
                                                                    }}
                                                                    selected={(values.mission_delegates instanceof Array) ? values.mission_delegates : []}
                                                                >
                                                                    {/* <div className='contact-form-btn'>
                                                                    {addContactBtn('mission_delegates', 'Add new delegate')}
                                                                </div> */}
                                                                </Typeahead>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='col-12 my-3'>
                                                        <div className='text-left'>
                                                            <div className='font-weight-bold mb-2'>{t("Team members")}</div>
                                                            <MDBTable striped className='missions'>
                                                                <MDBTableHead>
                                                                    <tr>
                                                                        <th>{t("Name")}</th>
                                                                        <th>{t("Role")}</th>
                                                                        <th>{t("Actions")}</th>
                                                                    </tr>
                                                                </MDBTableHead>
                                                                <MDBTableBody>
                                                                    {
                                                                        values.mission_benchmarks.map((value, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div className='d-flex'>
                                                                                        <Typeahead
                                                                                            id={`benchmark_${index}`}
                                                                                            className='w-100'
                                                                                            labelKey={(option) => `${option ? option.first_name : null} ${option ? option.last_name : null}`}
                                                                                            // onChange={setSingleSelections}
                                                                                            renderMenuItemChildren={(contact) => (
                                                                                                <MDBMedia>
                                                                                                    <MDBMedia body>
                                                                                                        {contact.first_name} {contact.last_name} <br />
                                                                                                        <span className='text-black-50'>{contact.email}</span>
                                                                                                    </MDBMedia>
                                                                                                </MDBMedia>
                                                                                            )}
                                                                                            options={contacts.filter((contact) => (!contact.selected || contact.selected === `mission_benchmarks_${index}`) && [ContactTypes.Employee.value].includes(contact.contact_type))}
                                                                                            placeholder={t("Select members")}
                                                                                            onChange={(selected) => {
                                                                                                const value = (selected.length > 0) ? selected[0] : null;
                                                                                                const benchmarks = values.mission_benchmarks;
                                                                                                benchmarks[index].benchmark = value;
                                                                                                setFieldValue('mission_benchmarks', benchmarks);
                                                                                                setTimeout(updateContacts, 100);
                                                                                            }}
                                                                                            selected={(value && value.benchmark) ? [value.benchmark] : []}
                                                                                        />
                                                                                        <div className='contact-form-btn'>
                                                                                            {addContactBtn('mission_benchmarks', t('Add new team member'), [ContactTypes.Employee.value], index)}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <select className="browser-default custom-select" defaultValue='' value={values.mission_benchmarks[index].type} name='benchmarkType' onChange={(e) => {
                                                                                        const benchmarks = values.mission_benchmarks;
                                                                                        benchmarks[index].type = e.target.value;
                                                                                        setFieldValue('mission_benchmarks', benchmarks);
                                                                                    }}>
                                                                                        <option value="employee">{t("Employee")}</option>
                                                                                        <option value="manager">{t("Manager")}</option>
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <button className='action-btn' type='button' onClick={() => {
                                                                                        const benchmarks = values.mission_benchmarks;
                                                                                        benchmarks.splice(index, 1);
                                                                                        setFieldValue('mission_benchmarks', benchmarks);
                                                                                        setTimeout(updateContacts, 100);
                                                                                    }}>
                                                                                        <MDBIcon icon="trash" className='icon' />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </MDBTableBody>
                                                            </MDBTable>
                                                            <span type='button' className='text-primary' onClick={() => {
                                                                const benchmarks = values.mission_benchmarks;
                                                                setFieldValue('mission_benchmarks', [...benchmarks, {
                                                                    benchmark: null,
                                                                    type: "employee"
                                                                }])
                                                                setTimeout(updateContacts, 100);
                                                            }}>+ {t("Add Team member")}</span>
                                                        </div>
                                                    </div>
                                                    <div className="text-center mb-3 d-flex col-12">
                                                        <MDBBtn
                                                            rounded
                                                            className="inline-block btn wwt-btn flat wwt-btn-blueli"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {t("Save mission")}
                                                        </MDBBtn>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>

                            </Loader>
                        </MDBContainer>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default withRouter(MobilityForm);