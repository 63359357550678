import React from 'react'
import CookieModal from './Modals/CookieModal'
import PersonalModal from './Modals/PersonalModal'
import LegalModal from './Modals/LegalModal'
import SendMailModal from './Modals/SendMailModal'
import mail_icon from '../../assets/mail.png'
import linkedin_icon from '../../assets/linkedin.png'
import facebook_icon from '../../assets/facebook.png'
import { useTranslation } from 'react-i18next'
import './ContactPage.css'
import { Link } from 'react-router-dom'

function ContactPage() {

    const { t } = useTranslation();

    return (
        <div className="contact-page">
            {/* <div className='container'> */}
            <div className='row'>
                <div className='align-items-md-center col-12 col-md-5 d-flex flex-column flex-md-row ml-3 ml-md-0'>
                    <div className='d-flex align-items-center mb-2 mb-md-0'>
                        <i className="far fa-envelope social-icon" aria-hidden="true"></i>
                        <SendMailModal />
                    </div>
                    <div className='d-flex align-items-center ml-md-4 mb-2 mb-md-0'>
                        <a className='d-flex mr-2' href="https://www.facebook.com/Work-Well-Together-101862698395748" target="_blank" rel="noreferrer noopener">
                            <i className="fab fa-facebook-square social-icon" aria-hidden="true"></i>
                        </a>
                        <a className='d-flex' href="https://www.linkedin.com/company/work-well-together/" target="_blank" rel="noreferrer noopener">
                            <i className="fab fa-linkedin social-icon" aria-hidden="true"></i>
                        </a>
                        <div className="suivez-nous">{t("Follow us")}</div>
                    </div>
                </div>
                <div className='col-12 col-md-7 d-flex flex-md-row justify-content-end flex-column align-items-md-center'>
                    <Link to='/privacypolicy'>
                        <div className="politique-de-cookies mt-2 mt-md-0">
                            {t("Terms of use and protection policy on personal data")}
                        </div>
                    </Link>
                    {/* <div className='d-none d-md-block'>|</div>
                    <PersonalModal /> */}
                    <div className='d-none d-md-block'>|</div>
                    <div className="politique-de-cookies mt-2 mt-md-0">
                        <a href='https://www.cookiesandyou.com/' target='_blank '>{t("Cookies policy")}</a></div>

                    {/* <CookieModal /> */}
                </div>
            </div>
            {/* </div> */}
            {/* <div className="row-one">
                <img src={mail_icon} className="mail-icon" alt="mail" />
                <SendMailModal />
            </div>
            <div className="row-two">
                <a href="https://www.facebook.com/Work-Well-Together-101862698395748" target="_blank" rel="noreferrer noopener">
                    <img src={facebook_icon} className="facebook-icon" alt="facebook" />
                </a>
                <a href="https://www.linkedin.com/company/work-well-together/" target="_blank" rel="noreferrer noopener">
                    <img src={linkedin_icon} className="linkedin-icon" alt="linkedin" />
                </a>
                <div className="suivez-nous">Suivez-nous</div>
            </div>
            <LegalModal />
            <PersonalModal />
            <CookieModal /> */}
        </div>
    )
}

export default ContactPage