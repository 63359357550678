import React from 'react'
import { useTranslation } from 'react-i18next'
import LoginModal from '../Auth/LoginModal'
import take_test from '../../assets/take-the-test.svg'
import learn_about from '../../assets/learn-about.svg'
import match_with_friends from '../../assets/match-with-friends.svg'
import open_opportunities from '../../assets/open-opportunities.svg'
import './HomePage.css'
import { isUserLoggedIn } from '../../utils/utils'
import { Link } from 'react-router-dom'
import profile from '../../assets/profile.svg';

function SecondPage(props) {

    const { t } = useTranslation();

    return (
        <div className="second-page" id="second-page">
            <div className='container'>
                <div className="how-it-works">{t("How it works")}</div>
                <div className='row align-items-baseline'>
                    <div className="step step-01 col-12 col-md-6 col-lg-4 mb-sm-4">
                        <img src={take_test} className="step-img" alt="take test"></img>
                        <div className="step-content first-content d-flex flex-column">
                            <div className="step-title">{t("Questionnaire")}</div>
                            <div className="step-description">
                                {t("Only if you have received a link, ")}
                                {t("answer 18 ")}
                                {t("situational questions ")}
                                {t("in 15-20 minutes. ")}
                            </div>
                            <div className="step-description">
                                {t("It is not a evaluation! ")}
                            </div>
                            <div className="step-description">
                                {t("No judgement! ")}
                            </div>
                        </div>
                    </div>
                    <div className="line-home d-block d-sm-none"></div>
                    <div className="step step-02  col-12 col-md-6 col-lg-4 mb-sm-4">
                        <img src={learn_about} className="step-img" alt="learn about"></img>
                        <div className="step-content second-content">
                            <div className="step-title">{t("Relational profile")}</div>
                            <div className="step-description"> {/* discover-your-domina */}
                                {t("Discover a brief summary ")}
                                <b>{t("of your relational profile both in everyday situations ")}</b>
                                {t("and under stress")}
                                <b>{t("in all kindness. ")}</b>
                                {t("Have your work connections ")}
                                <b>{t("complete the questionnaire ")}</b>
                                {t("or as recommended by your employer. ")}
                            </div>
                        </div>
                    </div>
                    {/* <div className="step-03">
                        <div className="match-with-your-frie">{t("Our 'Relationers'")}</div>
                        <div className="third-content">
                            <img src={match_with_friends} className="group-3" alt="match with friends"></img>
                            <div className="take-a-15-minutes-te">
                                {t("Understand your ")}
                                <b>{t("unique relational interactions. ")}</b>{t("Follow the personalized advice and recommendations "}
                                <b>{t("friends and colleagues ")}</b>
                                {t("better collaboration. ")}
                            </div>
                        </div>
                    </div> */}
                    <div className="line-home d-block d-sm-none"></div>
                    <div className="step step-03  col-12 col-md-6 col-lg-4 mb-sm-4">
                        <img src={match_with_friends} className="step-img" alt="match with friends"></img>
                        <div className="step-content third-content">
                            <div className="step-title">{t("Our 'Relationers'")}</div>
                            <div className="step-description">
                                {t("Understand your ")}
                                <b>{t("unique relational interactions. ")}</b>
                                {t("Follow the personalized advice and recommendations ")}
                                <b>{t("of our certified 'Relationers' to develop ")}</b>
                                {t("better collaboration. ")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-button">
                    {/* <LoginModal setPassed={props.setPassed} setEmail={props.setEmail} /> */}
                    {isUserLoggedIn() ?
                        <Link to='/results'>
                            <div className='wwt-btn wwt-btn-pink font-montserrat mx-auto'>
                                <img src={profile} alt="profile icon" className="profile-icon" />
                                {t("My account")}
                            </div>
                        </Link> :
                        <LoginModal
                            setEmail={props.setEmail}
                            setPassed={props.setPassed}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default SecondPage