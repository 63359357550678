import React from 'react';
import logo from '../../../assets/header.svg';
import shareLady from '../../../assets/share-lady.svg';
import './BarometerLastPage.css';

function BarometerLastPage({ company }) {
    return (
        <>
            <div className="header">
                <div className='container header-block d-flex justify-content-between'>
                    <img src={logo} alt="work well together" className="logo" />
                    {company?.company_logo && <img src={company?.company_logo} alt="work well together" className="company-logo ml-4" />}
                </div>
            </div>
            <div className="main-page position-relative main-container" id="main-page">
                <div className='container position-relative text-white my-4'>
                    <h4 className='mb-3'>Merci d’avoir complété le questionnaire “Baromètre social”</h4>
                    <h4 className='mb-3'>Vous pouvez désormais fermer le navigateur.</h4>
                    <div className='mt-5'>
                        <img src={shareLady} alt='Work well together' className='share-lady-img' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BarometerLastPage;