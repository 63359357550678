import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getLanguage } from 'utils/utils';
import './SelectWordBlock.css';

function SelectWordBlock({ words, max = 12, min = 12, onNext }) {
    const { t } = useTranslation();
    const lang = getLanguage();

    const [selectedWords, setSelectedWords] = useState([]);

    const onWordClick = (word) => {
        if (selectedWords.find((w) => w.word_id === word.word_id)) {
            setSelectedWords([...selectedWords.filter(w => w.word_id !== word.word_id)]);
        } else {
            if (selectedWords?.length < max) {
                selectedWords.push(word);
                setSelectedWords([...selectedWords]);
            }
        }
    }

    const onSubmit = () => {
        onNext(selectedWords);
    }
    return (
        <div>
            <div className="main">
                <div className='wwt-container'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <div className="select-title">
                            {t("Select the 12 words that are the most important to your eyes")}
                        </div>
                    </div>
                    <div className="words" style={{ flexWrap: "wrap" }}>
                        {words.length > 0 &&
                            words.map((word, index) => {
                                return <div onClick={e => onWordClick(word)} key={index}
                                    className={`word-button ${selectedWords?.map(word => word.word_id)?.includes(word.word_id) ? 'selected' : ''} ${(selectedWords?.length < max) ? '' : 'max'}`}>
                                    {
                                        {
                                            'en': word.en,
                                            'fr': word.fr,
                                            'ru': word.ru
                                        }[lang]
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="select-rooter">
                <div className="select-line" />
                <div className='wwt-container'>
                    <div className="bottom-content">
                        <div style={{ display: 'flex' }}>
                            <div className="select-number">{selectedWords?.length} / 12</div>
                            <div className="bottom-text">{t("word(s) selected")}</div>
                        </div>
                        {/* <button onClick={onSubmit}>{t("Next")}</button> */}
                        <button
                            style={{ outline: "none" }}
                            className="next-btn"
                            onClick={() => onSubmit()}
                            disabled={selectedWords?.length < min}
                        >
                            {t("Next")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectWordBlock
