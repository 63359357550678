import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as Yup from 'yup';
import './ResetPassword.css';
import { MDBBtn, MDBInput } from 'mdbreact';
import server from '@config'
import axios from 'axios'
import Loader from 'components/Loader/Loader';
import toast from 'react-hot-toast';

function ResetPassword(props) {

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState();

    // let token = '';

    useEffect(() => {
        // token = new URLSearchParams(window.location.search).get("token");
        setToken(new URLSearchParams(window.location.search).get("token"));
    }, []);

    const validateToken = () => {
        let data = {
            token: token
        }
        let config = {
            method: "post",
            url: server + '/password_reset/validate_token/',
            data: data
        }
        console.log(config)
        setLoading(true);
        axios(config)
            .then(res => {
                console.log('login response', res);
                setLoading(false);
                const data = res.data;
                if (data.status) {
                } else {
                    toast.error('Invalid token');
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false);
                toast.error('Invalid token');
            })
    }

    useEffect(() => {
        if (token) {
            validateToken();
        }
    }, [token]);


    const onSubmit = (values) => {
        console.log('on submit', values);
        let data = {
            token: token,
            password: values.password
        }
        let config = {
            method: "post",
            url: server + '/password_reset/confirm/',
            data: data
        }
        console.log(config)
        setLoading(true);
        axios(config)
            .then(res => {
                console.log('login response', res);
                setLoading(false);
                const data = res.data;
                toast.success('Your password reset successfully.');
                window.location.replace('/');
                if (data.status) {
                } else {
                    toast.error('Failed to reset your password');
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false);
                if (err && err.data && err.data.password && err.data.password.length) {
                    toast.error(err.data.password[0]);
                } else {
                    toast.error('Failed to reset your password');
                }
            })
    }

    return (
        <div className='reset-pwd-container'>
            <div className='reset-pwd-block'>
                <Loader loading={loading}>
                    <h3>Reset Password</h3>
                    <div>
                        <Formik
                            key='signInForm'
                            initialValues={{ password: "", confirmPassword: "" }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    onSubmit(values);
                                }, 500);
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string()
                                    .required("No password provided.")
                                    .min(
                                        8,
                                        "Password is too short - should be 8 chars minimum."
                                    )
                                    .matches(
                                        /(?=.*[0-9])/,
                                        "Password must contain a number."
                                    ),
                                confirmPassword: Yup.string()
                                    .required("No password provided.")
                                    .min(
                                        8,
                                        "Password is too short - should be 8 chars minimum."
                                    )
                                    .matches(
                                        /(?=.*[0-9])/,
                                        "Password must contain a number."
                                    ).when("password", {
                                        is: val => (val && val.length > 0 ? true : false),
                                        then: Yup.string().oneOf(
                                            [Yup.ref("password")],
                                            "Both password need to be the same"
                                        )
                                    }),
                            })}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <MDBInput
                                            label="Password"
                                            name="password"
                                            group
                                            type="password"
                                            validate
                                            containerClass="mb-0"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.password && touched.password && (
                                            <div className="input-feedback">
                                                {errors.password}
                                            </div>
                                        )}
                                        <MDBInput
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            group
                                            type="password"
                                            validate
                                            containerClass="mb-0"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <div className="input-feedback">
                                                {errors.confirmPassword}
                                            </div>
                                        )}
                                        <div className="text-center mb-3">
                                            <MDBBtn
                                                rounded
                                                className="btn-block z-depth-1a sign-in-button btn wwt-btn w-100 btn-primary flat"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Change Password
                          </MDBBtn>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </Loader>
            </div>
        </div>
    );
}

export default ResetPassword;