import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoginModal from '../Auth/LoginModal'
import photo_1 from '../../assets/photo-1.png'
import photo_2 from '../../assets/photo-2.png'
import down_arrow from '../../assets/down-arrow.png'
import './ThirdPage.css'
import { isUserLoggedIn } from '../../utils/utils'
import { Link } from 'react-router-dom'
import profile from '../../assets/profile.svg';


function ThirdPage(props) {

    const [className_1, setClassName_1] = useState('paragraph-bigger-1')
    const [className_2, setClassName_2] = useState('paragraph-2')
    const [read_more_1, setReadMore_1] = useState('read-more-1')
    const [read_more_2, setReadMore_2] = useState('read-more-2')

    const { t } = useTranslation();
    const moreClick_1 = (e) => {
        className_1 === 'paragraph-bigger-1' ? setClassName_1('paragraph-bigger-1-more') : setClassName_1('paragraph-bigger-1')
        setReadMore_1('read-more-hiden')
    }

    return (
        <div className="third-page" id="third-page">
            <div className='container'>
                <div className='row d-flex justify-content-around'>
                    <div className="group col-12">
                        <div className={className_1}>
                            {t("Work Well Together (WWT) is a revolutionary initiative dedicated to improving emotional relationships in the workplace. Developed by an expert clinical psychologist in emotions, WWT uses advanced data analysis to explore and understand the unique relational dynamics within professional environments. ")}
                            <br />{t("Our mission is to create strong and fulfilling connections in the workplace, improve employees’ emotional and mental well-being, and facilitate meaningful interactions. Guided by the belief that ‘Everything depends on the relationship’, WWT emphasizes the essence of human interactions in professional environments. Whether it’s strengthening a team, enhancing cohesion within a company, or optimizing relationships among colleagues, WWT is the key to more cohesive and productive work environments, where everyone finds their place and feels understood and appreciated. ")}
                            <br />{t("Thanks to our full-service solution, WWT Relational Solutions, we offer comprehensive support, from the initial questionnaire to personalized coaching. ")}
                            <br />{t("Derived from over 15 years of research and experimentation, WWT aims to radically transform workplace well-being. As the driving force behind the Be Well Together (BWT) initiative, WWT revolutionizes how we approach professional relationships. ")}
                            <br />{t("Join us to discover how WWT can transform your professional relationships and guide you toward a future where well-being aligns with performance. ")}
                        </div>
                        <div className={read_more_1}>
                            <div className="button-content" onClick={moreClick_1}>
                                <img src={down_arrow} alt="down-arrow" className="down-arrow" />
                        &nbsp;   {t("More")}    &nbsp;
                        <img src={down_arrow} alt="down-arrow" className="down-arrow" />
                                <div className="read-more-line" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <LoginModal setEmail={props.setEmail} setPassed={props.setPassed} /> */}
                {isUserLoggedIn() ?
                    <Link to='/results'>
                        <div className='wwt-btn wwt-btn-pink font-montserrat mx-auto'>
                            <img src={profile} alt="profile icon" className="profile-icon" />
                            {t("My account")}
                        </div>
                    </Link> :
                    <LoginModal
                        setEmail={props.setEmail}
                        setPassed={props.setPassed}
                    />
                }
            </div>
        </div>
    )
}

export default ThirdPage