import React, { useEffect, useState } from 'react'
import up_icon from '../../../assets/up.png'
import down_icon from '../../../assets/down.png'
import './Accordion.css'
import { getLanguage } from 'utils/utils'

function Accordion(props) {

  const [expand, setExpand] = useState(false)
  const [code, setCode] = useState()
  const [description, setDescription] = useState()
  const handleExpand = () => {
    setExpand(!expand)
  }

  const lang = getLanguage();

  useEffect(() => {
    switch(lang) {
      case 'en':
        setCode(props.code[0])
        setDescription(props.softSkill[0])
        break
      case 'fr':
        setCode(props.code[1])
        setDescription(props.softSkill[1])
        break
      case 'ru':
        setCode(props.code[2])
        setDescription(props.softSkill[2])
        break
      default:
        break
    }
  }, [props, lang])

  return (
    <div className="progress-accordion-bottom">
      <div className="progress-accordion-row-1">
        <div className="results-accordion-progressbar">
          <div style={{display: 'flex',width:'100%', justifyContent: 'space-between'}}>
            <div className="softskill">{code}</div>
          </div> 
        </div>
        <div className="accordion-icon-frame" onClick={handleExpand}>
          <img src={expand ? up_icon : down_icon} alt="accordion-icon" className="accordion-icon" />
        </div>
      </div>
      {
        expand && 
        <div className="accordion-content">
          {description}
      </div>
      }
    </div>
  )
}

export default Accordion