import server from '@config'
import axios from 'axios'

export const getMissionById = (missionId) => {
    let config = {
        method: 'get',
        url: server + '/company/mission_update/?pk=' + missionId,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios(config);
}

export const removeMemberFromMission = (missionId, contactId, companyId) => {
    return getMissionById(missionId)
        .then((res) => {
            if (res.data) {
                const mission = res.data;
                if (mission.mission_member) {
                    console.log('mission members before', mission.mission_member.map((m) => ({ name: m.first_name, id: m.id })));
                    mission.mission_member = mission.mission_member.filter((m) => m.id !== contactId);
                    console.log('mission members after', mission.mission_member.map((m) => ({ name: m.first_name, id: m.id })));
                }
                if (mission.mission_owner) {
                    mission.mission_owner = mission.mission_owner.id;
                }
                const data = {
                    ...mission,
                    mission_manager: mission.mission_manager?.id,
                    mission_member: mission.mission_member.map((m) => m.id),
                    mission_viewers: mission.mission_viewers.map((m) => m.id),
                    mission_delegates: mission.mission_delegates.map((m) => m.id),
                    mission_benchmarks: mission.mission_benchmarks.filter((value) => value.contact && value.contact.id).map((value) => { return { id: value.contact.id, benchmark_type: value.benchmark_type } }),
                    mission_end_date: new Date(mission.mission_end_date).toISOString(),
                    type: mission.type, company: companyId || mission.mission_manager?.company_id
                };
                return updateMission(missionId, data);
            }
            return res;
        })
}

export const removeBenchmarkFromMission = (missionId, contactId) => {
    return getMissionById(missionId)
        .then((res) => {
            if (res.data) {
                const mission = res.data;
                if (mission.mission_benchmarks) {
                    mission.mission_benchmarks = mission.mission_benchmarks.filter((m) => m.contact.id !== contactId);
                }
                if (mission.mission_owner) {
                    mission.mission_owner = mission.mission_owner.id;
                }
                const data = {
                    ...mission,
                    mission_manager: mission.mission_manager.id,
                    mission_member: mission.mission_member.map((m) => m.id),
                    mission_viewers: mission.mission_viewers.map((m) => m.id),
                    mission_delegates: mission.mission_delegates.map((m) => m.id),
                    mission_benchmarks: mission.mission_benchmarks.filter((value) => value.contact && value.contact.id).map((value) => { return { id: value.contact.id, benchmark_type: value.benchmark_type } }),
                    mission_end_date: new Date(mission.mission_end_date).toISOString(),
                    type: mission.type, company: mission.mission_manager.company_id
                };
                return updateMission(missionId, data);
            }
            return res;
        })
}

export const duplicateMission = (data, companyId) => {
    let mission = JSON.parse(JSON.stringify(data));
    mission = {
        ...mission,
        mission_manager: mission?.mission_manager?.id,
        mission_member: mission.mission_member.map((m) => m.id),
        mission_viewers: mission.mission_viewers.map((m) => m.id),
        mission_delegates: mission.mission_delegates.map((m) => m.id),
        mission_benchmarks: mission.mission_benchmarks.filter((value) => value.contact && value.contact.id).map((value) => { return { id: value.contact.id, benchmark_type: value.benchmark_type } }),
        mission_end_date: new Date(mission.mission_end_date).toISOString(),
        type: mission.type, company: companyId || mission?.mission_manager?.company_id,
        reference: `${mission.reference}(1)`
    };
    delete mission.mission_creation_date;
    let config = {
        method: 'post',
        url: server + '/company/mission/',
        data: mission
    }
    console.log('mission config', config);
    return axios(config);
}

const updateMission = (missionId, data) => {
    let config = {
        method: 'put',
        url: server + '/company/mission_update/?pk=' + missionId,
        data: data
    }
    console.log('mission config', config);
    return axios(config);
}