import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import server from '@config'
import { useTranslation } from 'react-i18next'
import './SelectPage.css'
import { getLanguage, gaEvent, isB2B } from 'utils/utils'
import toast from 'react-hot-toast';

function SelectPage() {

  const [selects, setSelects] = useState([])
  const [words, setWords] = useState([])
  const [btnClassName, setClassName] = useState('next-button')
  const [selNum, setSelNum] = useState(0)
  // const history = useHistory()
  const [passed, setPassed] = useState(false)
  const lang = getLanguage();
  const { t } = useTranslation();

  const onSelect = (e, id) => {
    if (e.target.className === "word-button" && selects.length < 12) {
      e.target.className = "word-button-select"
      let temp = selects
      temp.push(id)
      setSelects(temp)
      setSelNum(temp.length)
      if (temp.length === 12) {
        setClassName('next-button-active')
      }
    } else if (e.target.className === "word-button-select" && selects.length <= 12) {
      e.target.className = "word-button"
      let temp = selects
      temp.splice(temp.indexOf(id), 1)
      setSelects(temp)
      setSelNum(temp.length)
      if (temp.length === 11) {
        setClassName('next-button')
      }
    }
  }

  useEffect(() => {
    let data = { "email": localStorage.getItem('email') }
    gaEvent("EVALUATION", "EVALUATION_QUESTIONS_DONE", "Evaluation Questions Done");

    let config1 = {
      method: 'post',
      url: server + '/result/select/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config1)
      .then((res) => {
        let temp = res.data['result']

        if (temp.length === 0) {
          let config = {
            method: 'get',
            url: server + '/words/',
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          };

          axios(config)
            .then(res => {
              // console.log(res.data, '_+_+_+_+_+_+_')
              setWords(res.data)
            })
            .catch(err => {
              console.log(err);
              toast.error("Failed to retrieve the words")
            });
        } else {
          setPassed(true)
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to retrieve the results")
      });

  }, [])

  const onSubmit = (e) => {
    if (e.target.className === 'next-button-active') {
      let temp = ''
      selects.map(word => {
        temp += word + ','
      })

      let data = { "email": localStorage.getItem('email'), "result": temp }
      let config = {
        method: 'put',
        url: server + '/result/select/',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(res => {
          console.log(res.data, 'select submit message-----')
        })
        .catch(err => {
          console.log(err, 'select submit error message')
          toast.error("Failed to submit the selected words")
        })

      // history.push('/sort')
      setPassed(true)
    }
  }

  if (!passed) {
    return (
      <div>
        <div className="main">
          <div className='wwt-container'>
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <div className="select-title">
                {t("Select the 12 words that are the most important to your eyes")}
              </div>
              {
                isB2B() &&
                <Link className='d-flex align-items-center ml-3' to='/recruitments'>
                  <div className="wwt-btn bg-warning">
                    {t("Pro Access")}
                  </div>
                </Link>
              }
            </div>
            <div className="words" style={{ flexWrap: "wrap" }}>
              {words.length > 0 &&
                words.map((word, index) => {
                  return <div onClick={e => onSelect(e, word.word_id)} key={index} className="word-button">
                    {
                      {
                        'en': word.en,
                        'fr': word.fr,
                        'ru': word.ru
                      }[getLanguage()]
                    }
                  </div>
                })
              }
            </div>
          </div>
        </div>
        <div className="select-rooter">
          <div className="select-line" />
          <div className='wwt-container'>
            <div className="bottom-content">
              <div style={{ display: 'flex' }}>
                <div className="select-number">{selNum} / 12</div>
                <div className="bottom-text">{t("word(s) selected")}</div>
              </div>
              <button className={btnClassName} onClick={onSubmit}>{t("Next")}</button>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    gaEvent("EVALUATION", "EVALUATION_WORDS_SELECTION_DONE", "Evaluation Words Selection Done");
    return <Redirect to="sort" />
  }

}

export default SelectPage