import React, { useState, useEffect } from "react"
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBModal, MDBIcon } from 'mdbreact'
import server from '@config'
import './SendMailModal.css';
import toast from 'react-hot-toast';

function SendMailModal() {

  const [modal, setModal] = useState(false)
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [span, setSpan] = useState('no-error')

  const { t } = useTranslation();

  const toggle = () => {
    setModal(!modal)
  }

  const validEmail = (email) => {
    return (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email))
  }

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const onSubjectChange = (e) => {
    setSubject(e.target.value)
  }

  const onMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const onSubmit = () => {
    if (validEmail(email)) {
      setSpan('d-none')
      let data = {
        sender_email: email,
        sender_name: name,
        subject: subject,
        message: message
      }
      let config = {
        method: "post",
        url: server + '/send/contact',
        data: data
      }
      console.log(config)
      axios(config)
        .then(res => {
          console.log(res.data['message'])
          toast.success(t("message successfully sent"));
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setSpan('d-block')
    }
  }

  useEffect(() => {
    setSpan('d-none')
  }, [modal])

  return (
    <MDBContainer style={{ padding: '0' }}>
      <div className="nous-contacter" onClick={toggle}>{t("Contact us")}</div>
      <MDBModal isOpen={modal} toggle={toggle} className="contact-modal">
        <MDBRow className="contact-main">
          <MDBCol md="12">
            <form>
              <div className="h4 text-center mb-4 contact-title">{t("Contact us")}</div>
              <div className="grey-text">
                <MDBInput label={t("Your name")} icon="user" group type="text" validate error="wrong"
                  success="right" onChange={onNameChange} />
                <MDBInput label={t("Your email")} icon="envelope" group type="email" validate error="wrong"
                  success="right" onChange={onEmailChange} />
                <MDBInput label={t("Subject")} icon="tag" group type="text" validate error="wrong" success="right" onChange={onSubjectChange} />
                <MDBInput type="textarea" rows="2" label={t("Your message")} icon="pencil-alt" className="contact-box" onChange={onMessageChange} />
              </div>
              <span className={span}>{t("Invalid email address")}</span>
              <div className="text-center">
                <MDBBtn onClick={onSubmit} className="wwt-btn" color="primary" >
                  {t("Send")}
                  <MDBIcon far icon="paper-plane" className="ml-1" />
                </MDBBtn>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBModal>
    </MDBContainer>
  )
}

export default SendMailModal