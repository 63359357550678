import axios from 'axios';
import { logOut } from './utils/utils';

const skipTOkenEndpoints = [
    'auth/user/register',
    'auth/user/login',
    'company/country/',
    'company/state/',
    'company/city/',
    'company/department/',
    'company/company/',
    'send/contact',
    'barometer_response',
    'is_mission_active',
    'get_company_from_mission'
];

axios.interceptors.request.use(function (config) {
    if (!skipTOkenEndpoints.filter((e) => config.url.includes(e)).length) {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = 'Token ' + token;
        }
    }
    return config;
});

axios.interceptors.response.use(
    res => res,
    err => {
        console.log('err', err.response);
        if (err.response.status === 404) {
            throw new Error(`${err.config.url} not found`);
        } else if (err.response.status === 401) {
            logOut();
            window.location.href = '/';
            throw new Error(`${err.config.url} not authorized`);
        }
        throw err.response;
    }
);