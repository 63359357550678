import { MDBIcon } from 'mdbreact';
import { MenuItem, MenuItemGroup } from 'rc-menu';
import React from 'react';

function CompleteProfileSubMenu({ user, t }) {
    return (
        <>
            {
                (user && !user.confidential && user.complete_profile && (user.complete_profile.en || user.complete_profile.fr)) ?
                    <MenuItemGroup className='menu-item-group' title={<div>{t("Profile Report")}</div>}>
                        <MenuItem key='profile_report_pdf_en'>
                            <a href={user?.complete_profile?.en} target='_blank' rel="noopener noreferrer">
                                <button className='action-btn'>
                                    <MDBIcon icon="download" className='icon' />
                                    <span className='text'>{t("English")}</span>
                                </button>
                            </a>
                        </MenuItem>

                        <MenuItem key='profile_report_pdf_fr'>
                            <a href={user?.complete_profile?.fr} target='_blank' rel="noopener noreferrer">
                                <button className='action-btn'>
                                    <MDBIcon icon="download" className='icon' />
                                    <span className='text'>{t("French")}</span>
                                </button>
                            </a>
                        </MenuItem>
                    </MenuItemGroup>
                    : null
            }
        </>
    )
}

export default CompleteProfileSubMenu;
