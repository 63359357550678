import MultiLang from 'components/MultiLang/MultiLang';
import { useAuth } from 'context/auth';
import { MDBIcon } from 'mdbreact';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { Item as MenuItem } from 'rc-menu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { getProfile, logOut } from 'utils/utils';
import logo from '../../../assets/WWT icone blue.svg';
import './Header.css';

function Header(props) {
    const { t } = useTranslation();
    const [profile, setProfile] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const { setAuthTokens } = useAuth();
    const [company, setCompany] = useState(null);

    useEffect(() => {
        setProfile(getProfile());
    }, [])

    useEffect(() => {
        if (profile && profile.companies && profile.companies.length) {
            setCompany(profile.companies[0].company);
        }
    }, [profile])

    useEffect(() => {
        console.log('location', props.location);
        if (props && props.location && props.location.pathname) {
            const menu = props.location.pathname.split('/').length ? props.location.pathname.split('/')[1] : null;
            setSelectedMenu(menu)
        }
    }, [props.location])

    const logOutUser = () => {
        logOut();
        setAuthTokens(null);
    }

    return (
        <div className="">
            <header>
                <nav className="navbar navbar-expand-sm navbar-light bg-white shadow-none">
                    <div className='container d-flex align-items-center'>
                        <img src={logo} alt="work well together" width='44px' height='44px' />
                        <div className='d-flex align-items-center'>
                            <MultiLang />
                            <Link to='/company-contacts'>
                                <div className="wwt-btn wwt-btn-blueli profile-btn ml-4">
                                    {t("Company Contacts")}
                                </div>
                            </Link>
                            <button className="navbar-toggler d-lg-none ml-4" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <Menu className='p-2' selectable={false}>
                                        <MenuItem key='choice'>
                                            <Link to='/results'>
                                                <button className='action-btn'>
                                                    <MDBIcon icon="user" className='icon' />
                                                    <span className='text'>{t("My Profile")}</span>
                                                </button>
                                            </Link>
                                        </MenuItem>
                                        <MenuItem key='logout'>
                                            <button className='action-btn' onClick={() => logOutUser()}>
                                                <MDBIcon icon="sign-out-alt" className='icon' />
                                                <span className='text'>{t("Logout")}</span>
                                            </button>
                                        </MenuItem>
                                    </Menu>
                                }
                                animation="slide-up"
                                placement="bottomRight">
                                <div className="wwt-btn wwt-btn-pink profile-btn ml-4">
                                    {profile ? `${profile.first_name} ${profile.last_name}` : null}
                                    <i className="fa fa-chevron-down ml-2" aria-hidden="true"></i>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </nav>
            </header>

            <div className="nav-scroller py-1 mb-2 text-white admin-header">
                <nav className="nav h-100 d-flex justify-content-between align-items-center container">
                    <a className="menu-item text-white-50 disabled" >{t("Home")}</a>
                    <Link to="/recruitments" className={`menu-item ${selectedMenu === 'recruitments' ? ' active ' : ''}`}>{t("Recruitment")}</Link>
                    <Link to="/mobilities" className={`menu-item ${selectedMenu === 'mobilities' ? ' active ' : ''}`}>{t("Mobility")}</Link>
                    <Link to="/onboardings" className={`menu-item ${selectedMenu === 'onboardings' ? ' active ' : ''}`}>{t("Onboarding")}</Link>
                    <Link to="/team-building" className={`menu-item ${selectedMenu === 'team-building' ? ' active ' : ''}`}>{t("Team Building")}</Link>
                    {
                        company?.supports_barometer ?
                            <Link to="/barometer" className={`menu-item ${selectedMenu === 'barometer' ? ' active ' : ''}`}>{t("Barometer")}</Link>
                            : <a className="menu-item text-white-50 disabled" >{t("Barometer")}</a>
                    }
                    {
                        company?.supports_avatar ?
                            <Link to="/values" className={`menu-item ${selectedMenu === 'values' ? ' active ' : ''}`}>{t("Values")}</Link>
                            : <a className="menu-item text-white-50 disabled" >{t("Values")}</a>
                    }
                </nav>
            </div>
        </div>
    );
}

export default withRouter(Header);