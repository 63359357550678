import LoginModal from 'components/Auth/LoginModal';
import ContactPage from 'components/Home/ContactPage';
import Loader from 'components/Loader/Loader';
import { ProfileContext } from 'context/profile';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { isUserLoggedIn } from 'utils/utils';
import centered_on_social from '../../assets/centered-on-social-interactions.svg';
import down_arrow from '../../assets/down-arrow.png';
import logo from '../../assets/header.svg';
import illustration from '../../assets/illustration.svg';
import match_with_friends from '../../assets/match-with-friends.svg';
import photo_1 from '../../assets/photo-1.png';
import photo_2 from '../../assets/photo-2.png';
import profile from '../../assets/profile.svg';
import take_test from '../../assets/take-the-test.svg';
import used_by_hr from '../../assets/used-by-hr.svg';
import recruiters from '../../assets/recruiters.svg';
import open_opportunities from '../../assets/open-opportunities.svg'
import MultiLang from '../MultiLang/MultiLang';
import './Pro.css';

function Pro(props) {

    const { t } = useTranslation();

    const [email, setEmail] = useState()
    const [passed, setPassed] = useState(false)
    const [className_1, setClassName_1] = useState('paragraph-bigger-1')
    const [className_2, setClassName_2] = useState('paragraph-bigger-2')
    const [read_more_1, setReadMore_1] = useState('read-more-1')
    const [read_more_2, setReadMore_2] = useState('read-more-2')
    let params = new URLSearchParams(window.location.search)

    const moreClick_1 = (e) => {
        className_1 === 'paragraph-bigger-1' ? setClassName_1('paragraph-bigger-1-more') : setClassName_1('paragraph-bigger-1')
        setReadMore_1('read-more-hiden')
    }

    const profileContext = useContext(ProfileContext);

    const checkIsB2B = (profile) => {
        // const profile = getProfile();
        console.log('isB2B Called 123', profile)
        if (profile) {
            return !!(profile.companies && profile.companies.length);
        } else {
            return false;
        }
    }

    useEffect(() => {
        console.log('profile context in pro', profileContext.profile)
    }, [profileContext])

    if (email) {
        if (profileContext.profile) {
            if (checkIsB2B(profileContext.profile)) {
                return <Redirect to="/recruitments" />
            } else {
                return (
                    <Redirect to="results" />
                )
            }
        } else {
            return (
                <div className='mt-5'>
                    <Loader loading={true} />
                </div>
            )
        }
    } else {
        return (
            <div className='pro'>
                <div className="header">
                    <div className='container header-block'>
                        <img src={logo} alt="work well together" className="logo" />
                        <div className='d-flex'>
                            <div className='pro-login-btn'>
                                {isUserLoggedIn() ?
                                    <Link to='/results'>
                                        <div className='wwt-btn wwt-btn-pink font-montserrat mx-auto'>
                                            <img src={profile} alt="profile icon" className="profile-icon" />
                                            {t("My account")}
                                        </div>
                                    </Link> :
                                    <LoginModal
                                        setEmail={setEmail}
                                        setPassed={setPassed}
                                        signUpEnabled={false}
                                        btnText={t("Log in")}
                                    />
                                }
                            </div>
                            <div className='d-none d-md-block'>
                                {/* <CompanyProfileModal /> */}
                                <Link className='d-none d-sm-block' to='/'>
                                    <div className="wwt-btn bg-warning myprofile font-montserrat">
                                        {t("Not a company?")}
                                    </div>
                                </Link>
                            </div>
                            <MultiLang />
                        </div>
                    </div>
                </div>
                <div className="main-page position-relative" id="main-page">
                    <div className='container position-relative'>
                        <div className='row align-items-center justify-content-between'>
                            <div className='text-white text-left'>
                                <div>
                                    <h1 className='text-pre-wrap mb-5'>Work Well Together</h1>
                                </div>
                            </div>
                            <div className='col-3 row justify-content-between d-none d-md-block'>
                                <img src={illustration} alt='lady svg' height='100%' width='100%' />
                            </div>
                        </div>
                        <div className='text-white mt-4 message text-left'>
                            {t("We help people understand each other better. ")}
                        </div>
                        <div className='text-white mt-4 message text-left'>
                            {t("How can you save time by enhancing the performance of current and future employees, have more effective teams, and maintain a caring atmosphere? We have the solution. ")}
                        </div>
                    </div>
                </div>
                <div className="second-page" id="second-page">
                    <div className='container'>
                        <div className="how-it-works">{t("How we help you")}</div>
                        <div className='row align-items-baseline'>
                            <div className="step step-01 col-12 col-md-6 col-lg-4 mb-sm-4">
                                <img src={match_with_friends} className="step-img" alt="take test"></img>
                                <div className="step-content first-content d-flex flex-column">
                                    <div className="step-title">{t("Prevent conflicts")}</div>
                                    <div className="step-description">
                                        {t("Anticipate high-risk relationships (dysfunctional, conflictive, toxic), reduce communication problems, and assist you in resolving conflicts")}
                                    </div>
                                </div>
                            </div>
                            <div className="line-home d-block d-sm-none"></div>
                            <div className="step step-02  col-12 col-md-6 col-lg-4 mb-sm-4">
                                <img src={used_by_hr} className="step-img" alt="learn about"></img>
                                <div className="step-content second-content">
                                    <div className="step-title">{t("Retain talents")}</div>
                                    <div className="step-description"> {/* discover-your-domina */}
                                        {t("Ensure harmonious working relationships to develop engagement")}
                                    </div>
                                </div>
                            </div>
                            <div className="line-home d-block d-sm-none"></div>
                            <div className="step step-03  col-12 col-md-6 col-lg-4 mb-sm-4">
                                <img src={recruiters} className="step-img" alt="match with friends"></img>
                                <div className="step-content third-content">
                                    <div className="step-title">{t("Ensure a high level of performance")}</div>
                                    <div className="step-description">
                                        {t("Identify levers to improve cohesion, collective performance and well-being at work")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login-button">
                        {/* <LoginModal setPassed={setPassed} setEmail={setEmail} /> */}
                        {isUserLoggedIn() ?
                            <Link to='/results'>
                                <div className='wwt-btn wwt-btn-pink font-montserrat mx-auto'>
                                    <img src={profile} alt="profile icon" className="profile-icon" />
                                    {t("My account")}
                                </div>
                            </Link> :
                            <LoginModal
                                setEmail={setEmail}
                                setPassed={setPassed}
                                signUpEnabled={false}
                                btnText={t("Log in")}
                            />
                        }
                    </div>
                </div>
                <div className="second-page" id="second-page">
                    <div className='container'>
                        <div className="how-it-works">{t("Why Work Well Together is powerful")}</div>
                        <div className='row align-items-baseline'>
                            <div className="step step-01 col-12 col-md-6 col-lg-4 mb-sm-4">
                                <img src={take_test} className="step-img" alt="take test"></img>
                                <div className="step-content first-content d-flex flex-column">
                                    <div className="step-title">{t("Generates KPI based on 15 years of R&D")}</div>
                                    <div className="step-description">
                                        {t("Collective performance indicators, reports and operational action plans, analysis on interpersonal relationships, indicators about the compatibility of talents and candidates with the company’s values, an innovative and unique way to measure social climate")}
                                    </div>
                                </div>
                            </div>
                            <div className="line-home d-block d-sm-none"></div>
                            <div className="step step-02  col-12 col-md-6 col-lg-4 mb-sm-4">
                                <img src={open_opportunities} className="step-img" alt="learn about"></img>
                                <div className="step-content second-content">
                                    <div className="step-title">{t("Tested and approved")}</div>
                                    <div className="step-description"> {/* discover-your-domina */}
                                        {t("On thousands of persons, more than 20 nationalities, from student to senior, from any background and socio-professional status")}
                                    </div>
                                </div>
                            </div>
                            <div className="line-home d-block d-sm-none"></div>
                            <div className="step step-03  col-12 col-md-6 col-lg-4 mb-sm-4">
                                <img src={centered_on_social} className="step-img" alt="match with friends"></img>
                                <div className="step-content third-content">
                                    <div className="step-title">{t("They trust us")}</div>
                                    <div className="step-description">
                                        {/* {t("Work Well Together allows us to ensure optimal relationships inside our teams. For us, it is a key tool, that we rely on within our quality of worklife actions” said Marina Brugier, HR development Manager from Innothera")} */}
                                        <i>"{t("Work Well Together allows us to ensure optimal relationships inside our teams. For us, it is a key tool, that we rely on within our quality of worklife actions")}"</i><br />
                                        <span className='font-weight-bold'>{t("Marina Brugier, HR development Manager from Innothera")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login-button">
                        {/* <LoginModal setPassed={setPassed} setEmail={setEmail} /> */}
                        {isUserLoggedIn() ?
                            <Link to='/results'>
                                <div className='wwt-btn wwt-btn-pink font-montserrat mx-auto'>
                                    <img src={profile} alt="profile icon" className="profile-icon" />
                                    {t("My account")}
                                </div>
                            </Link> :
                            <LoginModal
                                setEmail={setEmail}
                                setPassed={setPassed}
                                signUpEnabled={false}
                                btnText={t("Log in")}
                            />
                        }
                    </div>
                </div>
                <div className="third-page" id="third-page">
                    <div className='container'>
                        <div className='row d-flex justify-content-around'>
                            <div className="group col-12">
                                <div className={className_1}>
                                    {t("Work Well Together (WWT) is a revolutionary initiative dedicated to improving emotional relationships in the workplace. Developed by an expert clinical psychologist in emotions, WWT uses advanced data analysis to explore and understand the unique relational dynamics within professional environments. ")}
                                    {t("Our mission is to create strong and fulfilling connections in the workplace, improve employees’ emotional and mental well-being, and facilitate meaningful interactions. Guided by the belief that ‘Everything depends on the relationship’, WWT emphasizes the essence of human interactions in professional environments. Whether it’s strengthening a team, enhancing cohesion within a company, or optimizing relationships among colleagues, WWT is the key to more cohesive and productive work environments, where everyone finds their place and feels understood and appreciated. ")}
                                    {t("Thanks to our full-service solution, WWT Relational Solutions, we offer comprehensive support, from the initial questionnaire to personalized coaching. ")}
                                    {t("Derived from over 15 years of research and experimentation, WWT aims to radically transform workplace well-being. As the driving force behind the Be Well Together (BWT) initiative, WWT revolutionizes how we approach professional relationships. ")}
                                    {t("Join us to discover how WWT can transform your professional relationships and guide you toward a future where well-being aligns with performance. ")}
                                </div>
                                <div className={read_more_1}>
                                    <div className="button-content" onClick={moreClick_1}>
                                        <img src={down_arrow} alt="down-arrow" className="down-arrow" />
                                &nbsp;   {t("More")}    &nbsp;
                                <img src={down_arrow} alt="down-arrow" className="down-arrow" />
                                        <div className="read-more-line" />
                                    </div>
                                </div>
                        </div>
                        {/* <LoginModal setEmail={props.setEmail} setPassed={props.setPassed} /> */}
                        {isUserLoggedIn() ?
                            <Link to='/results'>
                                <div className='wwt-btn wwt-btn-pink font-montserrat mx-auto'>
                                    <img src={profile} alt="profile icon" className="profile-icon" />
                                    {t("My account")}
                                </div>
                            </Link> :
                            <LoginModal
                                setEmail={props.setEmail}
                                setPassed={props.setPassed}
                            />
                        }
                    </div>
                        {/* <LoginModal setEmail={setEmail} setPassed={setPassed} /> */}
                        {isUserLoggedIn() ?
                            <Link to='/results'>
                                <div className='wwt-btn wwt-btn-pink font-montserrat mx-auto'>
                                    <img src={profile} alt="profile icon" className="profile-icon" />
                                    {t("My account")}
                                </div>
                            </Link> :
                            <LoginModal
                                setEmail={setEmail}
                                setPassed={setPassed}
                                signUpEnabled={false}
                                btnText={t("Log in")}
                            />
                        }
                    </div>
                </div>
                <ContactPage />
                {
                    params.has('login') &&
                    <LoginModal
                        setEmail={setEmail}
                        setPassed={setPassed}
                        pageNum={1}
                        showLoginDefault
                    />
                }
            </div>
        )
    }
}

export default Pro;