import Graph from 'components/Results/SubPages/Graph/Graph';
import * as htmlToImage from 'html-to-image';
import { MDBIcon, MDBModal, MDBModalBody } from 'mdbreact';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { getLanguage } from 'utils/utils';
import logo from '../../../../assets/header.svg';
import './QuotationTable.css';

function QuotationTable({ data, onToggle }) {

    const [result, setResult] = useState(null);
    const [graphA, setGraphA] = useState(null);
    const [graphB, setGraphB] = useState(null);

    const { t } = useTranslation();
    const lang = getLanguage();

    useEffect(() => {
        if (data) {
            setResult(data.result);
        }
    }, [data])

    useEffect(() => {
        if (result) {
            setGraphA({
                'W': result['w_1'],
                'X': result['x_1'],
                'Y': result['y_1'],
                'Z': result['z_1']
            })
            setGraphB({
                'W': result['w_2'],
                'X': result['x_2'],
                'Y': result['y_2'],
                'Z': result['z_2']
            })
        }
    }, [result])

    const toggle = () => {
        if (onToggle) {
            onToggle();
        }
    }

    const showGraph = () => {
        if (graphA && graphB) {
            return <Graph graphA={graphA} graphB={graphB} parentId='chartBlock' />;
        } else {
            return null;
        }
    }

    const printTable = () => {
        const html = document.getElementById('quotationContainer');
        const btn = document.getElementById('actions');
        if (btn) {
            btn.style.visibility = 'hidden';
        }
        htmlToImage.toPng(html, { pixelRatio: 1 })
            .then(function (dataUrl) {
                var a = document.createElement("a"); //Create <a>
                a.href = dataUrl; //Image Base64 Goes here
                a.download = `${data?.first_name} ${data?.last_name} - Quotation Table.png`; //File name Here
                a.click(); //Downloaded file
                a.remove();
                if (btn) {
                    btn.style.visibility = 'visible';
                }
                toast.success(t('Quotation table downloaded successfully'));
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                if (btn) {
                    btn.style.visibility = 'visible';
                }
                toast.error('Failed to download quotation table.');
            });
    }

    return (
        <MDBModal isOpen={!!result} toggle={() => { toggle() }} size='xl' className='quotation-table-modal'>
            <MDBModalBody>
                <div id='quotationContainer' className='white relative'>
                    <div className='p-4'>
                        <img src={logo} alt="work well together" className="company-logo" />
                    </div>
                    <div className='align-items-center d-flex flex-column my-4'>
                        <div className='main-heading'>
                            {t('Relational Profile')} <br />
                            {data?.first_name} {data?.last_name}
                        </div>
                    </div>
                    <div id='chartBlock'>
                        <div>
                            {
                                showGraph()
                            }
                        </div>
                    </div>
                    <div className='align-items-center d-flex flex-column my-4'>
                        <div className='header'><span className='title1'>{t("On a daily basis")}</span> &nbsp;/&nbsp; <span className='title2'>{t("Under tension")}</span></div>
                        <table className='quotation-table'>
                            <tr>
                                <th>{t("Mode")}</th>
                                <th>{t("PAG")}</th>
                                <th>{t("VAG")}</th>
                                <th>{t("AOP")}</th>
                                <th>{t("POP")}</th>
                                <th>{t("REG")}</th>
                                <th>{t("SAD")}</th>
                                <th>{t("AVO")}</th>
                                <th>{t("ESC")}</th>
                                <th>{t("FOR")}</th>
                                <th>{t("SDE")}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th colSpan={4}>{t("Protest")}</th>
                                <th></th>
                                <th></th>
                                <th colSpan={4}>{t("Protection")}</th>
                            </tr>
                            <tr>
                                <th>{t("On a daily basis")}</th>
                                <td>{result?.agp1_raw}</td>
                                <td>{result?.agv1_raw}</td>
                                <td>{result?.opa1_raw}</td>
                                <td>{result?.opp1_raw}</td>
                                <td>{result?.reg1_raw}</td>
                                <td>{result?.tri1_raw}</td>
                                <td>{result?.evi1_raw}</td>
                                <td>{result?.fui1_raw}</td>
                                <td>{result?.fea1_raw}</td>
                                <td>{result?.ade1_raw}</td>
                            </tr>
                            <tr>
                                <th>{t("Under tension")}</th>
                                <td>{result?.agp2_raw}</td>
                                <td>{result?.agv2_raw}</td>
                                <td>{result?.opa2_raw}</td>
                                <td>{result?.opp2_raw}</td>
                                <td>{result?.reg2_raw}</td>
                                <td>{result?.tri2_raw}</td>
                                <td>{result?.evi2_raw}</td>
                                <td>{result?.fui2_raw}</td>
                                <td>{result?.fea2_raw}</td>
                                <td>{result?.ade2_raw}</td>
                            </tr>
                        </table>
                    </div>
                    <div className='align-items-center d-flex flex-column my-4'>
                        <div className='header'>{t("Stakes balance")}</div>
                        <table className='quotation-table'>
                            <tr>
                                <th>{t("Action")}</th>
                                <th>{t("Vision")}</th>
                                <th>{t("Intention")}</th>
                            </tr>
                            <tr>
                                <td>{(result && result.r_percent >= 0) ? (result.r_percent * 100).toFixed(0) + '%' : null}</td>
                                <td>{(result && result.i_percent >= 0) ? (result.i_percent * 100).toFixed(0) + '%' : null}</td>
                                <td>{(result && result.s_percent >= 0) ? (result.s_percent * 100).toFixed(0) + '%' : null}</td>
                            </tr>
                        </table>
                    </div>
                    <div className='d-flex justify-content-end m-4 mt-5'>
                        <span>Date: {moment(result?.date).locale(lang).format('DD MMM YYYY')} </span>
                    </div>
                    <div id='actions' className='d-flex'>
                        <button id='printBtn' className='btn wwt-btn flat wwt-btn-blueli' onClick={() => printTable()}>
                            <i className="fa fa-print" aria-hidden="true"></i>
                            <span className='ml-2'>{t("Print")}</span>
                        </button>
                        <button className='action-btn' onClick={toggle}>
                            <MDBIcon icon="times" className='icon' />
                        </button>
                    </div>
                </div>
            </MDBModalBody>
        </MDBModal>

    );
}

export default QuotationTable;