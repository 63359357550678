import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getLanguage } from 'utils/utils';
import './QuestionBlock.css';

const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function QuestionBlock({ question: q, onNext, answerCount = 3, questionNo, progress = 0, isDemographicQuestion = false }) {
    const { t } = useTranslation();
    const lang = getLanguage();
    const [question, setQuestion] = useState(null);

    useEffect(() => {
        if (!q.answers) {
            q.answers = [];
        }
        setQuestion(q);
    }, [q])

    const selectAnswer = (answer_id) => {
        if (!question.answers) {
            question.answers = [];
        }
        if (answerCount > 1) {
            const index = question.answers.findIndex((aId) => aId === answer_id);
            if (index >= 0) {
                question.answers = question.answers.slice(0, index);
            } else {
                if (question.answers.length < answerCount) {
                    question.answers.push(answer_id);
                }
            }
        } else {
            question.answers = [];
            question.answers.push(answer_id);
        }
        setQuestion({ ...question });
    }

    return (
        <div className='question-block'>
            {question &&
                <>
                    <div className="question-page">
                        <div className='wwt-container'>
                            <div className="quiz-top mb-2">
                                <div className="quiz-num tc-primary">
                                    {t("Question")} {questionNo}
                                </div>
                            </div>
                            <div className="question">
                                {question[lang] || question.en}
                            </div>
                            <div className="answers">
                                {question.options.length > 0 && (
                                    <div className="answer-list">
                                        {question.options
                                            .map((answer, item) => {
                                                return (
                                                    <div key={`answer_${item}`} className={`answer ${question?.answers?.includes(answer.answer_id) ? 'selected' : ''}`}>
                                                        <button
                                                            style={{ outline: "none" }}
                                                            className="select-answer"
                                                            onClick={() => selectAnswer(answer.answer_id)}
                                                        >
                                                            {str[item]}
                                                        </button>
                                                        <div className="answer-desc">
                                                            {lang === "en"
                                                                ? answer.en
                                                                : lang === "fr"
                                                                    ? answer.fr
                                                                    : lang === "ru"
                                                                        ? answer.ru
                                                                        : ""}
                                                        </div>
                                                        {
                                                            question?.answers?.includes(answer.answer_id) && (answerCount > 1) &&
                                                            <span className="oval">
                                                                {question.answers.findIndex(answer_id => answer_id === answer.answer_id) + 1}
                                                            </span>
                                                        }
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-group">
                        <div className="question-line" />
                        <div className='wwt-container'>
                            <div className="row-1">
                                <div id="explain" className="explain">
                                    {isDemographicQuestion ? t('Select your answer') : t("Select the answers that best describe what you would do")}
                                </div>
                                <button
                                    style={{ outline: "none" }}
                                    className="next-question-btn"
                                    onClick={() => onNext(question)}
                                    disabled={question?.answers?.length !== answerCount}
                                >
                                    {t("Next")}
                                </button>
                                {/* <Link to="/select">
                            <button
                                style={{ outline: "none" }}
                                id="to-word"
                                className="to-word"
                                onClick={clickNext}
                            >
                                {t("Next")}
                            </button>
                        </Link> */}
                            </div>
                            <div className="bottom-buttons">
                                {
                                    Array.from(Array(answerCount).keys()).map((index) => {
                                        return (
                                            <div className="d-flex" key={index}>
                                                {
                                                    (question?.answers && question?.answers[index]) ?
                                                        <>
                                                            <button
                                                                style={{ outline: "none" }}
                                                                className="bottom-button"
                                                                onClick={() => selectAnswer(question?.answers[index])}
                                                            >
                                                                {str[question.options?.map((a) => a.answer_id).findIndex((a) => a === question?.answers[index])]}
                                                            </button>
                                                            {(answerCount > 1) &&
                                                                <span id="notification-1" className="notifications">
                                                                    {index + 1}
                                                                </span>
                                                            }
                                                        </>
                                                        :
                                                        <button
                                                            style={{ outline: "none" }}
                                                            className="bottom-button"
                                                        >
                                                            {index + 1}
                                                        </button>
                                                }
                                                <div className="choice">
                                                    {isDemographicQuestion ? t('Answer selected') : `${t('Choice')} ${index + 1}`}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div id="progressBarFrame">
                                <div id="progressBar" style={{ width: `${progress || 0}%` }}></div>
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    )
}

export default QuestionBlock;
