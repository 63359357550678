import PersonIcon from 'components/common/Icons/PersonIcon';
import { MDBIcon, MDBTooltip } from 'mdbreact';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CompleteProfileBtn({ user }) {
    const { t } = useTranslation();
    return (
        <>
            {(user && !user.confidential && user.complete_profile && (user.complete_profile.en || user.complete_profile.fr)) ?
                <Dropdown
                    trigger={['click']}
                    overlay={
                        <Menu className='p-2 pr-4' selectable={false}>
                            {
                                user.complete_profile.en &&
                                <MenuItem key='pdf_en'>
                                    <a href={user.complete_profile.en} target='_blank' rel="noopener noreferrer">
                                        <button className='action-btn'>
                                            <MDBIcon icon="download" className='icon' />
                                            <span className='text'>{t("English")}</span>
                                        </button>
                                    </a>
                                </MenuItem>
                            }
                            {
                                user.complete_profile.fr &&
                                <MenuItem key='pdf_fr'>
                                    <a href={user.complete_profile.fr} target='_blank' rel="noopener noreferrer">
                                        <button className='action-btn'>
                                            <MDBIcon icon="download" className='icon' />
                                            <span className='text'>{t("French")}</span>
                                        </button>
                                    </a>
                                </MenuItem>
                            }
                        </Menu>
                    }
                    animation="slide-up"
                    placement="bottomRight"
                >
                    <div>
                        <MDBTooltip domElement tag="span" placement="top">
                            <button className='action-btn'>
                                <PersonIcon className='icon' />
                            </button>
                            <span>{t("Profile Report")}</span>
                        </MDBTooltip>
                    </div>
                </Dropdown> :
                <MDBTooltip domElement tag="span" placement="top">
                    <button className='action-btn disabled'>
                        <PersonIcon className='icon' />
                    </button>
                    <span>{t("Profile Report")}</span>
                </MDBTooltip>
            }
        </>
    );
}

export default CompleteProfileBtn;