import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const options = {
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: 'en',
    supportedLngs: ['en','fr'],
    cleanCode: true,
    debug: false,
    keySeparator: false,
    load: 'languageOnly',
    backend: {
      loadPath: `/locales/{{lng}}/translation.json`
    },
    interpolation: {
      escapeValue: false,
    }
  })

export default i18n;

i18n.changeLanguage();
document.documentElement.lang = i18n.language;