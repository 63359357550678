import React from 'react'

const Polygon = (points) => {
    let pos = points.points.join()
    let style = points.style
    let dash = points.dash
    return (
        <polygon 
            points = {pos}
            style={style}
            strokeDasharray={dash}
        />
    )
}
export default Polygon;