import ContactPage from 'components/Home/ContactPage';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/WWT icone blue.svg';
import MultiLang from '../MultiLang/MultiLang';
import './PrivacyPolicy.css';
import { Link } from 'react-router-dom';
import { getLanguage } from 'utils/utils';

function PrivacyPolicy(props) {

    const { t } = useTranslation();
    var lang = getLanguage();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='pro'>
            <div className="">
                <header>
                    <nav className="navbar navbar-expand-sm navbar-light bg-white shadow-none">
                        <div className='container d-flex align-items-center'>
                            <Link to='/'>
                                <img src={logo} alt="work well together" width='44px' height='44px' className='mr-3' />
                            </Link>
                            <MultiLang />
                        </div>
                    </nav>
                </header>
            </div>
            <div className='bg-light body'>
                <div className='container'>
                    <div className='py-5'>
                        <p className='privacypolicy text-left'>
                            <div className="help-modal-subtitle">{t("GENERAL TERMS AND CONDITIONS")}</div>
                            {t("You acknowledge that by registering")}
                            <div className="help-modal-subtitle">{t("1. LEGAL INFORMATION")}</div>
                            {t("The Work Well Together platform and the Services are operated by Work Well Together SAS")}
                            <div className="help-modal-subtitle">{t("Hosting")}</div>
                            {t("www.workwelltogether.co website is hosted")}
                            <div className="help-modal-subtitle">{t("2. PREAMBLE")}</div>
                            {t("The Work Well Together platforms www.workwelltogether.co with its sub-domains")}

                            {t("The purpose of these general terms and conditions is to define the terms and conditions of the implementation of the services")}
                            <div className="help-modal-subtitle">{t("3. DEFINITIONS")}</div>
                            {t("The terms defined below shall have the following meanings between the parties")}
                            <div className="help-modal-subtitle">{t("4. SUBJECT")}</div>
                            {t("The purpose of this document is to define the conditions of access, the terms of use of the services")}
                            <div className="help-modal-subtitle">{t("5. CONTRACTUAL DOCUMENTS")}</div>
                            {t("The contractual documents that are binding for the Clients are")}
                            <div className="help-modal-subtitle">{t("6. OPPOSABILITY")}</div>
                            {t("Customers may only benefit from the services offered to them on the Platform")}
                            <div className="help-modal-subtitle">{t("7. ACCESS TO SERVICES")}</div>
                            {t("The Services are intended exclusively for Customers, understood as any natural or legal person, who wish to use Work Well Together solutions. ")}
                            <div className="help-modal-subtitle">{t("8. REGISTRATION LOGIN/PASSWORD")}</div>
                            {t("The user is solely responsible for the preservation and confidentiality of the password and/or login")}
                            <div className="help-modal-subtitle">{t("9. STRICTLY PERSONAL USE")}</div>
                            {t("The user can access his Personal Space at any time after having identified himself using his login and password.")}
                            <div className="help-modal-subtitle">{t("10. SUSPENSION AND CLOSURE OF THE USER ACCOUNT")}</div>
                            {t("In case of suspicion of fraudulent use of the User's account, the Company reserves the right,")}
                            <div className="help-modal-subtitle">{t("11. DESCRIPTION OF SERVICES")}</div>
                            {t("The user has access to the following Services,")}
                            <div className="help-modal-subtitle">{t("11.1 Client registration")}</div>
                            {t("The Company offers Clients to answer the Platform's Projective Questionnaire")}
                            <div className="help-modal-subtitle">{t("11.2 Establishment of deliverables")}</div>
                            {t("Based on the responses provided by the Client, the Platform prepares summary reports")}
                            <div className="help-modal-subtitle">{t("11.3 Delivery of deliverables")}</div>
                            {t("Deliverables can only be prepared and delivered to the Customer after the Customer has fully completed the Questionnaire.")}
                            <div className="help-modal-subtitle">{t("11.4 Other services")}</div>
                            {t("The Company reserves the right to offer any other Service that it deems useful,")}
                            <div className="help-modal-subtitle">{t("12. FINANCIAL CONDITIONS")}</div>
                            {t("The Customer subscribes to a subscription allowing him to benefit during the period of the said subscription from the totality of the functionalities. ")}
                            <div className="help-modal-subtitle">{t("12.1 Cost of the subscription")}</div>
                            {t("The price of the subscription is indicated on the site,")}
                            <div className="help-modal-subtitle">{t("12.2 Terms of payments")}</div>
                            {t("For subscriptions, payment of the subscription price is made by bank transfer,")}
                            <div className="help-modal-subtitle">{t("12.3 Duration of the subscription")}</div>
                            {t("There is no commitment period. The Customer is free to terminate the Platform's services at its own convenience with one (1) month notice.")}
                            <div className="help-modal-subtitle">{t("12.4 Common provisions")}</div>
                            {t("The Company reserves the right, at its sole discretion and on terms and conditions that it shall be its sole judge")}
                            <div className="help-modal-subtitle">{t("12.5 Late payments and payment incidents")}</div>
                            {t("The Client is informed and expressly accepts that any delay in the payment of all or part of a sum due on its due date")}
                            <div className="help-modal-subtitle">{t("13. DURATION OF THE SERVICES")}</div>
                            {t("The subscription is taken out for an indefinite period.")}
                            <div className="help-modal-subtitle">{t("14. UNSUBSCRIPTION")}</div>
                            {t("Users may unsubscribe from the Platform at any time, directly from their personal space or by sending a request")}
                            <div className="help-modal-subtitle">{t("15. USERS' OBLIGATIONS AND GUARANTEES")}</div>
                            {t("Without prejudice to the other obligations set forth herein, the user agrees to comply with the following obligations.")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.1 The user undertakes,")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.2 The user acknowledges")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.3 However, the Company reserves")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.4 The user undertakes to make strictly personal use")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.5 The user undertakes not to obscure the Company's trademark")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.6 The User undertakes not to modify or distort")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.7 The User agrees not to reproduce and use the framework,")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.8 The user undertakes to provide the Company with all the information necessar")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.9 The user undertakes to keep confidential,")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.10 Moreover, the Customer is solely responsible for the relations")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.11 The Customer acknowledges that the Services offer an additional,")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.12 The user must take the necessary measures to safeguard")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.13 The user is informed and accepts that the implementation of the Services requires that he be connected")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.14 The User indemnifies the Company against any and all complaints,")}
                            <div className="help-modal-subtitle"></div>
                            {t("15.15 The User undertakes to indemnify the Company for any prejudice")}
                            <div className="help-modal-subtitle">{t("16. LIABILITY AND GUARANTEE OF THE COMPANY")}</div>
                            <div className="help-modal-subtitle"></div>
                            {t("16.1 The Company undertakes to provide the Services diligently and in accordance")}
                            <div className="help-modal-subtitle"></div>
                            {t("16.2 The Questionnaire and the deliverables proposed by the Company are subject to constant improvement")}
                            <div className="help-modal-subtitle"></div>
                            {t("16.3 The Company is, moreover, in no way responsible for exchanges or relations between users")}
                            <div className="help-modal-subtitle"></div>
                            {t("16.4 The Company undertakes to keep confidential the information")}
                            <div className="help-modal-subtitle"></div>
                            {t("16.5 The Company declines all responsibility in the event of any loss of the information accessible")}
                            <div className="help-modal-subtitle"></div>
                            {t("16.6 The Company undertakes to carry out regular checks to verify the operation and accessibility of the Platform")}
                            <div className="help-modal-subtitle"></div>
                            {t("16.7 The Company does not guarantee to users (i) that the Services, which are subject to constant research to improve their performance")}
                            <div className="help-modal-subtitle">{t("17. PROHIBITED BEHAVIORS")}</div>
                            <div className="help-modal-subtitle"></div>
                            {t("17.1 The following are strictly prohibited")}:{t(" (i) any behavior likely to interrupt,")}
                            <div className="help-modal-subtitle"></div>
                            {t("17.2 In the event of failure to comply with any of the provisions of this article or")}
                            <div className="help-modal-subtitle"></div>
                            {t("17.3 The User undertakes to use the Services and the data extracted from them in perfect good faith")}
                            <div className="help-modal-subtitle">{t("18. SANCTIONS FOR BREACHES")}</div>
                            {t("In case of breach of any of the provisions of these terms and conditions or, more generally, infringement of the laws")}
                            <div className="help-modal-subtitle">{t("19. INTELLECTUAL PROPERTY OF THE COMPANY")}</div>
                            {t("The present general conditions do not entail any transfer of any kind of intellectual property rights")}
                            <div className="help-modal-subtitle">{t("20. RESPONSIBILITY")}</div>
                            {t("The Company declines all responsibility for any interruptions or bugs in the Platform.")}
                            <div className="help-modal-subtitle">{t("21. SECURITY")}</div>
                            {t("The Company makes its best efforts, in accordance with the rules of the art, to secure the Platform")}
                            <div className="help-modal-subtitle">{t("22. PERSONAL DATA")}</div>
                            {t("The Company has a personal data protection policy,")}
                            {
                                (lang === 'fr') ?
                                    <div>
                                        <p className='mt-2'>Les données utilisateurs stockées et utilisées sont :</p>
                                        <p className='m-0'>- Nom </p>
                                        <p className='m-0'>- Prénom </p>
                                        <p className='m-0'>- Email </p>
                                        <p className='m-0'>- Identifiant des questions posées </p>
                                        <p className='m-0'>- Réponse 1 de chaque question </p>
                                        <p className='m-0'>- Réponse 2 de chaque question </p>
                                        <p>- Mots sélectionnés et triés </p>
                                        <p>Les données utilisateurs permettent aux algorithmes Play Well Together de générer le profil relationnel et les résultats de matching relationnel.</p>
                                        <p>L’utilisateur peut demander la suppression de ses données personnelles en contactant notre service support: <a href='mailto:contact@workwelltogether.co'>contact@workwelltogether.co</a></p>
                                    </div>
                                    :
                                    <div>
                                        <p className='mt-2'>The users data we use and store are: </p>
                                        <p className='m-0'>- Last Name </p>
                                        <p className='m-0'>- First Name </p>
                                        <p className='m-0'>- email address </p>
                                        <p className='m-0'>- Question id</p>
                                        <p className='m-0'>- answer 1 for each question</p>
                                        <p className='m-0'>- answer 2 for each question </p>
                                        <p>- Choice of words and sort order</p>
                                        <p>The users data allow us to generate the user's relational profile and their relational matchings with other users, thanks to our algorithms.</p>
                                        <p>The user can request the deletion of their personal data by contacting our support team: <a href='mailto:contact@workwelltogether.co'>contact@workwelltogether.co</a></p>
                                    </div>
                            }
                            <div className="help-modal-subtitle">{t("23. TRACEABILITY")}</div>
                            {t("In order to provide an easily accessible and personalized service, the Company keeps a history of the Customers' connections")}
                            <div className="help-modal-subtitle">{t("24. ADVERTISING")}</div>
                            {t("The Company reserves the right to insert on the Platform and in any communication to users any advertising")}
                            <div className="help-modal-subtitle">{t("25. LINKS AND THIRD PARTY SITES")}</div>
                            {t("The Company may in no event be held liable for the technical availability of websites")}
                            <div className="help-modal-subtitle">{t("26. FORCE MAJEURE")}</div>
                            {t("Initially, cases of force majeure will suspend the execution of the general conditions of sale.")}
                            <div className="help-modal-subtitle">{t("27. CONVENTION OF PROOF")}</div>
                            {t("Acceptance of the General Terms and Conditions by electronic means shall have the same evidential value")}
                            <div className="help-modal-subtitle">{t("28. CONFIDENTIALITY")}</div>
                            {t("The information transmitted or collected by the Company during the use of the Services is by nature considered confidential")}
                            <div className="help-modal-subtitle">{t("29. COMMERCIAL REFERENCES")}</div>
                            {t("The Client expressly authorizes the Company to cite it and to use,")}
                            <div className="help-modal-subtitle">{t("30. GENERAL PROVISIONS")}</div>
                            {t("The parties agree to perform their obligations in good faith.")}
                            <div className="help-modal-subtitle">{t("31. LANGUAGES")}</div>
                            {t("In the event of a translation of these general terms and conditions, the Questionnaire, the deliverables and more generally the Platform")}
                            <div className="help-modal-subtitle">{t("32. APPLICABLE LAW AND JURISDICTION")}</div>
                            {t("The present general conditions are governed by French law.")}
                        </p>
                    </div>

                </div>
            </div>
            <ContactPage />
        </div>
    );
}

export default PrivacyPolicy;