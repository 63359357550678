import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import './ProgressBar.css';

function ProgressBar({ value, text, styles, classes }) {
    styles = styles || {};
    return (
        <CircularProgressbar className={`progress-value ${classes ? classes : ''}`}
            value={(value || 0).toFixed('2')} text={text.toFixed('0') + '%'} strokeWidth={8}
            styles={buildStyles({
                textColor: '#908D95', textSize: '28px', pathColor: '#D755A2', ...styles
            })} />
    );
}

export default ProgressBar;