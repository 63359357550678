import { MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ConfirmationDialog.css';

function ConfirmationDialog({ title, message, yesText, noText, onYesAction, onNoAction, onToggle, isOpen, ...props }) {

    const { t } = useTranslation();

    const [modal, setModal] = useState(!!isOpen);

    const toggle = () => {
        const modalState = modal;
        setModal(!modalState);
        if (onToggle && typeof onToggle === 'function') {
            onToggle(!modalState);
        }
    }

    const onYesClick = () => {
        if (onYesAction && typeof onYesAction === 'function') {
            onYesAction();
        }
        toggle();
    }

    const onNoClick = () => {
        if (onNoAction && typeof onNoAction === 'function') {
            onNoAction();
        }
        toggle();
    }

    return (
        <>
            {
                props.children ?
                    <div onClick={toggle}>
                        {props.children}
                    </div>
                    : null
            }
            <MDBModal isOpen={modal} toggle={toggle} className="confirmation-modal">
                {
                    title ?
                        <MDBModalHeader>
                            {title}
                        </MDBModalHeader>
                        : null
                }
                <MDBModalBody>
                    <div dangerouslySetInnerHTML={{ __html: message }}>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <div className='d-flex justify-content-end'>
                        <button className='btn wwt-btn flat btn-no' onClick={onNoClick}>{t(noText || 'No')}</button>
                        <button className='btn wwt-btn flat wwt-btn-blueli btn-yes' onClick={onYesClick}>{t(yesText || 'Yes')}</button>
                    </div>
                </MDBModalFooter>
            </MDBModal>
            <div></div>
        </>
    );
}

export default ConfirmationDialog;