import server from '@config';
import axios from 'axios';
import MatchingIcon from 'components/common/Icons/MatchingIcon';
import Table from 'components/common/Table/Table';
import { MDBIcon, MDBModal, MDBModalBody, MDBTooltip } from 'mdbreact';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { getLanguage, truncate } from 'utils/utils';
import ProgressBar from '../../../../common/ProgressBar/ProgressBar';
import './AllMatchings.css';

function AllMatchings(props) {

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [dataForGrid, setDataForGrid] = useState([]);
    const [filteredDataForGrid, setFilteredDataForGrid] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const { t } = useTranslation();
    const lang = getLanguage();

    useEffect(() => {
        setModal(true);
    }, [])

    useEffect(() => {
        updateDataForGrid();
    }, [props.mission, props.matchings]);

    useEffect(() => {
        setFilteredDataForGrid(dataForGrid);
    }, [dataForGrid]);

    useEffect(() => {
        filterData();
    }, [searchText])

    const updateDataForGrid = () => {
        const mission = props.mission ? { ...props.mission } : null;
        const matchings = props.matchings ? [...props.matchings] : [];
        const contactsByUserId = {};
        if (mission) {
            if (mission.mission_manager.user_id) {
                contactsByUserId[mission.mission_manager.user_id] = mission.mission_manager;
            }
            if (mission.mission_benchmarks) {
                mission.mission_benchmarks.forEach((benchmark) => {
                    if (benchmark.contact.user_id) {
                        contactsByUserId[benchmark.contact.user_id] = benchmark.contact;
                    }
                })
            }
        }
        matchings.forEach((matching) => {
            matching.user_1_contact = contactsByUserId[matching.user_1] || {};
            matching.user_2_contact = contactsByUserId[matching.user_2] || {};
        });
        setDataForGrid(matchings);
    }

    const filterData = () => {
        let data = [];
        if (searchText) {
            const text = '';
            data = dataForGrid.filter((entry) => {
                let bool = false;
                if (entry.user_1_contact.first_name.toLowerCase().startsWith(searchText.toLowerCase())) {
                    bool = true;
                } else if (entry.user_1_contact.last_name.toLowerCase().startsWith(searchText.toLowerCase())) {
                    bool = true;
                } else if (entry.user_2_contact.first_name.toLowerCase().startsWith(searchText.toLowerCase())) {
                    bool = true;
                } else if (entry.user_2_contact.last_name.toLowerCase().startsWith(searchText.toLowerCase())) {
                    bool = true;
                }
                return bool;
            });
        } else {
            data = dataForGrid.slice();
        }
        setFilteredDataForGrid(data);
    }

    const toggle = () => {
        if (props && props.toggle) {
            props.toggle();
        }
    }

    const MatchingPdfBtn = (user_1, user_2) => {
        return (
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu className='p-2 pr-4' selectable={false}>
                        <MenuItem key='pdf_en'>
                            <button className='action-btn' onClick={() => getMatchingProfilePdf(user_1, user_2, 'en')}>
                                <MDBIcon icon="download" className='icon' />
                                <span className='text'>{t("English")}</span>
                            </button>
                        </MenuItem>
                        <MenuItem key='pdf_fr'>
                            <button className='action-btn' onClick={() => getMatchingProfilePdf(user_1, user_2, 'fr')}>
                                <MDBIcon icon="download" className='icon' />
                                <span className='text'>{t("French")}</span>
                            </button>
                        </MenuItem>
                    </Menu>
                }
                animation="slide-up"
                placement="bottomRight"
            >
                <div>
                    <MDBTooltip domElement tag="span" placement="top">
                        <button className='action-btn'>
                            <MatchingIcon className='icon' />
                        </button>
                        <span>{t("Matching Report")}</span>
                    </MDBTooltip>
                </div>
            </Dropdown>
        )
    }

    const getMatchingProfilePdf = (contact_1, contact_2, language) => {
        setLoading(true);

        const data = { contact_1, contact_2 };

        let config = {
            method: 'post',
            url: server + '/company/matching_result_pdf/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log('response', res);
                setLoading(false);
                switch (language) {
                    case 'en':
                        if (res.data.matching_pdf_en) {
                            window.open(res.data.matching_pdf_en, '_blank');
                        }
                        break;

                    case 'fr':
                        if (res.data.matching_pdf_fr) {
                            window.open(res.data.matching_pdf_fr, '_blank');
                        }
                        break;

                    default:
                        break;
                }
            })
            .catch(err => {
                console.log('error in matching', err)
                toast.error('Failed to retrieve matching results.')
                setLoading(false);
            })
    }

    return (
        <MDBModal isOpen={modal} toggle={toggle} size='xl' className='all-matchings-modal'>
            {
                props &&
                <MDBModalBody>
                    <div className='modal-header d-flex justify-content-between align-items-center'>
                        <div className='mx-2'>{t("All the matchings of")} {props.mission.mission_manager.first_name} {props.mission.mission_manager.last_name}
                            {
                                {
                                    'en': "'s team"
                                }[lang]
                            }</div>
                        <div className='d-flex'>
                            <input class='search-input' value={searchText} placeholder={`${t('Search')}...`} onChange={(event) => setSearchText(event.target.value)} />
                            <div className='d-flex ml-3 mission-details-action-btns'>
                                <button className='action-btn' onClick={toggle}>
                                    <MDBIcon icon="times" className='icon' />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='overflow-auto'>
                        <Table className='missions'
                            columns={[
                                [
                                    { key: 'user_1_contact.last_name', title: 'Last Name' },
                                    { key: 'user_1_contact.first_name', title: 'First Name' },
                                    { key: 'rui_smooth', title: 'RI', tooltip: 'Relational Index', sortable: true },
                                    { title: '' },
                                    { key: 'user_2_contact.last_name', title: 'Last Name' },
                                    { key: 'user_2_contact.first_name', title: 'First Name' },
                                ]
                            ]}
                            data={filteredDataForGrid}
                            rowComponent={
                                (matching, index) => (
                                    <tr key={index}>
                                        <td className='last-name-1'>
                                            <MDBTooltip domElement tag="span" placement="top">
                                                <span>{truncate(matching.user_1_contact.last_name, 15)}</span>
                                                <span>{matching.user_1_contact.last_name}</span>
                                            </MDBTooltip>
                                        </td>
                                        <td>
                                            <MDBTooltip domElement tag="span" placement="top">
                                                <span>{truncate(matching.user_1_contact.first_name, 15)}</span>
                                                <span>{matching.user_1_contact.first_name}</span>
                                            </MDBTooltip>
                                        </td>
                                        <td>
                                            <ProgressBar value={matching.rui_smooth * 100} text={matching.rui_smooth * 100} />
                                        </td>
                                        <td>
                                            <div className='d-flex'>
                                                {MatchingPdfBtn(matching.user_1_contact.id, matching.user_2_contact.id)}
                                            </div>
                                        </td>
                                        <td className='last-name-2'>
                                            <MDBTooltip domElement tag="span" placement="top">
                                                <span>{truncate(matching.user_2_contact.last_name, 15)}</span>
                                                <span>{matching.user_2_contact.last_name}</span>
                                            </MDBTooltip>
                                        </td>
                                        <td>
                                            <MDBTooltip domElement tag="span" placement="top">
                                                <span>{truncate(matching.user_2_contact.first_name, 15)}</span>
                                                <span>{matching.user_2_contact.first_name}</span>
                                            </MDBTooltip>
                                        </td>
                                    </tr>
                                )
                            }
                        />
                        {
                            (dataForGrid?.length && !filteredDataForGrid?.length) ?
                                <div class='grey-text p-3'>No data available for applied filter.</div>
                                : null
                        }
                    </div>
                </MDBModalBody>

            }
        </MDBModal >

    );
}

export default AllMatchings;