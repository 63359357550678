import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './BottomMenu.css'

import alert from '../../assets/alert.png'
import search from '../../assets/search.png'
import contacts from '../../assets/contacts.png'
import profile from '../../assets/profile_1.png'
import { ReactComponent as ProfileIcon } from '../../assets/profile-icon.svg'
import { ReactComponent as ContactsIcon } from '../../assets/contacts-icons.svg'
import { ReactComponent as HomeIcon } from '../../assets/home.svg'
import pink_contacts from '../../assets/pink-contacts.png'
import pink_profile from '../../assets/pink-profile.png'
import { ReactComponent as NotifIcon } from '../../assets/notif-icon.svg'


function BottomMenu(props) {

    let item1, item2
    if (props.id === 'results') {
        item1 = pink_profile
        item2 = contacts
    } else {
        item1 = profile
        item2 = pink_contacts
    }

    const [contactIcon, setContactIcon] = useState(item2)
    const [profileIcon, setProfileIcon] = useState(item1)

    const toContacts = () => {
        if (contactIcon === contacts) {
            setContactIcon(pink_contacts)
        } else {
            if (props.id === 'results') {
                setContactIcon(contacts)
            }
        }
    }

    const toResults = () => {
        if (profileIcon === profile) {
            setProfileIcon(pink_profile)
        } else {
            if (props.id !== 'results') {
                setProfileIcon(profile)
            }
        }
    }

    return (
        <div className="bottom-menu">
            <div className='menu-line' />
            <div className="menu-items">
                <Link to="/results">
                    {/* <img src={profileIcon} height='30px' width='30px' onClick={toResults} alt="results" /> */}
                    <ProfileIcon className={`profile ${(props.id === 'results') ? 'selected' : ''}`} />
                </Link>
                <Link to="/contacts">
                    {/* <img src={contactIcon} height='30px' width='30px' onClick={toContacts} alt="contacts" /> */}
                    <ContactsIcon className={`contacts ${(props.id === 'contacts') ? 'selected' : ''}`} />
                </Link>
                <Link to="/">
                    <HomeIcon className={`home ${(props.id === 'home') ? 'selected' : ''}`} />
                </Link>
                {/* <img src={search} height='30px' width='30px' alt="search icon" /> */}
                <NotifIcon className="notification-icon" />
                {/* <img src={alert} height='30px' width='30px' alt="alert icon" /> */}
            </div>
        </div>
    )
}
export default BottomMenu