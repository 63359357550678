import React, {Component} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import icon from '@assets/dragdrop.png';
import { getLanguage } from 'utils/utils'


const ImgStyle = {
  marginRight: '23px',
  marginTop: '-2px',
  width: '17px',
  height: '17px',
  cursor: 'pointer',
}

const SortableItem = SortableElement(({value}) => {
  const lang = getLanguage();
  return (
      <div className='sort-item' tabIndex={0}>
        <img style={ImgStyle} src={icon} alt="icon" />
        {
          {
            'en': value.en,
            'fr': value.fr,
            'ru': value.ru
          }[lang]
        }
      </div>
    )
});

const SortableList = SortableContainer(({items}) => {
  return (
    <ul>
      {
        items.map((value, index) => (
        <SortableItem key={`${value.word_id}`} index={index} value={value} />
      ))}
    </ul>
  );
});

class SortList extends Component {

  state = {
    items: []
  }

  componentDidMount(){
    this.setState({
      items: this.props.words
    })

  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    this.props.setResult(this.state.items)
  };

  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
  }
}

export default SortList
