import server from '@config'
import { useAuth } from '@context/auth'
import { getIndividualResult, getProfile } from 'api/api'
import { ProfileContext } from 'context/profile'
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import LinkedIn from 'react-linkedin-login-oauth2'
import { withRouter } from 'react-router'
import { gaEvent } from 'utils/utils'
import './LoginModal.css'

function LinkedinButton(props) {
  const [errorMessage, setErrorMessage] = useState()
  const { setAuthTokens } = useAuth()
  const { t } = useTranslation();
  let params = new URLSearchParams(window.location.search)
  let ref = params.get('ref')
  const mission = params.get('mission')
  const contact = params.get('contact')
  const company = params.get('company')

  const profileContext = useContext(ProfileContext);

  useEffect(() => {
    if (profileContext.profile) {
      getIndividualResult();
    }
  }, [profileContext])

  const handleSuccess = (data) => {
    // setAuthTokens(data.code)
    props.setModal(true)
    const body = {
      site: "linkedin",
      code: data.code,
      referral: ref,
    }
    if (mission) {
      body.mission = mission;
    }
    if (contact) {
      body.contact = contact;
    }
    if (company) {
      body.company = company;
    }
    if (props.location.pathname.includes('/pro')) {
      body.is_b2b = true;
    } else {
      body.is_b2b = false;
    }

    fetch(server + '/auth/user', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(data => {
        console.log('linkedin login', data)
        if (data) {
          if (data.status === 0) {
            toast.error(data.message);
          } else {
            localStorage.setItem('email', data.email)
            localStorage.setItem('referral', data.referral)
            localStorage.setItem('token', data.token)
            setAuthTokens(data.token)
            let data1 = { 'email': data.email }
            gaEvent("CONNECTION", "CONNECTION_LINKEDIN", "Connected with LinkedIn");
            getProfile().then((profile) => {
              profileContext.setProfile();
              if (profile) {
                profileContext.setProfile();
                getIndividualResult().then((res) => {
                  if (res.data.w_1 !== -1) {
                    props.setPassed(true)
                  }
                  props.setEmail(localStorage.getItem('email'))
                })
              }
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
        toast.error("Login with LinkedIn failed")
      })
  }

  const handleFailure = (err) => {
    setErrorMessage(err.errorMessage)
    console.log(errorMessage)
    toast.error("Login with LinkedIn failed")
  }

  const url = window.location.origin + "/home";
  console.log(url)

  return (
    <div className='linkedin-btn-container'>
      <LinkedIn
        clientId="7766ia9vsfbyxb"
        // scope='r_liteprofile r_emailaddress'
        scope="r_liteprofile r_emailaddress w_member_social"
        redirectUri={url}
        onFailure={handleFailure}
        onSuccess={handleSuccess}
        styleClass='btn-linkedin'
        icon="fa-linkedin"
      >
        <i className="fa fa-linkedin" />
        {t("Connect with Linkedin")}
      </LinkedIn>
    </div>
  );
}

export default withRouter(LinkedinButton);
