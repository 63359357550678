export const ContactTypes = {
    Candidate: {
        name: 'Candidate',
        value: 'C'
    },
    Employee: {
        name: 'Employee',
        value: 'E'
    }
}

export const BenchmarkTypes = {
    M: 'Manager',
    E: 'Employee',
    employee: 'Employee',
    manager: 'Manager'
}

export const getContactType = (type, field = 'name') => {
    const inputType = type ? type.toLowerCase() : '';
    const contactTypes = {
        candidate: {
            name: 'Candidate',
            value: 'C'
        },
        employee: {
            name: 'Employee',
            value: 'E'
        },
        manager: {
            name: 'Manager',
            value: 'M'
        },
        c: {
            name: 'Candidate',
            value: 'C'
        },
        e: {
            name: 'Employee',
            value: 'E'
        }
    }
    return contactTypes[inputType] ? contactTypes[inputType][field] : type;
}

export const ValueFieldDetails = {
    cooperation: {
        key: 'cooperation',
        title: 'Cooperation',
    },
    benevolence: {
        key: 'benevolence',
        title: 'Benevolence',
    },
    exemplariness: {
        key: 'exemplariness',
        title: 'Exemplary',
    },
    engagement: {
        key: 'engagement',
        title: 'Engagement',
    },
    efficiency: {
        key: 'efficiency',
        title: 'Efficiency',
    }
}