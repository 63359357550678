import server from '@config'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { getIndividualResult } from 'api/api'
import axios from 'axios'
import 'bootstrap-css-only/css/bootstrap.min.css'
import Barometer from 'components/Admin/Barometer/Barometer'
import CompanyContacts from 'components/Admin/CompanyContacts/CompanyContacts'
import Mobilities from 'components/Admin/Mobilities/Mobilities'
import MobilityForm from 'components/Admin/Mobilities/MobilityForm/MobilityForm'
import Onboardings from 'components/Admin/Onboarding/Onboarding'
import OnboardingForm from 'components/Admin/Onboarding/OnboardingForm/OnboardingForm'
import RecruitmentForm from 'components/Admin/Recruitments/RecruitmentForm/RecruitmentForm'
import Recruitments from 'components/Admin/Recruitments/Recruitments'
import TeamBuilding from 'components/Admin/TeamBuilding/TeamBuilding'
import TeamBuildingForm from 'components/Admin/TeamBuilding/TeamBuildingForm/TeamBuildingForm'
import Values from 'components/Admin/Values/Values'
import ValuesForm from 'components/Admin/Values/ValuesForm/ValuesForm'
import BarometerQuestionnaire from 'components/BarometerQuestionnaire/BarometerQuestionnaire'
import PrivacyPolicy from 'components/PrivacyPolicy/PrivacyPolicy'
import Pro from 'components/Pro/Pro'
import ResetPassword from 'components/ResetPassword/ResetPassword'
import { ProfileContext } from 'context/profile'
import { MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact'
import 'mdbreact/dist/css/mdb.css'
import PrivateRoute from 'PrivateRoute'
import React, { Suspense, useEffect, useState } from 'react'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import ReactGA from 'react-ga'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Analytics from 'react-router-ga'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { gaEvent, getProfile, isUserLoggedIn, logOut } from 'utils/utils'
import './App.css'
import ComparePage from './components/Compare/ComparePage'
import ContactPage from './components/Contacts/ContactPage'
import HomePage from './components/Home/HomePage'
// import PrivateRoute from './PrivateRoute'
import QuestionPage from './components/Question/QuestionPage'
import Results from './components/Results/Results'
import SelectPage from './components/Select/SelectPage'
import SortPage from './components/Sort/SortPage'
import { AuthContext } from './context/auth'
import './i18n'
import './Interceptor'



function App(props) {

  let existingTokens;
  if (localStorage.getItem('token')) {
    try {
      existingTokens = localStorage.getItem("token");
    } catch (e) {
      console.log('error in existing token', e);
    }
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [profile, setProfile] = useState(null);
  const [redirectToChoice, setRedirectToChoice] = useState(null);
  const [changeUserConfirmModal, setChangeUserConfirmModal] = useState(false);

  const { i18n, t } = useTranslation();

  const setTokens = (data) => {
    if (data) {
      // localStorage.setItem("tokens", JSON.stringify(data));
      localStorage.setItem("token", data);
    }
    setAuthTokens(data);
  }

  ReactGA.initialize(process.env.REACT_APP_GA_UA_ID);

  useEffect(() => {
    const ref = new URLSearchParams(window.location.search).get('ref')
    if (ref && isUserLoggedIn()) {
      gaEvent("MATCHING", "MATCHING_ACKNOWLEDGED", "Matching Acknowledged");
      let config = {
        method: 'post',
        url: server + '/auth/user/add_matching',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          referral: ref
        }
      }

      axios(config)
        .then(res => {
          console.log('res', res);
          if (res.data && res.data.status === 1) {
            window.location.replace('/contacts');
            // window.location.push = 'contacts';
          }
        }).catch((err) => {
          console.log('err', err);
        })
    }
  }, []);

  useEffect(() => {
    const questionnaireId = new URLSearchParams(window.location.search).get('questionnaire_id')
    if (isUserLoggedIn()) { // && questionnaireId
      getIndividualResult()
        .then((data) => {
          console.log('result data', data)
          if (data && data.data && data.data.w_1 === -1) {
            setRedirectToChoice(true);
          } else {
            setRedirectToChoice(false);
          }
        })
    }
  }, [])

  useEffect(() => {
    setUserProfile();
  }, [])

  useEffect(() => {
    if (!authTokens) {
      setRedirectToChoice(null);
    }
  }, [authTokens])

  const setUserProfile = () => {
    if (isUserLoggedIn() && !localStorage.getItem('profile')) {
      getUserProfile();
    } else {
      setProfile(getProfile());
    }
  }

  useEffect(() => {
    // sessionStorage.removeItem('mission');
    // sessionStorage.removeItem('contact');
    // sessionStorage.removeItem('questionnaire');

    const mission = new URLSearchParams(window.location.search).get('mission')
    const contact = new URLSearchParams(window.location.search).get('contact')
    const questionnaire = new URLSearchParams(window.location.search).get('questionnaire_id')

    if (mission || contact || questionnaire) {
      // change language to fr if user comes with invitation link
      i18n.changeLanguage('fr');
      document.documentElement.lang = i18n.language;
    }

    if (mission) {
      sessionStorage.setItem('mission', mission);
    }
    if (contact) {
      sessionStorage.setItem('contact', contact);
    }
    if (questionnaire) {
      sessionStorage.setItem('questionnaire', questionnaire);
    }
  }, [])

  useEffect(() => {
    // Map contact only if route is not barometerquetionnaire route
    if (!window.location.href.includes('barometerquestionnaire')) {
      const mission = new URLSearchParams(window.location.search).get('mission')
      const contact = new URLSearchParams(window.location.search).get('contact')
      const company = new URLSearchParams(window.location.search).get('company')
      if ((mission || contact || company) && isUserLoggedIn()) {
        const data = {};
        if (mission) {
          data.mission = mission;
        }
        if (contact) {
          data.contact = contact;
        }
        if (company) {
          data.company = company;
        }

        let config = {
          method: 'post',
          url: server + '/auth/user/map_company_contact/',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
          .then(res => {
            if (company) {
              toast.success(t('Contact successfully added.'));
            }
            if (contact) {
              toast.success(t('Contact successfully added.'));
            }
            if (mission) {
              toast.success(t('Contact successfully added.'));
            }
            window.history.replaceState(null, 'WWT', '/');
          })
          .catch(err => {
            if (company) {
              toast.error(t('Failed to add to company contact.'));
            }
            if (contact) {
              toast.error(t('Failed to add to company contact.'));
            }
            if (mission) {
              toast.error(t('Failed to add to company mission.'));
            }
            window.history.replaceState(null, 'WWT', '/');
          })
      }
    }
  }, [])

  useEffect(() => {
    if (profile) {
      getIndividualResult()
        .then((data) => {
          console.log('result data', data)
          if (data && data.data && data.data.w_1 === -1) {
            setRedirectToChoice(true);
          } else {
            setRedirectToChoice(false);
          }
        })
    }
  }, [profile])

  useEffect(() => {
     // get token, email and referral from url params
    const token = new URLSearchParams(window.location.search).get('token')
    const email = new URLSearchParams(window.location.search).get('email')
    const referral = new URLSearchParams(window.location.search).get('referral')

    if (token && email && referral) {

      // if token is already available then it is already logged in with a user
      if (localStorage.getItem('token')) {

        // if email from params and localstorage is not same, means we want to imporsanate with another user
        // Show popup for confirmation to logout from current user and login with another user
        if (localStorage.getItem('email') !== email) {
          setChangeUserConfirmModal(true);
        } else {
          window.location.replace('/');
        }
      } else {
        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
        localStorage.setItem('referral', referral);
        window.location.replace('/');
      }
    }
  }, [])

  const changeUser = () => {

    // logout from current user
    logOut();

    // get token, email and referral from url params
    const token = new URLSearchParams(window.location.search).get('token')
    const email = new URLSearchParams(window.location.search).get('email')
    const referral = new URLSearchParams(window.location.search).get('referral')

    // update fields into local storage
    localStorage.setItem('token', token);
    localStorage.setItem('email', email);
    localStorage.setItem('referral', referral);

    // refresh screen with base url to continue it as logged in with new user
    window.location.replace('/');
  }

  const getUserProfile = () => {
    let data = JSON.stringify({ "email": localStorage.getItem("email") });
    ReactGA.set({ userId: localStorage.getItem("referral") });

    let config = {
      method: 'post',
      url: server + '/result/profile/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(res => {
        if (res.data['result']) {
          localStorage.setItem('profile', JSON.stringify(res.data['result']));
          setProfile(res.data['result']);
        }
      })
      .catch(err => {
      })
  }

  const isB2B = () => {
    const profile = getProfile();
    console.log('isB2B Called', profile)
    if (profile) {
      return !!(profile.companies && profile.companies.length);
    } else {
      return false;
    }
  }

  const Loader = () => (
    <div>Loading...</div>
  )

  const baseRoute = () => {
    if (isB2B() && !(new URLSearchParams(window.location.search).get('token') || new URLSearchParams(window.location.search).get('ref'))) {
      return (
        <Route exact path='/' render={
          (props) => <Redirect to="/recruitments" />
        } />
      )
    } else {
      if (redirectToChoice) {
        return (
          <Route exact path='/' render={
            (props) => <Redirect to='/choice' />
          } />
        );
      } else {
        return (
          <Route exact path='/' render={
            (props) => <HomePage />
          } />
        )
      }
    }
  }

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <ProfileContext.Provider value={{ profile, setProfile: setUserProfile }}>
        <div className="App">
          <Toaster position='bottom-center' />
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <Analytics id={process.env.REACT_APP_GA_UA_ID}>
                <Switch>
                  {
                    baseRoute()
                  }
                  <Route path="/home" component={LinkedInPopUp} />
                  <PrivateRoute path="/choice" component={QuestionPage} />
                  <PrivateRoute path="/select" component={SelectPage} />
                  <PrivateRoute path="/sort" component={SortPage} />
                  <PrivateRoute path="/matching" component={ComparePage} />
                  <PrivateRoute path="/results" component={Results} />
                  <PrivateRoute path="/contacts" component={ContactPage} />
                  <PrivateRoute path='/recruitments' exact b2bRoute component={Recruitments} />
                  <PrivateRoute path='/recruitments/add' b2bRoute component={RecruitmentForm} />
                  <PrivateRoute path='/recruitments/:missionId/update' b2bRoute component={RecruitmentForm} />
                  <PrivateRoute path='/mobilities' exact b2bRoute component={Mobilities} />
                  <PrivateRoute path='/mobilities/add' b2bRoute component={MobilityForm} />
                  <PrivateRoute path='/mobilities/:missionId/update' b2bRoute component={MobilityForm} />
                  <PrivateRoute path='/onboardings' exact b2bRoute component={Onboardings} />
                  <PrivateRoute path='/onboardings/add' b2bRoute component={OnboardingForm} />
                  <PrivateRoute path='/onboardings/:missionId/update' b2bRoute component={OnboardingForm} />
                  <PrivateRoute path='/team-building' exact b2bRoute component={TeamBuilding} />
                  <PrivateRoute path='/team-building/add' b2bRoute component={TeamBuildingForm} />
                  <PrivateRoute path='/team-building/:missionId/update' b2bRoute component={TeamBuildingForm} />
                  <PrivateRoute path='/company-contacts' exact b2bRoute component={CompanyContacts} />
                  <PrivateRoute path='/barometer' exact component={Barometer} />
                  <PrivateRoute path='/values' exact b2bRoute component={Values} />
                  <PrivateRoute path='/values/add' exact b2bRoute component={ValuesForm} />
                  <PrivateRoute path='/values/:missionId/update' b2bRoute component={ValuesForm} />
                  <Route path='/pro' component={Pro} />
                  <Route path='/privacypolicy' component={PrivacyPolicy} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/barometerquestionnaire/:company" component={BarometerQuestionnaire} />
                </Switch>
              </Analytics>
            </BrowserRouter>
          </Suspense>
          <MDBModal isOpen={changeUserConfirmModal} className="confirmation-modal">
            <MDBModalHeader>
              Change User
            </MDBModalHeader>
            <MDBModalBody>
              <div dangerouslySetInnerHTML={{ __html: `You are already logged in with <span class='text-primary'>${profile?.first_name} ${profile?.last_name}</span> user. <br/>Do you want to logout and login with another user?` }}>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <div className='d-flex justify-content-end'>
                <button className='btn wwt-btn flat btn-no' onClick={() => window.location.replace('/')}>No</button>
                <button className='btn wwt-btn flat wwt-btn-blueli btn-yes' onClick={() => changeUser()}>Yes</button>
              </div>
            </MDBModalFooter>
          </MDBModal>
        </div>
      </ProfileContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
