import { MDBModal, MDBModalBody } from 'mdbreact';
import React from 'react';
import { getLanguage } from 'utils/utils';
import './BarometerQuestionnaireResult.css';

function BarometerQuestionnaireResult({ isOpen, questionnaireResult, toggle }) {
    const lang = getLanguage();
    return (
        <MDBModal isOpen={isOpen} toggle={toggle} size='xl'>
            <MDBModalBody>
                <div className='p-3 text-left'>
                    {
                        questionnaireResult?.map((question, index) => {
                            return (
                                <div key={'questionBlock_' + index} className='p-2'>
                                    <div className='barometer-question'>{index + 1}. {(lang === 'fr') ? question?.fr : question?.en}</div>
                                    {
                                        question?.options?.map((option, oIndex) => {
                                            return (
                                                <div key={'option_' + oIndex} className='d-flex justify-content-between'>
                                                    <div>{(lang === 'fr') ? option?.fr : option?.en}</div>
                                                    <div className='selected-count'>{option?.selected_count}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </MDBModalBody>
        </MDBModal>
    )
}

export default BarometerQuestionnaireResult;