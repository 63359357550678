import i18next from 'i18next';
import moment, { isMoment } from 'moment';
import ReactGA from 'react-ga';

export const isUserLoggedIn = () => {
    return localStorage.getItem('token') ? true : false;
}

export const isB2B = () => {
    const profile = getProfile();
    if (profile) {
        return !!(profile.companies && profile.companies.length);
    } else {
        return false;
    }
}

export const getLanguage = () => {

    var lang = i18next.language;
    if (lang) {
        var dash = lang.indexOf('-');
        if (dash > -1)
            return lang.substring(0, dash);
        else
            return lang;
    } else {
        return 'en';
    }
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const gaEvent = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
    console.log(action);
};

export const formatDate = (date, format) => {
    // console.log('date', date, (new Date(date).format("YYYY")));
    if (format) {
        return moment(date).format(format);
    }
    switch (getLanguage()) {
        case 'fr':
            return moment(date).format('DD/MM/YYYY');

        default:
            return moment(date).format('MM/DD/YYYY');
    }
}

export const getProfile = () => {
    try {
        return JSON.parse(localStorage.getItem('profile'));
    } catch (err) {
        return null;
    }
}

export const logOut = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("referral");
    localStorage.removeItem("tokens");
    localStorage.removeItem("token");
    localStorage.removeItem("partner");
    localStorage.removeItem("profile");
}

export const stopPropagation = (e) => {
    if (e && e.stopPropagation && typeof e.stopPropagation === 'function') {
        e.stopPropagation();
    }
}

export const truncate = (text, characters) => {
    let value = text;
    if (value) {
        if (value.length > characters) {
            value = value.slice(0, characters) + '...';
        }
    }
    return value;
}

export const getProp = (object, path) => {
    if (object === null || object === undefined) return undefined;
    else if (path.length === 1) return object[path[0]];
    else if (path.length === 0) throw Error('path not found.');
    else {
        if (object[path[0]]) return getProp(object[path[0]], path.slice(1));
        else {
            // object[path[0]] = {};
            return getProp(object[path[0]], path.slice(1));
        }
    }
};

export const checkQuotationAccess = () => {
    const profile = getProfile();
    if (profile) {
        if (profile.companies && profile.companies.length) {
            return !!profile.companies[0].quotation_access;
        }
    }
    return false;
}

export const isEvaluationCompleted = (individualResult) => {
    if (individualResult) {
        if (individualResult.w_1 !== -1) {
            return true;
        }
    }
    return false;
}

export const isDateSixMonthsAgo = (inputDate) => {
    // Parse the input date
    const date = new Date(inputDate);
  
    // Get the current date
    const currentDate = new Date();
  
    // Calculate the date six months ago
    const sixMonthsAgo = new Date(currentDate);
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  
    // Compare the input date with six months ago
    return date <= sixMonthsAgo || true;
  }