import React from "react";
import Slide from "./Slides/Slide";
import LoginModal from "../Auth/LoginModal";
import "./HomePage.css";
import "swiper/swiper-bundle.css";

function MainPage(props) {

  return (
    <div className="main-page position-relative" id="main-page">
      <div className='container position-relative'>
        <Slide setEmail={props.setEmail} setPassed={props.setPassed} />
      </div>
    </div>
  );
}

export default MainPage;
