import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Polygon from './polygon'

import './Graph.css'

function Graph(props) {

  const [points_1, setPoints1] = useState([0, 0, 0, 0])
  const [points_2, setPoints2] = useState([0, 0, 0, 0])
  const [size, setSize] = useState(0)
  const { t } = useTranslation();
  // const size = window.innerWidth - 40

  useEffect(() => {
    // let size = window.innerWidth - 90
    const id = props.parentId || 'resultsFirstPage';
    const element = document.getElementById(id);
    let size = (element ? element.offsetWidth : 1024) - 90
    setSize(size)
    setPoints1([10 * props.graphA.Y, 10 * props.graphA.X, 10 * props.graphA.Z, 10 * props.graphA.W])
    setPoints2([10 * props.graphB.Y, 10 * props.graphB.X, 10 * props.graphB.Z, 10 * props.graphB.W])
  }, [props.graphA, props.graphB, props.parentId])

  return (
    <div style={{ height: size, width: size, margin: '0 auto', textAlign: 'center', position: 'relative' }}>
      <div className="graph-top-row">
        <div className="axis-top">{t("Relational well-being")}</div>
      </div>
      <div style={{ height: size - 42, width: size, display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="axis-left">
          <div style={{}}>{t("Relational opposition")}</div>{/* transform: 'rotate(90deg)' */}
        </div>
        <svg height="100%" width="100%" id="graphSvg">
          <line
            x1={`${(size - 42) / 2}`}
            y1={`0`}
            x2={`${(size - 42) / 2}`}
            y2={`${(size - 0)}`} />
          <line
            x1={`0`}
            y1={`${(size - 42) / 2}`}
            x2={`${(size - 0)}`}
            y2={`${(size - 42) / 2}`} />

          {/* <Polygon 
            points={[`${(size-42)/2}`, `${(size-42)/4}`,
                `${(size-42)*3/4}`, `${(size-42)/2}`,
                `${(size-42)/2}`, `${(size-42)*3/4}`,
                `${(size-42)/4}`, `${(size-42)/2}`]}
                dash="3 3"
                style={{fill:"none", stroke:"gray", strokeWidth:"1", opacity:'0.5'}} /> */}
          <Polygon
            points={[`${(size - 42) / 2}`, `${(size - 42) * (10 - points_1[0]) / 20}`,
            `${(size - 42) * (10 + points_1[1]) / 20}`, `${(size - 42) / 2}`,
            `${(size - 42) / 2}`, `${(size - 42) * (10 + points_1[2]) / 20}`,
            `${(size - 42) * (10 - points_1[3]) / 20}`, `${(size - 42) / 2}`]}
            style={{ fill: "#006CB5", stroke: "#006CB5", strokeWidth: "2", opacity: '0.3' }} />
          <Polygon
            points={[`${(size - 42) / 2}`, `${(size - 42) * (10 - points_2[0]) / 20}`,
            `${(size - 42) * (10 + points_2[1]) / 20}`, `${(size - 42) / 2}`,
            `${(size - 42) / 2}`, `${(size - 42) * (10 + points_2[2]) / 20}`,
            `${(size - 42) * (10 - points_2[3]) / 20}`, `${(size - 42) / 2}`]}
            style={{ fill: "#D755A2", stroke: "#D755A2", strokeWidth: "2", opacity: '0.3' }} />
        </svg>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="axis-right">
          <div style={{}}>{t("Relational distance")}</div> {/* transform: 'rotate(-90deg)' */}
        </div>
      </div>
      <div className="graph-top-row">
        <div className="axis-bottom">{t("Relational discomfort")}</div>
      </div>
    </div>
  )
}

export default Graph
