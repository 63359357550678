import React from 'react';
import logo from '../../../assets/header.svg';
import img1 from '../../../assets/Homme cercle vert.svg';
import img2 from '../../../assets/Femme cercle rose.svg';
import take_test from '../../../assets/take-the-test.svg';
import learn_about from '../../../assets/learn-about.svg';
import './BarometerFirstPage.css';

function BarometerFirstPage({ company, startQuestionnaire }) {
    return (
        <>
            <div className="header">
                <div className='container header-block d-flex justify-content-between'>
                    <img src={logo} alt="work well together" className="logo" />
                    {company?.company_logo && <img src={company?.company_logo} alt="work well together" className="company-logo ml-4" />}
                </div>
            </div>
            <div className="main-page position-relative" id="main-page">
                <div className='container position-relative'>
                    <div className='row'>
                        <div className='col-6 text-white'>
                            <h1 className='text-pre-wrap mb-5'>{company?.barometer_text_header1}</h1>
                            <p className='text-pre-wrap'>{company?.barometer_text_header2}</p>
                        </div>
                        <div className='col-6 row justify-content-between'>
                            <img className='col-6' src={img1} alt='lady svg' />
                            <img className='col-6' src={img2} alt='lady svg' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container position-relative'>
                <div className='py-5 px-3'>
                    <div className='barometer-text-intro text-pre-wrap'>
                        {company?.barometer_text_intro}
                    </div>
                    <div className='d-inline-block mt-5'>
                        <p className='font-weight-bold'>Merci de votre participation.</p>
                        <a href="#second-page">
                            <div className='wwt-btn continue-btn font-montserrat mb-4 mb-md-0'>
                                {"Continuer"}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="main-page position-relative" id="second-page">
                <div className='container position-relative text-white'>
                    <h3>Comment compléter le questionnaire ?</h3>
                    <div className='mt-4'>
                        <img src={learn_about} className="step-img bg-white p-2 mx-4" alt="learn about" />
                        <img src={take_test} className="step-img bg-white p-2 mx-4" alt="take test" />
                    </div>
                    <p className='text-pre-wrap'>{`
Une première partie vous permet de répondre à de simples questions d’ordre statistiques (classe d’âge, lieu de travail, etc.). C’est très rapide.

Ensuite, le questionnaire n’est constitué que de 18 questions (environ 20 minutes de passation), pour lesquelles vous devez choisir 2 réponses :

- la première qui correspond à votre préférence,
- la seconde qui correspond à votre deuxième choix.

Il n’y a pas de bonnes ni de mauvaises réponses. Ce qui compte, c’est ce que vous pourriez ressentir a priori dans la situation proposée. Il est possible que parmi les 6 réponses proposées, vous ayez le sentiment qu’aucune ne soit exactement conforme à ce que vous feriez. L’important, c’est d’exprimer une tendance.

Parfois, aucune réponse ne semble être la plus proche, choisissez alors celle qui serait la moins éloignée, même si cela ne vous paraît pas totalement satisfaisant.

Cela fait justement partie du questionnaire.

Bonne passation !
                    `}</p>
                    <div className='d-inline-block mt-2'>
                        <div className='wwt-btn wwt-btn-pink font-montserrat mb-4 mb-md-0' onClick={() => startQuestionnaire()}>
                            {"ALLER AU QUESTIONNAIRE"}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BarometerFirstPage;