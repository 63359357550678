import React from 'react'
import { useTranslation } from 'react-i18next'
import lady_picture from '../../../assets/philo.svg'
import '../Results.css'

const TopPage = () => {

  const { t } = useTranslation();

  return (
    <div className="results-rectangle-1">
        <div className="results-rectangle-1-text">
          {t("Congrats!")} <br /><br />{t("You have completed the questionnaire.")} <br /><br />
          {t("Get a complete relational profile from the person who sent you the invite link ")}
          <br />{t("Find potential relational matches in your professional network via your contact ")}
          {t("The Work Well Together ")}<b>{t("experience continues")}</b>!
          <br />{t("If you have not received an invitation link and are interested in your profile, please contact us at ")}
          <a href='mailto:contact@bewelltogether.co'>contact@bewelltogether.co</a>
        </div>
        <div className="results-rectangle-1-picture">
            <img src={lady_picture} alt="lady" className="results-lady-picture" />
        </div>
    </div>
  )
}

export default TopPage