import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import BottomMenu from '../BottomMenu/BottomMenu'
import ProgressAccordion from './PrgressAccordion/ProgressAccordion'
import Accordion from './Accordion/Accordion'
import 'react-circular-progressbar/dist/styles.css'
import './ComparePage.css'
import back_icon from '../../assets/back.png'
import compatibility_icon from '../../assets/pink-compatibility.png'
import contacts_icon from '../../assets/grey-contacts.png'
// import down_icon from '../../assets/down.png'
import more_icon from '../../assets/more.png'
import profile_icon from '../../assets/profile_1.png'
import server from '@config'
import { Link } from 'react-router-dom'
import { getLanguage } from 'utils/utils'
import { useTranslation } from 'react-i18next'
import UserAvatar from 'react-user-avatar'
import toast from 'react-hot-toast';

function ComparePage() {

  const [name, setName] = useState('')
  const [rate_1, setRate_1] = useState()
  const [rate_2, setRate_2] = useState()
  const [photo_1, setPhoto_1] = useState()
  const [photo_2, setPhoto_2] = useState()
  const [top, setTop] = useState(['', '', ''])
  const [top_fiber, setTopFiber] = useState(['', '', ''])
  const [bottom, setBottom] = useState(['', '', ''])
  const [bottom_fiber, setBottomFiber] = useState(['', '', ''])
  const [softSkills, setSoftSkills] = useState([])
  const [profile, setProfile] = useState({});
  var lang = getLanguage();
  const { t } = useTranslation();


  useEffect(() => {
    let data = { 'email': localStorage.getItem('email'), 'partner': localStorage.getItem('partner') }

    let config = {
      method: 'post',
      url: server + '/result/matching/detail/',
      data: data
    }

    if (localStorage.getItem('partner')) {
      axios(config)
        .then(res => {
          // console.log(Math.ceil(res.data.result["top_fiber_smooth"] * 100), "================")
          let result = res.data.result
          setName(result['first_name'] + ' ' + result['last_name'])
          setRate_1(result['rui_smooth'])
          setRate_2(result['bottom_fiber_smooth'])
          setPhoto_1(result['picture_url_1'])
          setPhoto_2(result['picture_url_2'])
          setTop([result['top_fiber_en'], result['top_fiber_fr'], result['top_fiber_ru']])
          setTopFiber([result['top_fiber_description_en'], result['top_fiber_description_fr'], result['top_fiber_description_ru']])
          setBottom([result['bottom_fiber_en'], result['bottom_fiber_fr'], result['bottom_fiber_ru']])
          setBottomFiber([result['bottom_fiber_description_en'], result['bottom_fiber_description_fr'], result['bottom_fiber_description_ru']])
          setSoftSkills(result.soft_skills)
        })
        .catch(err => {
          console.log(err)
          toast.error("There is an error to get the matching result from the server!!!")
        })
    } else {
      toast.error('No person is selected to match.');
      window.location.replace('/contacts');
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('profile')) {
      setProfile(JSON.parse(localStorage.getItem('profile')));
    }
  }, []);

  return (
    <div className="compare-page">
      <div className="compare-header">
        <div className='wwt-container d-flex position-relative'>
          <Link to='/contacts'>
            <i className="fa fa-arrow-left back-icon" aria-hidden="true"></i>
          </Link>
          <div className="compare-header-text">{name}</div>
          <i className="fa fa-ellipsis-h more-icon" aria-hidden="true"></i>
        </div>
      </div>
      <div className="compare-shadow" />

      <div className='wwt-container'>
        <div className="nav-button">
          <Link to='/results'>
            <div className="nav-profile">
              <img src={profile_icon} className="profile-icon-nav" alt="profile-icon" />
              <div className="profile-nav-text">{t("Profile")}</div>
            </div>
          </Link>
          <div className="nav-compare">
            <img src={compatibility_icon} className="compatibility-icon" alt="compatibility-icon" />
            <div className="compatibility-nav-text">{t("Matching")}</div>
          </div>
          <Link to='/contacts'>
            <div className="nav-contacts">
              <img src={contacts_icon} className="contacts-icon-nav" alt="contacts-icon" />
              <div className="contacts-nav-text">{t("Contacts")}</div>
            </div>
          </Link>
        </div>
      </div>

      <div className='wwt-container'>
        <h4 className='font-weight-bold mt-5 text-left'>{t("Your relational index")}</h4>
        <div className="compare-row-1">
          {/* <ProgressAccordion rate={rate_1} description={top_fiber} code={top} /> */}
          {/* <div className='fiber-title'>{{
            'en': top[0],
            'fr': top[1],
            'ru': top[2]
          }[lang]
          }</div> */}
          <div className="compare-row-2">
            {/* <div className="compare-row-title">{t("Your compatibility")}:</div> */}
            <div className="compare-second-rect">
              {/* <img src={photo_1} alt="inviter" className="photo-3" /> */}
              <UserAvatar size="44" className='photo-3' name={`${profile ? profile.first_name + ' ' + profile.last_name : 'A'}`} src={photo_1} />
              <div className="combine-line" />
              <div style={{ width: '130px' }}>
                <CircularProgressbar
                  value={Math.ceil(rate_1 * 100)}
                  text={Math.ceil(rate_1 * 100) + '%'}
                  strokeWidth={8}
                  styles={buildStyles({
                    textColor: '#D755A2',
                    textSize: '30px',
                    textFamily: 'Montserrat',
                    pathColor: '#D755A2'
                  })}
                />
              </div>
              <div className="combine-line" />
              {/* <img src={photo_2} alt="receipter" className="photo-3" /> */}
              <UserAvatar size="44" className='photo-3' name={`${name || 'A'}`} src={photo_2} />
            </div>
          </div>
          <div className="compare-row-title">{t("The strength")}</div>
          <div className="compare-row-subtitle">{t("of your relationship")}:</div>
          <div className='fiber-title'>{{
            'en': top[0],
            'fr': top[1],
            'ru': top[2]
          }[lang]
          }</div>
          <div className='fiber-description'>
            {
              {
                'en': top_fiber[0],
                'fr': top_fiber[1],
                'ru': top_fiber[2]
              }[lang]
            }
          </div>
        </div>



        <div className="compare-row-3">
          <div className="compare-row-title">{t("The point of attention")}</div>
          <div className="compare-row-subtitle">{t("of your relationship")}:</div>
          {/* <ProgressAccordion rate={rate_2} description={bottom_fiber} code={bottom} /> */}
          <div className='fiber-title'>{{
            'en': bottom[0],
            'fr': bottom[1],
            'ru': bottom[2]
          }[lang]
          }</div>
          <div className='fiber-description'>
            {
              {
                'en': bottom_fiber[0],
                'fr': bottom_fiber[1],
                'ru': bottom_fiber[2]
              }[lang]
            }
          </div>
        </div>

        <div className="compare-row-4">
          <div className="compare-row-title" style={{ marginBottom: '30px' }} >{t("Your common soft skills")}:</div>
          <div style={{ display: 'inline-block' }}>
            {
              softSkills.map((skill, index) => {
                return (
                  <div key={skill.en + index} className='results-softskill-text'>
                    {{
                      'en': skill.en,
                      'fr': skill.fr,
                      'ru': skill.ru
                    }[lang]
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <BottomMenu id='matching' />
    </div>
  )
}

export default ComparePage