import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

function Animation() {
  const [redirect, setRedirect] = useState(false)
  
  useEffect(() => {
    const id = setTimeout(() => setRedirect(true), 5000)
    
    return function cleanup() {
      clearTimeout(id)
    }
  },[redirect])

  return redirect
    ? <Redirect to="/results" />
    : (
    <div></div>
  )
}

export default Animation
