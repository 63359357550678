import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './context/auth';
import { isB2B } from './utils/utils';

function PrivateRoute({ component: Component, b2bRoute, ...rest }) {

  const { authTokens } = useAuth();

  const checkForB2B = () => {
    if (b2bRoute) {
      return isB2B();
    } else {
      return true;
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        authTokens ? (
          checkForB2B() ?
            <Component {...props} />
            : <Redirect to="/pro" />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}

export default PrivateRoute