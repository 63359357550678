import React from 'react';
import './Loader.css';

function Loader(props) {
    return (
        <div className='position-relative'>
            {(!props.replace || !props.loading) &&
                props.children
            }
            {props.loading &&
                <div className='wwt-loader'>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
        </div>
    );
}

export default Loader;