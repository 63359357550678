import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { gen_leftIndex } from "../../helper/gen_perm";
import server from "@config";
import { getLanguage, gaEvent, isB2B } from 'utils/utils'
import MultiLang from '../MultiLang/MultiLang'
import toast from 'react-hot-toast';


import "./QuestionPage.css";

const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function QuestionPage() {

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [quizNum, setQuizNum] = useState(1);
  const [chooseNum, setChooseNum] = useState(0);
  const [left_quizIndex, setLeftIndex] = useState([]);
  const [origin_index, setOriginIndex] = useState(0);
  const [question_answered, setQuestionAnswered] = useState("");
  const [first_answers, setFirstAnswers] = useState("");
  const [second_answers, setSecondAnswers] = useState("");
  const [firstAnswer, setFirstAnswer] = useState(0);
  const [secondAnswer, setSecondAnswer] = useState(0);
  const [number, setNumber] = useState(18);

  const email = localStorage.getItem("email");
  const { t } = useTranslation();
  const first_exp = t(
    "Select the answers that best describe what you would do"
  );
  const second_exp = t("Now select your second best choice");
  const third_exp = t("Click again on your answers if you want to unselect them");
  const lang = getLanguage();

  const getLeftIndex = () => {
    let data = { email: email };

    let config = {
      method: "post",
      url: server + "/result/response/",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        let str1 = res.data["question_answered"];
        setQuestionAnswered(str1);
        setFirstAnswers(res.data["first_answers"]);
        setSecondAnswers(res.data["second_answers"]);
        let li = [];
        if (str1.length > 0) {
          str1.split(",").map((val) => {
            li.push(Number(val));
          });
        }
        li.pop(0);
        setLeftIndex(gen_leftIndex(li));
        setQuizNum(li.length + 1);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to retrieve the questions");
      });
  };

  useEffect(() => {
    getLeftIndex();
    gaEvent("EVALUATION", "EVALUATION_STARTED", "Evaluation started");
    let url = "/response/question/";
    if (sessionStorage.getItem('questionnaire')) {
      url += '?questionnaire_type=' + sessionStorage.getItem('questionnaire');
    } else if (sessionStorage.getItem('mission')) {
      url += '?mission=' + sessionStorage.getItem('mission');
    } else if (sessionStorage.getItem('contact')) {
      url += '?contact=' + sessionStorage.getItem('contact');
    } else if (sessionStorage.getItem('company')) {
      url += '?company=' + sessionStorage.getItem('company');
    }
    axios
      .get(server + url, {
        header: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        setQuestions(res.data);
        setNumber(res.data.length);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to retrieve the questions");
      });

    //request the answer
    let answerUrl = "/response/answer/";
    if (sessionStorage.getItem('questionnaire')) {
      answerUrl += '?questionnaire_type=' + sessionStorage.getItem('questionnaire');
    } else if (sessionStorage.getItem('mission')) {
      answerUrl += '?mission=' + sessionStorage.getItem('mission');
    } else if (sessionStorage.getItem('contact')) {
      answerUrl += '?contact=' + sessionStorage.getItem('contact');
    } else if (sessionStorage.getItem('company')) {
      answerUrl += '?company=' + sessionStorage.getItem('company');
    }
    axios
      .get(server + answerUrl, {
        header: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        setAnswers(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to retrieve the answers");
      });
  }, []);

  const selectAnswer = (e) => {
    const bottom_button_1 = document.getElementById("bottom-button-1");
    const bottom_button_2 = document.getElementById("bottom-button-2");
    switch (chooseNum) {
      case 0:
        e.target.className = "select-answer-active";
        setChooseNum(1);
        setFirstAnswer(str.indexOf(e.target.innerHTML) + 1);
        bottom_button_1.className = "bottom-button-active";
        bottom_button_1.innerHTML = e.target.innerHTML;
        document.getElementById("notification-1").style.display = "block";
        document.getElementById("oval-1-" + e.target.innerHTML).style.display =
          "block";
        document.getElementById("bottom-div-2").style.opacity = 1;
        document.getElementById("explain").innerHTML = second_exp;
        break;
      case 1:
        if (e.target.className === "select-answer-active") {
          setChooseNum(0);
          setFirstAnswer(0);
          e.target.className = "select-answer";
          bottom_button_1.className = "bottom-button";
          bottom_button_1.innerHTML = "1";
          document.getElementById("notification-1").style.display = "none";
          document.getElementById(
            "oval-1-" + e.target.innerHTML
          ).style.display = "none";
          document.getElementById("bottom-div-2").style.opacity = 0.5;
          document.getElementById("explain").innerHTML = first_exp;
        } else {
          setChooseNum(2);
          setSecondAnswer(str.indexOf(e.target.innerHTML) + 1);
          e.target.className = "select-answer-active";
          bottom_button_2.className = "bottom-button-active";
          bottom_button_2.innerHTML = e.target.innerHTML;
          document.getElementById("bottom-div-2").style.opacity = 1;
          document.getElementById("notification-2").style.display = "block";
          document.getElementById(
            "oval-2-" + e.target.innerHTML
          ).style.display = "block";
          document.getElementById("explain").innerHTML = third_exp;
          document.getElementById("next-question").style.opacity = 0.7;
          if (quizNum === number) {
            document.getElementById("to-word").style.display = "block";
            document.getElementById("next-question").style.display = "none";
            document.getElementById("to-word").style.opacity = 0.5;
          }
        }
        break;
      case 2:
        if (e.target.className === "select-answer-active") {
          if (e.target.innerHTML === bottom_button_2.innerHTML) {
            setChooseNum(1);
            setSecondAnswer(0);
            e.target.className = "select-answer";
            document.getElementById(
              "oval-1-" + bottom_button_2.innerHTML
            ).style.display = "block";
            bottom_button_2.innerHTML = "2";
            document.getElementById("explain").innerHTML = second_exp;
            document.getElementById("bottom-div-2").style.opacity = 1;
          } else if (e.target.innerHTML === bottom_button_1.innerHTML) {
            setChooseNum(0);
            setFirstAnswer(0);
            setSecondAnswer(0);
            let label_1 = document.getElementById("bottom-button-1").innerHTML;
            let label_2 = document.getElementById("bottom-button-2").innerHTML;
            document.getElementById("next-question").style.opacity = 0;
            document.getElementById("bottom-div-2").style.opacity = 0.5;
            document.getElementById("bottom-button-1").className =
              "bottom-button";
            document.getElementById("bottom-button-2").className =
              "bottom-button";
            document.getElementById("bottom-button-1").innerHTML = "1";
            document.getElementById("bottom-button-2").innerHTML = "2";
            document.getElementById("notification-1").style.display = "none";
            document.getElementById("select-answer-" + label_1).className =
              "select-answer";
            document.getElementById("select-answer-" + label_2).className =
              "select-answer";
            document.getElementById("oval-1-" + label_1).style.display = "none";
            document.getElementById("oval-2-" + label_2).style.display = "none";
            document.getElementById("explain").innerHTML = first_exp;
          }
          bottom_button_2.className = "bottom-button";
          bottom_button_2.innerHTML = "2";
          document.getElementById("notification-2").style.display = "none";
          document.getElementById(
            "oval-2-" + e.target.innerHTML
          ).style.display = "none";
          document.getElementById(
            "oval-1-" + e.target.innerHTML
          ).style.display = "none";
          document.getElementById("next-question").style.opacity = 0;
        }
        break;
      default:
        break;
    }
  };

  const clickChoice_1 = () => {
    let txt_1 = document.getElementById("bottom-button-1").innerHTML;
    switch (chooseNum) {
      case 0:
        break;
      case 1:
        setChooseNum(0);
        setFirstAnswer(0);
        document.getElementById("bottom-button-1").className = "bottom-button";
        document.getElementById("select-answer-" + txt_1).className =
          "select-answer";
        document.getElementById("oval-1-" + txt_1).style.display = "none";
        document.getElementById("bottom-button-1").innerHTML = "1";
        document.getElementById("notification-1").style.display = "none";
        document.getElementById("bottom-div-2").style.opacity = 0.5;
        document.getElementById("explain").innerHTML = first_exp;
        break;
      case 2:
        let caption_1 = document.getElementById("bottom-button-1").innerHTML;
        let caption_2 = document.getElementById("bottom-button-2").innerHTML;
        setChooseNum(0);
        setFirstAnswer(0);
        setSecondAnswer(0);
        document.getElementById("next-question").style.opacity = 0;
        document.getElementById("bottom-div-2").style.opacity = 0.5;
        document.getElementById("bottom-button-1").className = "bottom-button";
        document.getElementById("bottom-button-2").className = "bottom-button";
        document.getElementById("bottom-button-1").innerHTML = "1";
        document.getElementById("bottom-button-2").innerHTML = "2";
        document.getElementById("notification-1").style.display = "none";
        document.getElementById("notification-2").style.display = "none";
        document.getElementById("select-answer-" + caption_1).className =
          "select-answer";
        document.getElementById("select-answer-" + caption_2).className =
          "select-answer";
        document.getElementById("oval-1-" + caption_1).style.display = "none";
        document.getElementById("oval-2-" + caption_2).style.display = "none";
        document.getElementById("explain").innerHTML = first_exp;
        break;
      default:
        break;
    }
  };

  const clickChoice_2 = () => {
    switch (chooseNum) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        setChooseNum(1);
        setSecondAnswer(0);
        document.getElementById("bottom-button-2").className = "bottom-button";
        let txt = document.getElementById("bottom-button-2").innerHTML;
        document.getElementById("notification-2").style.display = "none";
        document.getElementById("bottom-button-2").innerHTML = "2";
        document.getElementById("select-answer-" + txt).className =
          "select-answer";
        document.getElementById("oval-2-" + txt).style.display = "none";
        document.getElementById("explain").innerHTML = second_exp;
        document.getElementById("next-question").style.opacity = 0;
        break;
      default:
        break;
    }
  };

  const clickNext = () => {
    window.scrollTo(0, 0);
    let label_1 = document.getElementById("bottom-button-1").innerHTML;
    let label_2 = document.getElementById("bottom-button-2").innerHTML;
    if (chooseNum === 2) {
      setQuizNum(quizNum + 1);
      setOriginIndex(origin_index + 1);
      setChooseNum(0);
      setQuestionAnswered(
        question_answered + String(left_quizIndex[origin_index]) + ","
      );
      setFirstAnswers(first_answers + String(firstAnswer) + ",");
      setSecondAnswers(second_answers + String(secondAnswer) + ",");
      document.getElementById("next-question").style.opacity = 0;
      document.getElementById("bottom-div-2").style.opacity = 0.5;
      document.getElementById("bottom-button-1").className = "bottom-button";
      document.getElementById("bottom-button-2").className = "bottom-button";
      document.getElementById("bottom-button-1").innerHTML = "1";
      document.getElementById("bottom-button-2").innerHTML = "2";
      document.getElementById("notification-1").style.display = "none";
      document.getElementById("notification-2").style.display = "none";
      document.getElementById("select-answer-" + label_1).className =
        "select-answer";
      document.getElementById("select-answer-" + label_2).className =
        "select-answer";
      document.getElementById("oval-1-" + label_1).style.display = "none";
      document.getElementById("oval-2-" + label_2).style.display = "none";
      document.getElementById("explain").innerHTML = first_exp;
      document.getElementById("progressBar").style.width =
        (100 * quizNum) / number + "%";

      //////////// Store the question and selected answers to the database for current Questioniare page /////////////////


      let data1 = {
        email: email,
        question_answered: question_answered + String(left_quizIndex[origin_index]) + ",",
        first_answers: first_answers + String(firstAnswer) + ",",
        second_answers: second_answers + String(secondAnswer) + ",",
      };

      let config1 = {
        method: "put",
        url: server + "/result/response/",
        headers: {
          "Content-Type": "application/json",
        },
        data: data1,
      };

      gaEvent("QUESTION", "QUESTION_NUMBER_" + String(origin_index), "Question # " + String(origin_index) + " answered");
      gaEvent("QUESTION", "QUESTION_ID_" + String(left_quizIndex[origin_index]), "Question ID " + String(left_quizIndex[origin_index]) + " answered");

      axios(config1)
        .then((res) => {
          console.log(JSON.stringify(res.data), "Successfully submitted!!!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to submit the responses to the questionnaire");
        });
    }
  };

  const getQuestionIndex = (str) => {
    return Number(str.split("a")[0].replace("q", ""));
  };

  /////////////////// render the page ///////////////
  if (quizNum > number) {
    return <Redirect to="select" />;
  } else {
    return (
      <div>
        <div className="question-page">
          <div className='wwt-container'>
            <div className="quiz-top mb-2">
              <div className="quiz-num tc-primary">
                {t("Question")} {quizNum}
              </div>
              {
                isB2B() &&
                <Link className='d-flex align-items-center ml-3' to='/recruitments'>
                  <div className="wwt-btn bg-warning">
                    {t("Pro Access")}
                  </div>
                </Link>
              }
              <MultiLang />
            </div>
            <div className="question">
              {questions.length > 0 && (
                <div>
                  {questions.map((item, index) => {
                    let question_id = "q" + String(left_quizIndex[origin_index]);
                    if (item.question_id === question_id) {
                      switch (lang) {
                        case "en":
                          return <div key={`question_${index}`}>{item.en}</div>;
                        case "fr":
                          return <div key={`question_${index}`}>{item.fr}</div>;
                        case "ru":
                          return <div key={`question_${index}`}>{item.ru}</div>;
                        default:
                          break;
                      }
                    }
                  })}
                </div>
              )}
            </div>
            <div className="answers">
              {answers.length > 0 && (
                <div className="answer-list">
                  {answers
                    .filter(
                      (answer) =>
                        getQuestionIndex(answer.answer_id) ===
                        left_quizIndex[origin_index]
                    )
                    .map((answer, item) => {
                      return (
                        <div key={`answer_${item}`} className="answer">
                          <button
                            style={{ outline: "none" }}
                            id={"select-answer-" + str[item]}
                            className="select-answer"
                            onClick={selectAnswer}
                          >
                            {str[item]}
                          </button>
                          <div className="answer-desc">
                            {lang === "en"
                              ? answer.en
                              : lang === "fr"
                                ? answer.fr
                                : lang === "ru"
                                  ? answer.ru
                                  : ""}
                          </div>
                          <span id={"oval-1-" + str[item]} className="oval">
                            1
                          </span>
                          <span id={"oval-2-" + str[item]} className="oval">
                            2
                          </span>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bottom-group">
          <div className="question-line" />
          <div className='wwt-container'>
            <div className="row-1">
              <div id="explain" className="explain">
                {t("Select the answers that best describe what you would do")}
              </div>
              <button
                style={{ outline: "none" }}
                id="next-question"
                className="next-question"
                onClick={clickNext}
              >
                {t("Next")}
              </button>
              <Link to="/select">
                <button
                  style={{ outline: "none" }}
                  id="to-word"
                  className="to-word"
                  onClick={clickNext}
                >
                  {t("Next")}
                </button>
              </Link>
            </div>
            <div className="bottom-buttons">
              <div id="bottom-div-1">
                <button
                  style={{ outline: "none" }}
                  id="bottom-button-1"
                  className="bottom-button"
                  onClick={clickChoice_1}
                >
                  1
                </button>
                <div id="choice-1" className="choice">
                  {t("Choice")} 1
                </div>
                <span id="notification-1" className="notifications">
                  1
                </span>
              </div>
              <div id="bottom-div-2">
                <button
                  style={{ outline: "none" }}
                  id="bottom-button-2"
                  className="bottom-button"
                  onClick={clickChoice_2}
                >
                  2
                </button>
                <div id="choice-2" className="choice">
                  {t("Choice")} 2
                </div>
                <span id="notification-2" className="notifications">
                  2
                </span>
              </div>
            </div>
            <div id="progressBarFrame">
              <div id="progressBar"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionPage;
