import React, { useImperativeHandle, useState } from 'react'
import * as htmlToImage from 'html-to-image';
import logo from '../../../assets/header.svg'
import { useTranslation } from 'react-i18next'

import './ResultImage.css'
import Graph from './Graph/Graph';
import { getLanguage } from 'utils/utils';

const ResultImage = React.forwardRef((props, ref) => {
    const [render, setRender] = useState(false);
    var lang = getLanguage();
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
        convertHtmlToImage(cb) {
            setRender(true);
            setTimeout(() => {
                const html = document.getElementById('resultImageContainer');
                console.log('html to img', html);
                htmlToImage.toPng(html, { pixelRatio: 1 })
                    .then(function (dataUrl) {
                        // var img = new Image();
                        // img.src = dataUrl;
                        // document.body.appendChild(img);
                        // console.log('data', dataUrl);
                        cb(dataUrl);
                        setRender(false);
                    })
                    .catch(function (error) {
                        console.error('oops, something went wrong!', error);
                    });
            }, 300);

        }
    }));

    return (
        <div id='imageRef'>
            {render &&
                <div id='resultImageContainer' className='result-image-container'>
                    {props.profile &&
                        <div className='title-container'>
                            <span className='title-icon'>{props.profile.first_name && props.profile.first_name[0]}</span>
                            <span>{props.profile.first_name} {props.profile.last_name}</span>
                        </div>
                    }
                    <div className='result-body-container'>
                        <div id='chartBlock' className='chart-block'>
                            <div className='chart-title'>{t("Your relational profile")}</div>
                            <Graph graphA={props.graphA} graphB={props.graphB} parentId='chartBlock' />
                            <div style={{ marginTop: '48px', padding: "0 30px", textAlign: "left" }}>
                                <div className="graph-explain-content">{t("Your behaviour and relations")}</div>
                                <div style={{ display: "flex" }}>
                                    <div className="graph-oval-1" />
                                    <div className="graph-oval-content">{t("On a day to day basis")}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div className="graph-oval-2" />
                                    <div className="graph-oval-content">{t("Under stress")}/{t("tension")}</div>
                                </div>
                            </div>
                        </div>
                        <div className='strength-block'>
                            <div style={{ display: 'flex' }}>
                                <div className="strength-bar" />
                                <div className='strength-title'>{t("Your")}<br /><b style={{ fontWeight: '900' }}>{t("strengths and assets")}</b></div>
                            </div>
                            <div className='result-skills'>
                                {
                                    props.softSkills.map((skill, index) => {
                                        return (
                                            <div key={skill.en + index} className='results-softskill-text'>
                                                {{
                                                    'en': skill.en,
                                                    'fr': skill.fr,
                                                    'ru': skill.ru
                                                }[lang]
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <img src={logo} alt="work well together" className="result-logo" />
                    </div>
                </div>
            }
        </div>
    );
});

export default ResultImage;