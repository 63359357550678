import server from '@config'
import axios from 'axios'
import { MDBModal, MDBModalBody } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getLanguage } from 'utils/utils'
import BottomMenu from '../BottomMenu/BottomMenu'
import InviteModal from '../Results/SubPages/InviteModal'
import './ContactPage.css'
import ContactPerson from './ContactPerson'

function ContactPage() {

    const [items, setItems] = useState()
    const [renderItems, setRenderItems] = useState()
    const [fakeUserImage, setFakeUserImage] = useState()
    const [showFakeUserImageModal, setShowFakeUserImageModal] = useState();
    // const history = useHistory()
    const { t } = useTranslation();

    useEffect(() => {
        if (items) {
            getFakeUserImage();
        }
    }, [items])

    useEffect(() => {
        if (fakeUserImage) {
            setShowFakeUserImageModal(true);
        }
    }, [fakeUserImage])

    useEffect(() => {
        let data = { "email": localStorage.getItem('email') }
        let config = {
            method: 'post',
            url: server + '/result/matching/',
            data: data
        }
        axios(config)
            .then(res => {
                // Sort b2c and fake matchings by rui_smooth in descending
                if (res.data.result) {
                    if (res.data.result.b2c_matching) {
                        res.data.result.b2c_matching = res.data.result.b2c_matching.sort((a, b) => b.rui_smooth - a.rui_smooth);
                    }
                    if (res.data.result.fake_matching) {
                        res.data.result.fake_matching = res.data.result.fake_matching.sort((a, b) => b.rui_smooth - a.rui_smooth);
                    }
                }
                setItems(res.data.result)
                setRenderItems(res.data.result)
            })
            .catch(err => {
                console.log(err)
                toast.error('there is an error to get the matching result from the server')
            })
    }, [])

    const toMatching = (item) => {
        localStorage.setItem('partner', item.email)
    }

    const getStrength = (item) => {
        if (item) {
            const strength = {};
            strength.value = item.rui_smooth;
            switch (getLanguage()) {
                case 'en':
                    strength.strength = item.top_fiber_en;
                    break
                case 'fr':
                    strength.strength = item.top_fiber_fr;
                    break
                case 'ru':
                    strength.strength = item.top_fiber_ru;
                    break
                default:
                    break
            }
            return strength;
        } else {
            return null;
        }
    }

    const filterContacts = (element) => {
        console.log('element', element.target.value);
        const filterInput = element.target.value;
        if (filterInput) {
            const data = { ...items };
            if (data.b2c_matching) {
                data.b2c_matching = data.b2c_matching.filter((item) => {
                    const fullName = item.first_name + ' ' + item.last_name;
                    return fullName.toLowerCase().includes(filterInput.toLowerCase());
                });
            }
            if (data.fake_matching) {
                data.fake_matching = data.fake_matching.filter((item) => {
                    const fullName = item.first_name + ' ' + item.last_name;
                    return fullName.toLowerCase().includes(filterInput.toLowerCase());
                });
            }
            setRenderItems(data);
        } else {
            setRenderItems(items);
        }
    }

    const getFakeUserImage = () => {
        if (items && items.fake_matching && items.fake_matching?.length >= 2) {
            const user1Id = items.fake_matching[0].fake_user_uuid;
            const user2Id = items.fake_matching[1].fake_user_uuid;
            let config = {
                method: 'get',
                url: `${server}/auth/get_fake_user_image/?user_1=${user1Id}&user_2=${user2Id}`
            }
            axios(config)
                .then(res => {
                    if (res && res.data) {
                        setFakeUserImage(res.data);
                    }
                }).catch((err) => {
                    console.warn('err', err);
                })

        }
    }

    return (
        <div className="contacts-page">
            <div className="compare-header">
                <div className='wwt-container d-flex contacts-header'>
                    {/* <div className="contact-invite-button">{t("Invite")}</div> */}
                    <InviteModal title={t("Invite someone")} />
                </div>
            </div>
            <div className="compare-shadow" />
            <div className='wwt-container'>
                <div className="contact-nav-button">
                    {/* <div className="contacts-header-content">{t("Push notifications disabled")}</div> */}
                    {/* <ToggleButton /> */}
                    <div className='d-flex row contacts-filter-block'>
                        <i className="fa fa-search filter-input-icon" aria-hidden="true"></i>
                        <input className='filter-input col' type='text' placeholder={t("Filter Contacts")} onChange={(element) => filterContacts(element)} />
                    </div>
                </div>
            </div>
            <BottomMenu id='contacts' />
            <div className='wwt-container'>
                {
                    renderItems ?
                        <>
                            {
                                renderItems?.fake_matching && renderItems?.fake_matching.map && (typeof renderItems?.fake_matching?.map === 'function') && renderItems?.fake_matching?.map((item, index) => {
                                    return (
                                        item.is_finished ?
                                            <Link
                                                key={index}
                                                to="/matching"
                                                onClick={() => toMatching(item)}
                                            >
                                                <ContactPerson
                                                    img={item.picture_url}
                                                    name={item.first_name + ' ' + item.last_name}
                                                    state={t("has accepted your invitation, click to discover your matching score")}
                                                    strength={getStrength(item)}
                                                />
                                            </Link> :
                                            <ContactPerson
                                                key={index}
                                                img={item.picture_url}
                                                name={item.first_name + ' ' + item.last_name}
                                                state={t("has accepted your invitation but hasn’t completed the test yet")}
                                            />
                                    )
                                })
                            }
                            {
                                renderItems?.b2c_matching && renderItems?.b2c_matching.map && (typeof renderItems?.b2c_matching?.map === 'function') && renderItems?.b2c_matching?.map((item, index) => {
                                    return (
                                        item.is_finished ?
                                            <Link
                                                key={index}
                                                to="/matching"
                                                onClick={() => toMatching(item)}
                                            >
                                                <ContactPerson
                                                    img={item.picture_url}
                                                    name={item.first_name + ' ' + item.last_name}
                                                    state={t("has accepted your invitation, click to discover your matching score")}
                                                    strength={getStrength(item)}
                                                />
                                            </Link> :
                                            <ContactPerson
                                                key={index}
                                                img={item.picture_url}
                                                name={item.first_name + ' ' + item.last_name}
                                                state={t("has accepted your invitation but hasn’t completed the test yet")}
                                            />
                                    )
                                })
                            }
                            <div></div>
                        </>
                        :
                        null
                }
            </div>
            <MDBModal isOpen={showFakeUserImageModal} toggle={() => setShowFakeUserImageModal(!showFakeUserImageModal)}>
                <MDBModalBody>
                    <div className='p-3 position-relative'>
                        <div className='top-message'>{t("Trop difficile de choisir entre 2 candidats ? On a la solution* !")}</div>
                        <img src={fakeUserImage?.image} alt='Person img' className='w-100' />
                        <div className='bottom-message'>{t("*Ce morphing est la synthèse de vos 2 meilleurs matchings")}</div>
                    </div>
                    <i className="fa fa-times close-icon" aria-hidden="true" onClick={() => setShowFakeUserImageModal(!showFakeUserImageModal)}></i>
                </MDBModalBody>
            </MDBModal>
        </div>
    )
}

export default ContactPage