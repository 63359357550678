import server from '@config';
import { duplicateMission } from 'api/mission';
import axios from 'axios';
import ConfirmationDialog from 'components/common/Dialogs/ConfirmationDialog';
import ContactsIcon from 'components/common/Icons/ContactsIcon';
import CopyIcon from 'components/common/Icons/CopyIcon';
import MoreDotsIcon from 'components/common/Icons/MoreDotsIcon';
import TrashIcon from 'components/common/Icons/TrashIcon';
import Table from 'components/common/Table/Table';
import Loader from 'components/Loader/Loader';
import { MDBBtn, MDBContainer, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane, MDBTooltip } from 'mdbreact';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { Item as MenuItem } from 'rc-menu';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDate, stopPropagation, truncate } from 'utils/utils';
import Header from '../Header/Header';
import RecruitmentDetails from './RecruitmentDetails/RecruitmentDetails';
import './Recruitments.css';


function Recruitments(props) {

    const { t } = useTranslation();

    const [activeItem, setActiveItem] = useState("1");
    const [missions, setMissions] = useState([]);
    const [missionsForTable, setMissionsForTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMission, setSelectedMission] = useState(null);
    const [confirmDialogConfig, setConfirmDialogConfig] = useState(null);

    const resetConfirmDialogConfig = () => {
        setTimeout(() => {
            setConfirmDialogConfig(null);
        }, 500);
    }

    const toggle = tab => e => {
        if (activeItem !== tab) {
            setActiveItem(tab);
        }
    };

    useEffect(() => {
        switch (activeItem) {
            case "1":
                getMyMissions();
                break;

            case "2":
                getColleaguesMissions();
                break;

            default:
                break;
        }
        // setDemoMissions();
    }, [activeItem])

    useEffect(() => {
        if (missions) {
            const missionForTable = [];
            missions.forEach((mission) => {
                const m = { ...mission };
                m.candidates = mission.mission_member ? mission.mission_member.length : 0;
                missionForTable.push(m);
            })
            setMissionsForTable(missionForTable);
        } else {
            setMissionsForTable([]);
        }
        updateSelectedMission();
    }, [missions]);

    const getMyMissions = () => {
        setLoading(true);
        let config = {
            method: 'get',
            url: server + '/company/mission/?mission_type=R',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('missions', res.data);
                setMissions(res.data);
            })
            .catch((err) => {
                setLoading(false);
                setMissions([]);
            })
    }

    const getColleaguesMissions = () => {
        setLoading(true);
        let config = {
            method: 'get',
            url: server + '/company/mission/?mission_type=R&colleagues=true',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                console.log('missions', res.data);
                setMissions(res.data);
            })
            .catch((err) => {
                setLoading(false);
                setMissions([]);
            })
    }

    const updateSelectedMission = () => {
        if (selectedMission) {
            const mission = missions.find((m) => m.pk === selectedMission.pk);
            setSelectedMission({ ...mission });
        }
    }

    const setDemoMissions = () => {
        setMissions([
            {
                mission_name: 'Mission 1',
                reference: 'CA001',
                mission_creation_date: '2021-01-02',
                mission_end_date: '2021-01-02',
                mission_manager: {
                    first_name: 'Arjun',
                    last_name: 'Patel'
                },
                mission_owner: {
                    first_name: 'Arjun',
                    last_name: 'Patel'
                },
            },
            {
                mission_name: 'Mission 1',
                reference: 'CA001',
                mission_creation_date: '2021-01-02',
                mission_end_date: '2021-01-02',
                mission_manager: {
                    first_name: 'Arjun',
                    last_name: 'Patel'
                },
                mission_owner: {
                    first_name: 'Arjun',
                    last_name: 'Patel'
                },
            }
        ])
    }

    const onMissionClick = (mission) => {
        setSelectedMission(mission);
    }

    const onMissionActionClick = (mission, type) => {
        switch (type) {
            case 'duplicate':
                setConfirmDialogConfig({
                    title: 'Duplicate Mission',
                    message: `${t('Do you want to duplicate mission')} <span class='text-primary'>${mission.mission_name}</span>?`,
                    onYesAction: () => onDuplicateMissionClick(mission),
                })
                break;

            case 'archive':
                archiveMission(mission);
                break;

            case 'remove':
                setConfirmDialogConfig({
                    title: t('Delete mission'),
                    message: `${t('Do you want to delete mission')} <span class='text-primary'>${mission.mission_name}</span>?`,
                    onYesAction: () => removeMission(mission),
                })
                break;

            case 'copyLink':
                toast.success(t("Link copied"));
                break;

            default:
                break;
        }
    }

    const onDuplicateMissionClick = (mission) => {
        setLoading(true);
        duplicateMission(mission).then(() => {
            setLoading(false);
            toast.success(('Mission duplicated successfully'));
            getMyMissions();
        }).catch((error) => {
            setLoading(false);
            toast.error('Failed to duplicate mission.');
        })
    }

    const archiveMission = (mission) => {
        let config = {
            method: 'put',
            url: server + '/company/mission_update/?pk=' + mission.pk,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                toast.success('Mission archived successfully.');
                getMyMissions();
            })
            .catch((err) => {
                setLoading(false);
                console.log('missions', err);
                toast.success('Failed to archive mission.');
            })
    }

    const removeMission = (mission) => {
        setLoading(true);
        let config = {
            method: 'delete',
            url: server + '/company/mission_update/?pk=' + mission.pk,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then(res => {
                setLoading(false);
                toast.success(t('Mission successfully removed'));
                getMyMissions();
            })
            .catch((err) => {
                setLoading(false);
                console.log('missions', err);
                toast.success(t('Failed to delete mission.'));
            })
    }

    return (
        <React.Fragment>
            <div className='admin-container' style={{ background: "#eeeeee", minHeight: '100vh' }}>
                <Header />
                <div className='container py-4 pt-5'>
                    <div className="row">
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <h2 className='missions-header'>{t("Recruitment")}</h2>
                            <Link to={'/recruitments/add'}>
                                <MDBBtn className='wwt-btn flat wwt-btn-pink px-5' >
                                    + {t("New")}
                                </MDBBtn>
                            </Link>
                        </div>
                        <MDBContainer>
                            <Loader loading={loading}>
                                <MDBNav className="nav-tabs mt-5">
                                    <MDBNavItem>
                                        <MDBNavLink link to="#" active={activeItem === "1"} onClick={toggle("1")} role="tab" >
                                            {t("My Recruitment Missions")}
                                        </MDBNavLink>
                                    </MDBNavItem>
                                    {/* <MDBNavItem>
                                        <MDBNavLink link to="#" active={activeItem === "2"} onClick={toggle("2")} role="tab" >
                                            My Colleagues' Missions
                                </MDBNavLink>
                                    </MDBNavItem> */}
                                </MDBNav>
                                <MDBTabContent activeItem={activeItem} className='bg-white'>
                                    <MDBTabPane tabId="1" role="tabpanel" className='overflow-auto'>
                                        <Table id='myRecruitmentMissions' className='missions' rowClickable
                                            columns={[
                                                [
                                                    { key: 'mission_name', title: 'Missions', rowSpan: 2, sortable: true },
                                                    { key: 'reference', title: 'Ref.', rowSpan: 2, sortable: true },
                                                    { key: 'candidates', title: 'Candidates', rowSpan: 2, sortable: true },
                                                    { key: 'mission_creation_date', title: 'Creation', type: 'date', rowSpan: 2, sortable: true },
                                                    { key: 'mission_end_date', title: 'Estimated End', type: 'date', rowSpan: 2, sortable: true },
                                                    { title: 'Manager', colSpan: 2 },
                                                    { title: '' }
                                                ],
                                                [
                                                    { key: 'mission_manager.last_name', title: 'Last Name', className: 'font-weight-normal', sortable: true },
                                                    { key: 'mission_manager.first_name', title: 'First Name', className: 'font-weight-normal', sortable: true }
                                                ],
                                            ]}
                                            data={missionsForTable}
                                            rowComponent={
                                                (mission, index) => (
                                                    <tr key={`row-${mission.pk}-${index}`} id={`${mission.pk}_${mission.mission_name}`} onClick={() => onMissionClick(mission)}>
                                                        <td>
                                                            <MDBTooltip
                                                                domElement
                                                                tag="span"
                                                                placement="top"
                                                            >
                                                                <span>{truncate(mission.mission_name, 30)}</span>
                                                                <span>{mission.mission_name}</span>
                                                            </MDBTooltip>
                                                        </td>
                                                        <td>{mission.reference}</td>
                                                        <td>
                                                            <ContactsIcon className='mr-2' />
                                                            {mission.candidates}
                                                        </td>
                                                        <td>{formatDate(mission.mission_creation_date)}</td>
                                                        <td>{formatDate(mission.mission_end_date)}</td>
                                                        <td>
                                                            <MDBTooltip domElement tag="span" placement="top">
                                                                <span>{truncate(mission.mission_manager.last_name, 15)}</span>
                                                                <span>{mission.mission_manager.last_name}</span>
                                                            </MDBTooltip>
                                                        </td>
                                                        <td>
                                                            <MDBTooltip domElement tag="span" placement="top">
                                                                <span>{truncate(mission.mission_manager.first_name, 15)}</span>
                                                                <span>{mission.mission_manager.first_name}</span>
                                                            </MDBTooltip>
                                                        </td>
                                                        <td onClick={stopPropagation}>

                                                            <Dropdown
                                                                trigger={['click']}
                                                                overlay={
                                                                    <Menu className='p-2' selectable={false}>
                                                                        <MenuItem key='duplicate'>
                                                                            <button className='action-btn'>
                                                                                <CopyIcon className='icon' />
                                                                                <span className='text'>{t("Duplicate")}</span>
                                                                            </button>
                                                                        </MenuItem>
                                                                        {/* {
                                                                            !mission.mission_archived_date ?
                                                                                <MenuItem key='archive'>
                                                                                    <button className='action-btn'>
                                                                                        <MDBIcon icon="archive" className='icon' />
                                                                                        {t("Archive")}
                                                                                    </button>
                                                                                </MenuItem> : null
                                                                        } */}
                                                                        <MenuItem key='remove'>
                                                                            <button className='action-btn'>
                                                                                <TrashIcon className='icon' />
                                                                                <span className='text'>{t("Delete")}</span>
                                                                            </button>
                                                                        </MenuItem>
                                                                        <MenuItem key='copyLink'>
                                                                            <CopyToClipboard text={`${window.location.origin}/?mission=${mission.mission_referral}`}>
                                                                                <button className='action-btn'>
                                                                                    <CopyIcon className='icon' />
                                                                                    <span className='text'>{t("Copy the invitation link")}</span>
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                }
                                                                animation="slide-up"
                                                                placement="bottomRight"
                                                                onOverlayClick={(e) => onMissionActionClick(mission, e.key)}
                                                            >
                                                                <button className='action-btn' onClick={stopPropagation}>
                                                                    <MoreDotsIcon className='icon' />
                                                                </button>
                                                            </Dropdown>
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                        />
                                    </MDBTabPane>
                                </MDBTabContent>
                            </Loader>
                            <RecruitmentDetails loading={loading} mission={selectedMission} toggle={() => setSelectedMission(null)} reload={() => getMyMissions()} />
                        </MDBContainer>
                    </div>
                </div>
                {
                    confirmDialogConfig ?
                        <ConfirmationDialog isOpen
                            title={confirmDialogConfig.title}
                            message={confirmDialogConfig.message}
                            onYesAction={confirmDialogConfig.onYesAction}
                            onNoAction={confirmDialogConfig.onNoAction}
                            onToggle={(state) => !state ? resetConfirmDialogConfig() : null}>
                        </ConfirmationDialog>
                        : null
                }
            </div>
        </React.Fragment>
    );
}

export default Recruitments;