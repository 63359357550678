import SortList from 'components/Sort/SortList';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SortWordBlock.css';

function SortWordBlock({ words, onNext }) {

    const { t } = useTranslation();
    const [sortedWords, setSortedWords] = useState([...words]);

    const setResult = (data) => {
        console.log('sorting words', data);
        setSortedWords([...data]);
    }

    const onSubmit = () => {
        onNext(sortedWords);
    }

    return (
        <div>
            {words.length > 0 &&
                <div className="sort-page">

                    <div className="main">
                        <div className='wwt-container'>
                            <div className="sort-title">
                                {t("Please sort the words selected by order of preference")}
                            </div>
                            <div className="content">
                                <div className="lines-numbers">
                                    {Array.from({ length: words?.length }, (_, i) => i + 1).map((number, index) => {
                                        return <div className="number" key={`${index}`}>{number}</div>
                                    })}
                                </div>
                                <div className="sort-list">
                                    <SortList words={words} setResult={setResult} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rooter">
                        <div className="line" />
                        <div className='wwt-container align-items-center d-flex h-100 justify-content-end'>
                            <button className="next-button-active" onClick={() => onSubmit()}>
                                {t("Validate")}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SortWordBlock;
