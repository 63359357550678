import server from '@config';
import { removeMemberFromMission } from 'api/mission';
import axios from 'axios';
import CompleteProfileSubMenu from 'components/Admin/common/CompleteProfileSubMenu/CompleteProfileSubMenu';
import QuotationTable from 'components/Admin/common/QuotationTable/QuotationTable';
import ConfirmationDialog from 'components/common/Dialogs/ConfirmationDialog';
import EditIcon from 'components/common/Icons/EditIcon';
import MoreDotsIcon from 'components/common/Icons/MoreDotsIcon';
import PeopleIcon from 'components/common/Icons/PeopleIcon';
import TableIcon from 'components/common/Icons/TableIcon';
import TrashIcon from 'components/common/Icons/TrashIcon';
import Table from 'components/common/Table/Table';
import Loader from 'components/Loader/Loader';
import { getContactType } from 'constants/constants';
import * as htmlToImage from 'html-to-image';
import { MDBIcon, MDBModal, MDBModalBody, MDBTooltip } from 'mdbreact';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem, MenuItemGroup } from 'rc-menu';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { checkQuotationAccess, isEvaluationCompleted, stopPropagation, truncate } from 'utils/utils';
import { getProp } from '../../../../utils/utils';
import ProgressBar from '../../../common/ProgressBar/ProgressBar';
import MobilityCandidateModel from './MobilityCandidateModel/MobilityCandidateModel';
import './MobilityDetails.css';

function MobilityDetails(props) {

    const [mission, setMission] = useState(null);
    const [modal, setModal] = useState(false);
    const [candidateModal, setCandidateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [dataForGrid, setDataForGrid] = useState([]);
    const [matchingMap, setMatchingMap] = useState({});
    const [searchInput, setSearchInput] = useState(null);
    const [printMode, setPrintMode] = useState(false);
    const [showAvatarCompatibility, setShowAvatarCompatibility] = useState(false);

    const [quotationTableData, setQuotationTableData] = useState(null);

    const quotationAccess = checkQuotationAccess();
    const [confirmDialogConfig, setConfirmDialogConfig] = useState(null);

    const resetConfirmDialogConfig = () => {
        setTimeout(() => {
            setConfirmDialogConfig(null);
        }, 500);
    }

    useEffect(() => {
        if (props.mission) {
            setMission(props.mission);
            const mission = props.mission;
            setModal(true);
            const contacts = [];
            if (mission.mission_manager) {
                contacts.push({ user: { ...mission.mission_manager, type: getContactType(mission.mission_manager.contact_type) } });
            }
            if (mission.mission_benchmarks && mission.mission_benchmarks.length) {
                mission.mission_benchmarks.forEach((benchmark) => {
                    contacts.push({ user: { ...benchmark.contact, type: getContactType(benchmark.benchmark_type) } });
                })
            }
            if (mission.mission_member && mission.mission_member.length) {
                mission.mission_member.forEach((member) => {
                    contacts.push({ user: { ...member, type: getContactType(member.contact_type), member: true } });
                })
            }
            setContacts(contacts);
            if (contacts.length) {
                setSelectedContact(contacts[0]);
            }
        } else {
            setModal(false);
        }
        setSearchInput('');
    }, [props.mission]);

    useEffect(() => {
        updateDataForGrid();
    }, [contacts, matchingMap, selectedContact])

    useEffect(() => {
        if (printMode) {
            printTable();
        }
    }, [printMode])

    const updateDataForGrid = () => {
        const data = contacts.filter((c) => c.user.member);
        if (matchingMap && selectedContact) {
            data.forEach((contact) => {
                const user_1 = selectedContact.user.user_id;
                const user_2 = contact.user.user_id;
                const matching = matchingMap[`${user_1}_${user_2}`] || matchingMap[`${user_2}_${user_1}`];
                contact.user.matching = matching || {};
            });
        }
        setDataForGrid(data);
    }

    const toggle = () => {
        if (props && props.toggle) {
            props.toggle();
        }
    }

    const toggleCandidateModal = () => {
        setCandidateModal(!candidateModal);
    }

    useEffect(() => {
        if (selectedContact && selectedContact.user.user_id) {
            const data = {
                source: {
                    company_id: selectedContact.user.company_id,
                    email: selectedContact.user.email
                },
                target: contacts.filter((c) => c.user.member).filter((c) => !isMatchingAvailable(selectedContact.user.user_id, c.user.user_id)).map((c) => {
                    return {
                        company_id: c.user.company_id,
                        email: c.user.email
                    }
                })
            }
            if (data.target.length) {
                getMatchingData(data);
            }
        }
    }, [selectedContact])

    useEffect(() => {
        if (selectedCandidate && selectedCandidate.user.user_id) {
            const data = {
                source: {
                    company_id: selectedCandidate.user.company_id,
                    email: selectedCandidate.user.email
                },
                target: contacts.filter((c) => !c.user.member).filter((c) => !isMatchingAvailable(selectedCandidate.user.user_id, c.user.user_id)).map((c) => {
                    return {
                        company_id: c.user.company_id,
                        email: c.user.email
                    }
                })
            }
            if (data.target.length) {
                getMatchingData(data);
            }
            toggleCandidateModal();
        }
    }, [selectedCandidate])

    const isMatchingAvailable = (user_1, user_2) => {
        return matchingMap[`${user_1}_${user_2}`] || matchingMap[`${user_2}_${user_1}`];
    }

    const getMatchingData = (data) => {
        setLoading(true);

        let config = {
            method: 'post',
            url: server + '/result/match_contacts/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log('response', res);
                if (res && res.data) {
                    updateMatchingMap(res.data);
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('error in matching', err)
                toast.error('Failed to retrieve matching results.')
                setLoading(false);
            })
    }

    const updateMatchingMap = (matchings) => {
        const map = matchingMap || {};
        if (matchings) {
            if (matchings.source_target_matching && matchings.source_target_matching.length) {
                matchings.source_target_matching.forEach((matching) => {
                    map[`${matching.user_1}_${matching.user_2}`] = matching;
                });
            }
            if (matchings.avatar_target_matching && matchings.avatar_target_matching.length) {
                setShowAvatarCompatibility(true);
                matchings.avatar_target_matching.forEach((avatarMatching) => {
                    if (map[`${avatarMatching.user_1}_${avatarMatching.user_2}`]) {
                        map[`${avatarMatching.user_1}_${avatarMatching.user_2}`].ircp_with_avatar = avatarMatching.ircp;
                        map[`${avatarMatching.user_1}_${avatarMatching.user_2}`].ircp_smooth_with_avatar = avatarMatching.ircp_smooth;
                    } else if (map[`${avatarMatching.user_2}_${avatarMatching.user_1}`]) {
                        map[`${avatarMatching.user_2}_${avatarMatching.user_1}`].ircp_with_avatar = avatarMatching.ircp;
                        map[`${avatarMatching.user_2}_${avatarMatching.user_1}`].ircp_smooth_with_avatar = avatarMatching.ircp_smooth;
                    } else {
                        const sourceMatchingKeys = Object.keys(map).filter((key) => [avatarMatching.user_1, avatarMatching.user_2].includes(map[key].user_1) || [avatarMatching.user_1, avatarMatching.user_2].includes(map[key].user_2));
                        sourceMatchingKeys.forEach((key) => {
                            map[key].ircp_with_avatar = avatarMatching.ircp;
                            map[key].ircp_smooth_with_avatar = avatarMatching.ircp_smooth;
                        });
                    }
                })
            }
        }
        setMatchingMap({ ...map });
    }

    const getMatchingProgressBar = (user_1, user_2, key, styles, classes) => {
        const matching = matchingMap[`${user_1}_${user_2}`] || matchingMap[`${user_2}_${user_1}`];
        if (matching) {
            if (matching[key] >= 0) {
                return <ProgressBar value={matching[key] * 100} text={matching[key] * 100} styles={styles} classes={classes} />
            }
        }
        return null;
    }

    const getMatchingProfilePdf = (contact_1, contact_2, language) => {
        setLoading(true);

        const data = { contact_1, contact_2 };

        let config = {
            method: 'post',
            url: server + '/company/matching_result_pdf/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log('response', res);
                setLoading(false);
                switch (language) {
                    case 'en':
                        if (res.data.matching_pdf_en) {
                            window.open(res.data.matching_pdf_en, '_blank');
                        }
                        break;

                    case 'fr':
                        if (res.data.matching_pdf_fr) {
                            window.open(res.data.matching_pdf_fr, '_blank');
                        }
                        break;

                    default:
                        break;
                }
            })
            .catch(err => {
                console.log('error in matching', err)
                toast.error('Failed to retrieve matching results.')
                setLoading(false);
            })
    }

    const removeMemberConfirmation = (contact) => {
        setConfirmDialogConfig({
            title: `${t('Confirm deletion?')}`,
            onYesAction: () => removeMember(contact),
        })
    }

    const updateQuotationTableData = (data) => {
        setQuotationTableData(data);
    }

    const removeMember = (contact) => {
        setLoading(true);
        removeMemberFromMission(mission.pk, contact.user.id)
            .then((res) => {
                console.log('remove member res', res);
                toast.success(t('Candidate removed successfully'));
                if (props.reload) {
                    props.reload();
                }
                setLoading(false);
            });
    }

    const showMessage = (contact1, contact2) => {
        let message = '';
        const c1EvaluationCompleted = isEvaluationCompleted(getProp(contact1, ['user', 'result']));
        const c2EvaluationCompleted = isEvaluationCompleted(getProp(contact2, ['user', 'result']));
        if (!(c1EvaluationCompleted || c2EvaluationCompleted)) {
            message = `${contact1?.user?.first_name} ${contact1?.user?.last_name} ${t('and')}  ${contact2?.user?.first_name} ${contact2?.user?.last_name} ${t("haven't completed the evaluation yet")}.`;
        } else if (!c1EvaluationCompleted) {
            message = `${contact1?.user?.first_name} ${contact1?.user?.last_name} ${t("hasn't completed the evaluation yet")}.`;
        } else if (!c2EvaluationCompleted) {
            message = `${contact2?.user?.first_name} ${contact2?.user?.last_name} ${t("hasn't completed the evaluation yet")}.`;
        }
        return message;
    }

    const filterData = (data) => {
        const input = searchInput ? searchInput.toLowerCase() : '';
        if (input) {
            const matchString = [data.user.last_name, data.user.first_name].filter((d) => d).join('').toLowerCase();
            if (matchString.includes(input)) {
                return true;
            }
            return false;
        }
        return true;
    }

    const printTable = () => {
        const modal = document.getElementById('missionDetails');
        const html = modal.getElementsByClassName('modal-content')[0];
        html.style.padding = '16px 32px 32px 32px';
        htmlToImage.toPng(html, { pixelRatio: 1 })
            .then(function (dataUrl) {
                var a = document.createElement("a"); //Create <a>
                a.href = dataUrl; //Image Base64 Goes here
                const fileName = `${mission?.reference?.substring(0, 15)} ${selectedContact?.user?.last_name?.substring(0, 15)} ${selectedContact?.user?.first_name?.substring(0, 3)}`;
                a.download = `${fileName}.png`; //File name Here
                a.click(); //Downloaded file
                a.remove();
                toast.success(t('Mission details downloaded successfully'));
                setPrintMode(false);
                html.style.padding = 'unset';
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                toast.error(t('Failed to download mission details'));
                setPrintMode(false);
                html.style.padding = 'unset';
            });
    }


    return (
        <MDBModal isOpen={modal} toggle={toggle} size='xl' className='mobility-details-modal' id='missionDetails'>
            <Loader loading={(loading || props.loading) && !candidateModal}>
                {
                    props.mission &&
                    <MDBModalBody>
                        {
                            printMode &&
                            <div className='d-flex justify-content-center align-items-center p-3'>
                                <h4>{t("Mobility")}</h4>
                            </div>
                        }
                        <div className='modal-header d-flex justify-content-between align-items-center'>
                            <div className='mx-2'>
                                <MDBTooltip domElement tag="span" placement="bottom">
                                    <span>{truncate(props.mission.mission_name, 30)}</span>
                                    <span>{props.mission.mission_name}</span>
                                </MDBTooltip>
                            </div>
                            <div className='mx-2'>{t("Ref.")} : {props.mission.reference}</div>
                            <span></span>
                            <div className='d-flex ml-3 mission-details-action-btns'>
                                {
                                    false &&
                                    <>
                                        <button className='action-btn'>
                                            <MDBIcon icon="user-plus" className='icon' />
                                        </button>
                                        <button className='action-btn'>
                                            <MDBIcon icon="edit" className='icon' />
                                        </button>
                                        <button className='action-btn'>
                                            <MDBIcon icon="archive" className='icon' />
                                        </button>
                                        <button className='action-btn'>
                                            <MDBIcon icon="trash" className='icon' />
                                        </button>
                                    </>
                                }
                                {
                                    (!loading && !printMode) &&
                                    <button className='action-btn' onClick={() => setPrintMode(true)}>
                                        <MDBIcon icon="print" className='icon' />
                                    </button>
                                }
                                {
                                    (mission && !printMode) &&
                                    <Link to={`/mobilities/${mission.pk}/update`}>
                                        <button className='action-btn'>
                                            <EditIcon className='icon' />
                                        </button>
                                    </Link>
                                }
                                {
                                    (!printMode) &&
                                    <button className='action-btn' onClick={toggle}>
                                        <MDBIcon icon="times" className='icon' />
                                    </button>
                                }
                            </div>

                        </div>
                        {
                            selectedContact &&
                            <div className='selected-contact-block'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <span className='mr-2'>
                                            <MDBTooltip domElement tag="span" placement="top">
                                                <span>{truncate(selectedContact.user.first_name + ' ' + selectedContact.user.last_name, 30)}</span>
                                                <span>{selectedContact.user.first_name} {selectedContact.user.last_name}</span>
                                            </MDBTooltip>
                                        </span>
                                        {!printMode &&
                                            <Dropdown
                                                trigger={['click']}
                                                overlay={
                                                    <Menu className='p-2' selectable={false}>
                                                        <MenuItem key={'menu-title'}><span>{t("Match with")}:</span></MenuItem>
                                                        {
                                                            contacts.filter((c) => !c.user.member && c.user.id !== selectedContact.user.id).map((contact) => (
                                                                <MenuItem key={contact.user.id}>
                                                                    <button className='action-btn' onClick={() => setSelectedContact(contact)}>
                                                                        <MDBIcon icon="user-circle" className='icon' />
                                                                        <span className='text'>{contact.user.first_name} {contact.user.last_name}
                                                                            <span className='ml-2 text-light'>({contact.user.type})</span>
                                                                        </span>
                                                                    </button>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Menu>
                                                }
                                                animation="slide-up"
                                                placement="bottomLeft"
                                            >
                                                <button className='action-btn'>
                                                    <MDBIcon icon="angle-down" className='icon' />
                                                </button>
                                            </Dropdown>
                                        }
                                    </div>
                                    {
                                        !printMode &&
                                        <input className='search-input' placeholder={`${t('Search')}...`} onChange={(e) => setSearchInput(e.target.value)} />
                                    }
                                </div>
                            </div>
                        }
                        <div className={`${!printMode ? 'overflow-auto' : ''}`}>
                            <Table className='missions'
                                columns={[
                                    [
                                        { key: 'user.last_name', title: 'Last Name', sortable: true },
                                        { key: 'user.first_name', title: 'First Name', sortable: true },
                                        { key: 'user.type', title: 'Role', sortable: true },
                                        { key: 'user.matching.ircp_smooth_with_avatar', title: 'Avatar', tooltip: 'Avatar Compatibility', sortable: true, hidden: !showAvatarCompatibility },
                                        { key: 'user.matching.rui_smooth', title: 'RI', tooltip: 'Relational Index', sortable: true },
                                        { key: 'user.matching.immediate_harmony_smooth', title: 'FE', tooltip: 'Feeling', sortable: true },
                                        { key: 'user.matching.deep_compatibility_smooth', title: 'DC', tooltip: 'Deep Compatibility', sortable: true },
                                        { key: 'user.matching.complementarity_smooth', title: 'CP', tooltip: 'Complementarity', sortable: true },
                                        { key: 'user.matching.similarity_smooth', title: 'CO', tooltip: 'Connivance', sortable: true },
                                        { key: 'user.matching.understanding_smooth', title: 'UN', tooltip: 'Understanding', sortable: true },
                                        { title: '', hidden: printMode }
                                    ]
                                ]}
                                data={dataForGrid.filter((data) => filterData(data))}
                                rowComponent={
                                    (data, index) => (
                                        <tr key={data.user.id}>
                                            <td>
                                                <MDBTooltip domElement tag="span" placement="top">
                                                    <span>{truncate(data.user.last_name, 15)}</span>
                                                    <span>{data.user.last_name}</span>
                                                </MDBTooltip>
                                            </td>
                                            <td>
                                                <MDBTooltip domElement tag="span" placement="top">
                                                    <span>{truncate(data.user.first_name, 15)}</span>
                                                    <span>{data.user.first_name}</span>
                                                </MDBTooltip>
                                            </td>
                                            <td>{t(data.user.type)}</td>
                                            {
                                                (isEvaluationCompleted(getProp(selectedContact, ['user', 'result'])) && isEvaluationCompleted(getProp(data, ['user', 'result']))) ?
                                                    <>
                                                        {showAvatarCompatibility &&
                                                            <td>{getMatchingProgressBar(selectedContact.user.user_id, data.user.user_id, 'ircp_smooth_with_avatar', { pathColor: '#e5e563' }, 'font-weight-bold')}</td>
                                                        }
                                                        <td>{getMatchingProgressBar(selectedContact.user.user_id, data.user.user_id, 'rui_smooth', { pathColor: '#00CFD7' }, 'font-weight-bold')}</td>
                                                        <td>{getMatchingProgressBar(selectedContact.user.user_id, data.user.user_id, 'immediate_harmony_smooth')}</td>
                                                        <td>{getMatchingProgressBar(selectedContact.user.user_id, data.user.user_id, 'deep_compatibility_smooth')}</td>
                                                        <td>{getMatchingProgressBar(selectedContact.user.user_id, data.user.user_id, 'complementarity_smooth')}</td>
                                                        <td>{getMatchingProgressBar(selectedContact.user.user_id, data.user.user_id, 'similarity_smooth')}</td>
                                                        <td>{getMatchingProgressBar(selectedContact.user.user_id, data.user.user_id, 'understanding_smooth')}</td>
                                                    </>
                                                    : <td colSpan={showAvatarCompatibility ? 7 : 6} className='text-black-50 text-center'>
                                                        {showMessage(selectedContact, data)}
                                                    </td>
                                            }
                                            {!printMode &&
                                                <td onClick={stopPropagation}>
                                                    <Dropdown
                                                        trigger={['click']}
                                                        overlay={
                                                            <Menu className='p-2' selectable={false}>
                                                                {CompleteProfileSubMenu({ user: data.user, t })}
                                                                {
                                                                    isEvaluationCompleted(data.user.result) ?
                                                                        <MenuItemGroup className='menu-item-group' title={<div>{t("Matching Report")}</div>}>
                                                                            <MenuItem key='matching_result_pdf_en'>
                                                                                <button className='action-btn' onClick={() => getMatchingProfilePdf(selectedContact?.user?.id, data.user.id, 'en')}>
                                                                                    <MDBIcon icon="download" className='icon' />
                                                                                    <span className='text'>{t("English")}</span>
                                                                                </button>
                                                                            </MenuItem>
                                                                            <MenuItem key='matching_result_pdf_fr'>
                                                                                <button className='action-btn' onClick={() => getMatchingProfilePdf(selectedContact?.user?.id, data.user.id, 'fr')}>
                                                                                    <MDBIcon icon="download" className='icon' />
                                                                                    <span className='text'>{t("French")}</span>
                                                                                </button>
                                                                            </MenuItem>
                                                                        </MenuItemGroup>
                                                                        : null
                                                                }
                                                                {
                                                                    (quotationAccess && !data.user.confidential && isEvaluationCompleted(data.user.result)) ?
                                                                        <MenuItem key='quotationTable'>
                                                                            <MDBTooltip domElement tag="span" placement="top">
                                                                                <button className='action-btn' onClick={() => updateQuotationTableData(data.user)}>
                                                                                    <TableIcon className='icon' />
                                                                                    <span className='text'>{t("Quotation Table")}</span>
                                                                                </button>
                                                                                <span>{t("Quotation Table")}</span>
                                                                            </MDBTooltip>
                                                                        </MenuItem>
                                                                        :
                                                                        null
                                                                }
                                                                <MenuItem key='allMatchings'>
                                                                    <MDBTooltip domElement tag="span" placement="top">
                                                                        <button className='action-btn' onClick={() => setSelectedCandidate(data)}>
                                                                            <PeopleIcon className='icon' />
                                                                            <span className='text'>{t("All Matchings")}</span>
                                                                        </button>
                                                                        <span>{t("All Matchings")}</span>
                                                                    </MDBTooltip>
                                                                </MenuItem>
                                                                <MenuItem key='remove'>
                                                                    <MDBTooltip domElement tag="span" placement="top">
                                                                        <button className='action-btn' onClick={() => removeMemberConfirmation(data)}>
                                                                            <TrashIcon className='icon' />
                                                                            <span className='text'>{t("Remove Candidate")}</span>
                                                                        </button>
                                                                        <span>{t("Remove Candidate")}</span>
                                                                    </MDBTooltip>
                                                                </MenuItem>
                                                            </Menu>
                                                        }
                                                        placement="bottomRight"
                                                    >
                                                        <button className='action-btn' onClick={stopPropagation}>
                                                            <MoreDotsIcon className='icon' />
                                                        </button>
                                                    </Dropdown>
                                                </td>
                                            }
                                        </tr>

                                    )
                                }
                                noDataComponent={searchInput ? (<tr><td colSpan='10' className='text-center'>No data available for applied filter.</td></tr>) : null}
                            />
                        </div>
                        {
                            selectedCandidate &&
                            <MobilityCandidateModel loading={loading} contacts={contacts} matchingMap={matchingMap}
                                selectedCandidate={selectedCandidate} setSelectedCandidate={setSelectedCandidate} />
                        }
                        {
                            quotationTableData &&
                            <QuotationTable data={quotationTableData} onToggle={() => setQuotationTableData(null)} />
                        }
                        {
                            confirmDialogConfig ?
                                <ConfirmationDialog isOpen
                                    title={confirmDialogConfig.title}
                                    message={confirmDialogConfig.message}
                                    onYesAction={confirmDialogConfig.onYesAction}
                                    onNoAction={confirmDialogConfig.onNoAction}
                                    onToggle={(state) => !state ? resetConfirmDialogConfig() : null}>
                                </ConfirmationDialog>
                                : null
                        }
                    </MDBModalBody>

                }
            </Loader>
        </MDBModal>
    );
}

export default MobilityDetails;