import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import MultiLang from '../MultiLang/MultiLang'
import Header from '../Header/Header'
import MainPage from './MainPage'
import SecondPage from './SecondPage'
import ThirtPage from './ThirdPage'
import FourthPage from './FourthPage'
import ContactPage from './ContactPage'
import LoginModal from 'components/Auth/LoginModal'

function HomePage() {

  const [email, setEmail] = useState()
  const [passed, setPassed] = useState(false)
  let params = new URLSearchParams(window.location.search)

  useEffect(() => {
    // let params = new URLSearchParams(window.location.search)
    // let ref = params.get('ref')
  }, [])

  // debugger
  if (email) {
    if (passed) {
      return (
        <Redirect to="results" />
      )
    } else {
      return (
        <Redirect to="choice" />
      )
    }
  } else {
    return (
      <div className="homepage">
        {/* <div className="NavBar">
                    <MultiLang />
                </div><br /> */}
        <Header />
        <MainPage setEmail={setEmail} setPassed={setPassed} />
        <SecondPage setEmail={setEmail} setPassed={setPassed} />
        <ThirtPage setEmail={setEmail} setPassed={setPassed} />
        <ContactPage />
        {
          params.has('login') &&
          <LoginModal
            setEmail={setEmail}
            setPassed={setPassed}
            pageNum={1}
            showLoginDefault
          />
        }
      </div>
    )
  }
}

export default HomePage