import server from '@config'
import axios from 'axios'

export const getProfile = () => {
    const email = localStorage.getItem('email')
    let config = {
        method: 'post',
        url: server + '/result/profile/',
        headers: {
            'Content-Type': 'application/json'
        },
        data: { email }
    };
    return axios(config)
        .then(res => {
            let profile;
            if (res.data['result']) {
                profile = res.data['result'];
                if (profile) {
                    localStorage.setItem('profile', JSON.stringify(profile));
                }
            }
            return profile;
        })
}

export const getIndividualResult = () => {
    let data1 = { 'email': localStorage.getItem('email') }
    let config = {
        method: 'post',
        url: server + '/result/individual_result/',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data1
    }

    return axios(config);
}