import { MDBTable, MDBTableBody, MDBTableHead, MDBTooltip } from 'mdbreact';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProp } from 'utils/utils';
import SortAscIcon from '../Icons/SortAscIcon';
import SortDescIcon from '../Icons/SortDescIcon';
import SortIcon from '../Icons/SortIcon';

function Table({ columns = [], data = [], rowComponent, noDataComponent, className, rowClickable, id = null }) {

    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [sortBy, setSortBy] = useState({});

    const { t } = useTranslation();

    useEffect(() => {
        setCols(columns);
    }, [columns]);

    useEffect(() => {
        if(sortBy && sortBy.col) {
            sortData(sortBy.col, sortBy.type);
        } else {
            setRows([...data]);
        }
    }, [data]);

    const ShowSortIcon = (col) => {
        let icon = null;
        if (col.sortable) {
            icon = <SortIcon className='ml-2' />;
            if (sortBy.col) {
                if (sortBy.col.key === col.key) {
                    if (sortBy.type === 'asc') {
                        icon = <SortAscIcon className='ml-2' />;
                    } else {
                        icon = <SortDescIcon className='ml-2' />;
                    }
                }
            }
        }
        return icon;
    }

    const sort = (col) => {
        if (col.sortable && col.key && typeof col.key === 'string') {

            let sortOrder = 'asc';
            if (sortBy.col) {
                if (sortBy.col.key === col.key) {
                    if (sortBy.type === 'asc') {
                        sortOrder = 'desc';
                        setSortBy({
                            col, type: 'desc'
                        });
                    } else {
                        setSortBy({});
                        sortOrder = null;
                    }
                } else {
                    setSortBy({
                        col, type: 'asc'
                    })
                }
            } else {
                setSortBy({
                    col, type: 'asc'
                });
            }

            sortData(col, sortOrder);
        }
    }

    const sortData = (col, sortOrder) => {
        if (sortOrder) {
            if (sortOrder === 'asc') {
                setRows([
                    ...data.sort((a, b) => {
                        let order = 0;
                        let aValue = getProp(a, col.key.split('.'));
                        let bValue = getProp(b, col.key.split('.'));

                        /* Check if value is date or not */
                        if (col.type === 'date') {
                            if (moment(aValue).isValid()) {
                                aValue = new Date(aValue).getTime();
                            }
                            if (moment(bValue).isValid()) {
                                bValue = new Date(bValue).getTime();
                            }
                        }

                        if (aValue != null && bValue != null) {
                            order = typeof aValue == "number" ? (aValue - bValue) : aValue.localeCompare(bValue);
                        } else {
                            if (aValue != null) {
                                order = -1;
                            } else if (bValue != null) {
                                order = 1;
                            }
                        }
                        return order;
                    })
                ]);
            } else {
                const sortedData = [
                    ...data.sort((a, b) => {
                        let order = 0;
                        let aValue = getProp(a, col.key.split('.'));
                        let bValue = getProp(b, col.key.split('.'));

                        /* Check if value is date or not */
                        if (col.type === 'date') {
                            if (moment(aValue).isValid()) {
                                aValue = new Date(aValue).getTime();
                            }
                            if (moment(bValue).isValid()) {
                                bValue = new Date(bValue).getTime();
                            }
                        }

                        if (aValue != null && bValue != null) {
                            order = typeof aValue == "number" ? (bValue - aValue) : bValue.localeCompare(aValue);
                        } else {
                            if (bValue != null) {
                                order = 1;
                            } else if (aValue != null) {
                                order = -1;
                            }
                        }
                        return order;
                    })
                ]
                setRows(sortedData);
            }
        } else {
            setRows([...data]);
        }
    }


    return (
        <MDBTable striped className={`wwt-table ${rowClickable ? 'row-clickable' : ''} ${className || ''}`} id={id}>
            <MDBTableHead>
                {
                    cols.map((columnGroup, colGroupIndex) => {
                        return (
                            <tr key={`col-group-${colGroupIndex}`}>
                                {
                                    columnGroup.map((column, colIndex) => {
                                        return (
                                            !column.hidden ?
                                                <th key={`col-${colIndex}`} {...column} title={null} className={`${column.sortable ? 'cursor-pointer' : ''} ${column.className ? ' ' + column.className + ' ' : ''}`} onClick={() => sort(column)}>
                                                    <div className='d-flex align-items-center'>
                                                        {
                                                            column.tooltip ?
                                                                <MDBTooltip domElement tag="span" placement="top">
                                                                    <span>{t(column.title)}</span>
                                                                    <span>{t(column.tooltip)}</span>
                                                                </MDBTooltip>
                                                                : <span>{t(column.title)}</span>
                                                        }
                                                        {ShowSortIcon(column)}
                                                    </div>
                                                </th>
                                                : null
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </MDBTableHead>
            <MDBTableBody>
                {
                    rows.map((row, index) => {
                        return (rowComponent(row, index))
                    })
                }
                {
                    (!rows.length && noDataComponent) ?
                        noDataComponent : null
                }
            </MDBTableBody>
        </MDBTable>

    );
}

export default Table;