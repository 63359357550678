import server from '@config';
import axios from 'axios';
import Loader from 'components/Loader/Loader';
import { ContactTypes } from 'constants/constants';
import { Formik } from 'formik';
import { MDBBtn, MDBContainer, MDBInput, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import './ContactForm.css';

function ContactForm(props) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);

    const contactFormRef = new useRef(null);

    const toggle = () => {
        setModal(!modal)
    }

    useEffect(() => {
        if (modal) {
            setTimeout(() => {
                if (contactFormRef && contactFormRef.current) {
                    const ref = contactFormRef.current;
                    const values = ref.values;
                    if (values) {
                        console.log('default q type', props.defaultQuestionnaireType);
                        if (props.defaultQuestionnaireType && props.defaultQuestionnaireType.questionnaire_type) {
                            values.questionnaire_type = props.defaultQuestionnaireType.questionnaire_type;
                        } else if (props.questionnaireTypes && props.questionnaireTypes.length) {
                            props.questionnaireTypes.forEach((q) => {
                                if (q.default_companies && q.default_companies.includes(props.companyId)) {
                                    console.log('default companies', q);
                                    values.questionnaire_type = q.questionnaire_type;
                                }
                            })
                        }
                    }
                    console.log('values', values);
                    ref.setValues(values);
                }
            }, 500);
        }
    }, [modal])

    const saveContact = (contact) => {
        console.log('contact', contact);
        setLoading(true);
        const sendInvitation = contact.send_invitation;
        delete contact.send_invitation;
        const questionnaireType = contact.questionnaire_type;
        delete contact.questionnaire_type;
        let data = {
            company_id: props.companyId,
            ...contact
        }
        let config = {
            method: "post",
            url: server + `/company/company_contact/?send_invitation=${sendInvitation ? 1 : 0}&questionnaire_id=${questionnaireType}`,
            data: data
        }
        console.log(config)
        axios(config)
            .then(res => {
                setLoading(false);
                console.log(res.data['message'])
                if (props.onSave && typeof props.onSave === 'function') {
                    props.onSave(res.data);
                }
                toast.success(t('Contact successfully added'));
                toggle();
            })
            .catch(err => {
                setLoading(false);
                console.log(err)
                const msg = (err && err.data && err.data.message) ? err.data.message : 'Failed to add contact.';
                toast.error(msg);
            })
    }

    return (
        <MDBContainer style={{ padding: '0' }}>
            <div onClick={toggle}>{props.children}</div>
            <MDBModal isOpen={modal} toggle={toggle} className='contact-form-modal'>
                <MDBModalHeader>
                    <div>{t("Contact Form")}</div>
                </MDBModalHeader>
                <MDBModalBody>
                    <Loader loading={loading}>
                        <Formik
                            innerRef={contactFormRef}
                            key='signInForm'
                            initialValues={{ first_name: "", last_name: "", email: "", phone: "", contact_type: '', confidential: false, send_invitation: true }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    saveContact(values);
                                }, 500);
                            }}
                            validationSchema={Yup.object().shape({
                                first_name: Yup.string().required("First name is required."),
                                last_name: Yup.string().required("Last name is required."),
                                email: Yup.string().email(t("Email must be a valid email")).required(t("Required")),
                                contact_type: Yup.string().required(t('Required'))
                            })}
                        >
                            {(formikProps) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = formikProps;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className='d-flex flex-wrap bg-white px-2'>
                                            <div className='col-6'>
                                                <MDBInput
                                                    label={t("First Name")}
                                                    group
                                                    name="first_name"
                                                    validate
                                                    error="wrong"
                                                    success="right"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.first_name && touched.first_name && (
                                                    <div className="input-feedback">{errors.first_name}</div>
                                                )}
                                            </div>
                                            <div className='col-6'>
                                                <MDBInput
                                                    label={t("Last Name")}
                                                    group
                                                    name="last_name"
                                                    validate
                                                    error="wrong"
                                                    success="right"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.last_name && touched.last_name && (
                                                    <div className="input-feedback">{errors.last_name}</div>
                                                )}
                                            </div>
                                            <div className='col-6'>
                                                <MDBInput
                                                    label={t("Email ID")}
                                                    group
                                                    name="email"
                                                    type="email"
                                                    validate
                                                    error="wrong"
                                                    success="right"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.email && touched.email && (
                                                    <div className="input-feedback">{errors.email}</div>
                                                )}
                                            </div>
                                            <div className='col-6'>
                                                <MDBInput
                                                    label={t("Phone Number")}
                                                    group
                                                    name="phone"
                                                    type="phone"
                                                    validate
                                                    error="wrong"
                                                    success="right"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.phone && touched.phone && (
                                                    <div className="input-feedback">{errors.phone}</div>
                                                )}
                                            </div>
                                            <div className='col-12'>
                                                <label for='contactTypeSelect'>{t("Contact Type")}</label>
                                                <select id='contactTypeSelect' className="browser-default custom-select" defaultValue={''} name='contact_type'
                                                    onChange={handleChange} onBlur={handleBlur}>
                                                    <option value="" disabled>{t("Contact Type")}</option>
                                                    {
                                                        (props.allowedContactTypes && props.allowedContactTypes.length) ?
                                                            Object.keys(ContactTypes).filter((type) => props.allowedContactTypes.includes(ContactTypes[type].value)).map((key) => (<option key={ContactTypes[key].value} value={ContactTypes[key].value}>{t(ContactTypes[key].name)}</option>))
                                                            :
                                                            Object.keys(ContactTypes).map((key) => (<option key={ContactTypes[key].value} value={ContactTypes[key].value}>{t(ContactTypes[key].name)}</option>))
                                                    }
                                                    {/* {Object.keys(ContactTypes).map((key) => (<option key={ContactTypes[key].value} value={ContactTypes[key].value}>{ContactTypes[key].name}</option>))} */}
                                                </select>
                                                {/* {errors.contact_type && touched.contact_type && (
                                                    <div className="input-feedback">{errors.contact_type}</div>
                                                )} */}
                                            </div>
                                            {
                                                values.send_invitation &&
                                                <>
                                                    <div className='col-12 mt-3'>
                                                        <label for='questionnaireTypeSelection'>{t("Questionnaire")}</label>
                                                        <select id='questionnaireTypeSelection' className="browser-default custom-select" defaultValue={''} value={values.questionnaire_type} name='questionnaire_type'
                                                            onChange={handleChange} onBlur={handleBlur}>
                                                            <option value="" disabled>{t("Select Questionnaire")}</option>
                                                            {
                                                                (props.questionnaireTypes && props.questionnaireTypes.length) ?
                                                                    props.questionnaireTypes.map((q) => (<option key={q.questionnaire_type} value={q.questionnaire_type} >{q.questionnaire_type}</option>))
                                                                    :
                                                                    null
                                                            }
                                                        </select>
                                                    </div>
                                                </>
                                            }
                                            <div class='col-12 row mx-1 my-3'>
                                                <div className='col-12'>
                                                    <div className="custom-checkbox mt-0">
                                                        <input type="checkbox" className="custom-control-input" id="confidential" name="confidential" onChange={handleChange} onBlur={handleBlur} />
                                                        <label className="custom-control-label" htmlFor="confidential">{t("Is Confidential?")}</label>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="custom-checkbox mt-0">
                                                        <input type="checkbox" className="custom-control-input" id="send_invitation" name="send_invitation" defaultChecked onChange={handleChange} onBlur={handleBlur} />
                                                        <label className="custom-control-label" htmlFor="send_invitation">{t("Send Invitation")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center d-flex col-12 my-4">
                                                <MDBBtn
                                                    rounded
                                                    className="inline-block btn wwt-btn flat wwt-btn-blueli"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {t("Save")}
                                                </MDBBtn>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Loader>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    );
}

export default ContactForm;