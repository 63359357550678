export function gen_perm(len) {

  let rsort = new Array(len);
  for(let idx = 0; idx < len; idx++)
  {
      rsort[idx] = idx+1;
  }

  // then proceed to shuffle the rsort array
  for(let idx = 0; idx < len; idx++)
  {
      let swpIdx = idx + Math.floor(Math.random() * (len - idx));
      // now swap elements at idx and swpIdx
      let tmp = rsort[idx];
      rsort[idx] = rsort[swpIdx];
      rsort[swpIdx] = tmp;
  }
  return rsort;
}

export function gen_leftIndex(question_answered) {
  
  let rsort = gen_perm(18);

  if (question_answered.length > 0) {
    question_answered.forEach(item => {
      let index = rsort.indexOf(item);
      if (index > -1) {
        rsort.splice(index, 1);
      } 
    });
  }
  return rsort;
}
